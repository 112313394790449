import { Card, CardBody, CardHeader } from 'reactstrap';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { useMutation, useQuery } from '@apollo/client';
import TeamForm from './TeamForm';
import { GET_TEAM } from 'graphql/queries';
import { TEAM_UPDATE } from 'graphql/mutations';
import { getImage } from 'helpers/global';
import { setMe } from 'redux/reducers/auth';
import { useDispatch, useSelector } from 'react-redux';

const Team = () => {
  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'You have updated team info successfully!',
    place: 'br'
  });

  const { me } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { loading: isLoadingData, data } = useQuery(GET_TEAM);

  const [teamUpdate, { loading: isLoadingSave }] = useMutation(TEAM_UPDATE);

  const onSubmit = async ({ name, logo }) => {
    try {
      const { data } = await teamUpdate({
        variables: {
          input: {
            name,
            logo
          }
        }
      });

      dispatch(setMe({ ...me, team: data.teamUpdate }));

      showNotificationAlert();
    } catch (err) {
      showNotificationAlert({
        type: 'danger',
        title: 'Failed',
        message: err.message
      });
    }
  };

  return (
    <div className="card-wrapper">
      <Card>
        <CardHeader>
          <h3 className="mb-0 text-brand">Company Detail</h3>
        </CardHeader>
        <CardBody>
          {isLoadingData && <p>Loading...</p>}
          {data && (
            <TeamForm
              onSubmit={onSubmit}
              defaultValues={{
                name: data.getTeam.name
              }}
              isLoadingSave={isLoadingSave}
              currentLogo={data.getTeam.logo ? getImage(data.getTeam.logo) : ''}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Team;
