import { Card, CardHeader, CardBody, Table, Container, Row, Col } from 'reactstrap';
import SubscriptionHeader from 'components/Headers/SubscriptionHeader.js';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_CHECKOUT_URL } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalNoActiveSubscription from 'components/modals/ModalNoActiveSubscription';
import ModalEndOfTrial from 'components/modals/ModalEndOfTrial';
import { GET_STRIPE_PORTAL_URL } from 'graphql/queries';
import moment from 'moment';
import SubscriptionCard from './SubscriptionCard';
import QuoteBox from 'components/QuoteBox';
import { useCallback } from 'react';

function Subscription() {
  const { me, currentPlan, isNoActiveSubscription, isTrialEnded } = useSelector(
    (state) => state.auth
  );

  const [getCheckoutUrl] = useMutation(GET_CHECKOUT_URL);

  const { data: dataStripePortalUrl, loading: isLoadingStripeUrl } =
    useQuery(GET_STRIPE_PORTAL_URL);

  useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContentRef.current.scrollTop = 0;
    document.body.classList.add('bg-secondary');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove('bg-secondary');
    };
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [isLoading, setIsLoading] = useState({
    BASIC_MONTHLY: false,
    PRO_MONTHLY: false
  });

  const callUrl = async (subscriptionType) => {
    setIsLoading((localIsLoading) => ({
      ...localIsLoading,
      [subscriptionType]: true
    }));
    try {
      const { data } = await getCheckoutUrl({ variables: { subscriptionType } });
      window.location.href = data.getCheckoutUrl;
    } catch (err) {
      showNotificationAlert({
        type: 'danger',
        title: 'Failed',
        message: err.message
      });

      setIsLoading((localIsLoading) => ({
        ...localIsLoading,
        [subscriptionType]: false
      }));
    } finally {
    }
  };

  const buttonBasic = {
    onClick: () => {
      callUrl('BASIC_MONTHLY');
    },
    title: 'Subscribe to Basic'
  };

  const buttonPro = {
    onClick: () => {
      callUrl('PRO_MONTHLY');
    },
    title: 'Subscribe to Pro'
  };

  const [showModalEndOfTrial, setShowModalEndOfTrial] = useState(
    (isNoActiveSubscription && isTrialEnded) || isTrialEnded
  );

  const [showModalNoActiveSubscription, setShowModalNoActiveSubscription] =
    useState(isNoActiveSubscription && !isTrialEnded );

  const additionalComponent = useCallback(
    (plan) => {
      if (currentPlan === plan) {
        return (
          <p className="position-absolute text-white mb-0 text-current w-100">Your Current Plan:</p>
        );
      } else if (plan === 'PRO' && me?.team.onTrial) {
        return (
          <p className="position-absolute text-white mb-0 text-current w-100">
            You are currently trialling this plan - Your trial is ending{' '}
            {moment(me?.team.trialEndsAt, 'YYYY-MM-DD').fromNow()}
          </p>
        );
      } else {
        return null;
      }
    },
    [me, currentPlan]
  );

  const secondAdditionalComponent = useCallback(
    (plan) => {
      if (me?.team.onTrial) {
        return null;
      }

      if (currentPlan === plan) {
        const expiryDate = moment
          .unix(me?.team.currentSubscriptionInfo?.currentPeriodEnd)
          .format('Do MMMM, YYYY');

        return (
          <p className="mb-0 w-100 text-cancelled-on">
            Your plan will be cancelled on {expiryDate}.
          </p>
        );
      } else {
        return null;
      }
    },
    [me, currentPlan]
  );

  return (
    <>
     {showModalEndOfTrial ? (
        <ModalEndOfTrial
          isShow={true}
          onClose={() => setShowModalEndOfTrial(false)}
          level={me?.level}
        />
      ) : showModalNoActiveSubscription ? (
        <ModalNoActiveSubscription
          isShow={true}
          onClose={() => setShowModalNoActiveSubscription(false)}
          level={me?.level}
        />
      ) : (
        <></>
      )}
      <SubscriptionHeader />

      <Container className="mt--9 mt-md--6 pb-5">
        <Row className="justify-content-center">
          <Col lg="12">
            <div className="current-plan-mobile">
              {currentPlan && (
                <p className="text-current-mobile text-center">
                  Your Current Plan: {currentPlan === 'PRO' ? 'Pro' : 'Basic'}
                </p>
              )}

              {!currentPlan && me?.team.onTrial && (
                <p className="text-current-mobile text-center">
                  You are currently trialling this plan - Your trial is ending{' '}
                  {moment(me?.team.trialEndsAt, 'YYYY-MM-DD').fromNow()}
                </p>
              )}

              {currentPlan && isLoadingStripeUrl && <p className="text-center">Loading...</p>}
              {currentPlan && !isLoadingStripeUrl && dataStripePortalUrl?.getStripePortalUrl && (
                <p className="text-center mb-4">
                  <a
                    href={dataStripePortalUrl?.getStripePortalUrl}
                    className="btn rounded-pill btn-black"
                    target="_blank"
                    rel="noreferrer">
                    Manage Subscription
                  </a>
                </p>
              )}
            </div>

            <div className="pricing card-group flex-column flex-md-row mb-3">
              <SubscriptionCard
                title="Standard"
                price="£20"
                priceInfo="/month"
                features={[
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Free trial - No credit cards required'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Brand to your organisation'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Select from 7 styles of question types'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Dashboard and Reporting'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Set email alerts to be instantly informed'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Intelligence with survey logic'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Unlimited Survey'
                  }
                ]}
                isCurrent={currentPlan === 'BASIC'}
                buttonTitle={buttonBasic.title}
                onButtonClick={buttonBasic.onClick}
                isButtonLoading={isLoading.BASIC_MONTHLY}
                hideButton={currentPlan ? true : false}
                additionalComponent={additionalComponent('BASIC')}
                secondAdditionalComponent={secondAdditionalComponent('BASIC')}
              />

              <SubscriptionCard
                title="Pro"
                price="£40"
                priceInfo="/month"
                features={[
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Free trial - No credit cards required'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'As with Standard plus'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Add members of your team so you can easily collaborate'
                  },
                  {
                    icon: <i className="fas fa-check" />,
                    title: 'Multi-User at both admin and user level'
                  }
                ]}
                isHighlighted={true}
                isCurrent={currentPlan === 'PRO'}
                buttonTitle={buttonPro.title}
                onButtonClick={buttonPro.onClick}
                isButtonLoading={isLoading.PRO_MONTHLY}
                hideButton={currentPlan ? true : false}
                additionalComponent={additionalComponent('PRO')}
                secondAdditionalComponent={secondAdditionalComponent('PRO')}
              />
              <Card className="card-pricing border-0 text-center mb-4 flex-grow-1">
                <CardHeader className="bg-transparent">
                  <QuoteBox>
                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">Enterprise</h4>
                  </QuoteBox>
                </CardHeader>
                <CardBody className="px-lg-5">
                  <p className="text-black text-plan-features">
                    Let us do the work for you and give you a fully engaging service.
                  </p>
                  <p className="text-black text-plan-features">
                    If you have multiple locations and would like regular reporting across your
                    enterprise please contact us
                  </p>
                  <div>
                    <Link
                      className="btn btn-primary mb-3 my-4 rounded-pill"
                      to="/contact-us"
                      type="button">
                      Contact Us
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        {currentPlan && isLoadingStripeUrl && (
          <p className="text-center d-none d-md-block mt-5">Loading...</p>
        )}
        {currentPlan && !isLoadingStripeUrl && dataStripePortalUrl?.getStripePortalUrl && (
          <p className="text-center d-none d-md-block mt-5">
            <a
              href={dataStripePortalUrl?.getStripePortalUrl}
              className="btn rounded-pill btn-black"
              target="_blank"
              rel="noreferrer">
              Manage Subscription
            </a>
          </p>
        )}
        <div className="mt-5">
          <p className="text-black text-features-heading">
            Create quick, easy and intuitive feedback, Gather data in seconds.
          </p>
        </div>
        <Row className="row-grid justify-content-center">
          <Col lg="10">
            <Table className="table-light bg-secondary text-black mt-5 table-features" responsive>
              <thead>
                <tr>
                  <th className="px-0 bg-transparent" scope="col">
                    <span className="font-weight-700">Features</span>
                  </th>
                  <th className="text-center bg-transparent" scope="col">
                    Standard
                  </th>
                  <th className="text-center bg-transparent" scope="col">
                    Pro
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-0">(prices exc. VAT)</td>
                  <td className="text-center">£20 pm</td>
                  <td className="text-center">£40 pm</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Available on any device</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Company Branding</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Conditional Logic</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Admins</td>
                  <td className="text-center">
                    <i className="fas fa-times text-muted" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td>
                    <span>Multiple Admins and Users collaborating</span>
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Preset data</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td>
                    <span>
                      Create your own data sets that you can quickly and constantly refer to such as
                      demographics, membership, age groups etc.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Live Analytics</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Data Export</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td className="px-0">Real- Time Alerting</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td>
                    <span>Receive instant emails based on conditions you define</span>
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Easily customisable</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Single Answer selection</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Multiple Answer selection</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">Response based experience</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td>
                    <span>
                      Create the experience for the user dependant on the response they give
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Step Logic</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-0">External Links</td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td className="text-center">
                    <i className="fas fa-check text-brand" />
                  </td>
                  <td>
                    <span>Hyperlinks available for external reference</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Subscription;
