import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

function SimpleHeader({
  name,
  description,
  parents,
  rightElements,
  firstColProps,
  secondColProps
}) {
  return (
    <>
      <div className="header header-dark bg-gradient-brand pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col xs="6" sm="5" lg="6" {...firstColProps}>
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">{name}</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-2 ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark">
                  <BreadcrumbItem>
                    <Link to="/admin">
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                  {parents &&
                    parents.map((parent) => (
                      <BreadcrumbItem key={`${parent.label}`}>
                        <Link to={parent.to} title={parent.title}>
                          {parent.label}
                        </Link>
                      </BreadcrumbItem>
                    ))}
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {rightElements && (
                <Col xs="6" sm="7" lg="6" {...secondColProps}>
                  <div className="d-flex flex-nowrap justify-content-end">{rightElements}</div>
                </Col>
              )}
            </Row>
            {description && (
              <Row>
                <Col sm="8" lg="8">
                  <p className="text-white header__text">{description}</p>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  rightElements: PropTypes.element
};

export default SimpleHeader;
