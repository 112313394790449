import React, { useState } from 'react';
import FormPreset from './FormPreset';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { PRESET_DATA_SET_CREATE } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { PRESET_DATA_SET_DATA_CRUD } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';
import PresetPageWrapper from './PresetPageWrapper';
import { PRESET_DATA_FULL_PATH } from 'constants/paths';
import ModalPresetLimit from 'components/modals/ModalPresetLimit';

const AddPreset = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  const [presetDataSetCreate, { loading: isLoadingSave }] = useMutation(PRESET_DATA_SET_CREATE);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [presetDataSetDataCrud, { loading: isLoadingSaveSetDatas }] =
    useMutation(PRESET_DATA_SET_DATA_CRUD);

  const [showModalPresetLimit, setShowModalPresetLimit] = useState(false);

  const onSubmit = async (data) => {
    const { name, datas } = data;
    try {
      const resPresetDataSetCreate = await presetDataSetCreate({ variables: { input: { name } } });

      await presetDataSetDataCrud({
        variables: {
          setId: resPresetDataSetCreate.data.presetDataSetCreate.id,
          setDatas: datas.map((dt, index) => ({
            name: dt.public,
            internalName: dt.internal,
            sortOrder: index + 1
          }))
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have added a preset successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        case 'only_10_entries_allowed_for_non_enterprise':
          setShowModalPresetLimit(true);
          break;
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (isRedirect) {
    return <Redirect to={PRESET_DATA_FULL_PATH} />;
  }

  return (
    <>
      <ModalPresetLimit
        isShow={showModalPresetLimit}
        onClose={() => setShowModalPresetLimit(false)}
      />
      <PresetPageWrapper>
        <SimpleHeader
          name="Add Preset Data"
          firstColProps={{ xs: 8, sm: 10, lg: 10 }}
          secondColProps={{ xs: 4, sm: 2, lg: 2 }}
          parents={[{ to: PRESET_DATA_FULL_PATH, label: 'Preset' }]}
          rightElements={
            <Link
              to={PRESET_DATA_FULL_PATH}
              className="btn btn-neutral btn-sm rounded-pill"
              color="default"
              size="sm">
              Back
            </Link>
          }
        />
        <FormPreset onSubmit={onSubmit} isLoadingSave={isLoadingSave || isLoadingSaveSetDatas} />
      </PresetPageWrapper>
    </>
  );
};

export default AddPreset;
