import { Container } from 'reactstrap';

function AuthHeader() {
  return (
    <>
      <div className="header bg-gradient-brand py-7 py-lg-8 pt-lg-9">
        <Container>
          <div className="header-body text-center mb-6">
            <img
              alt="Gather Logo"
              style={{ maxWidth: '300px' }}
              src={require('assets/img/brand/GatherLogo2White.png').default}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default AuthHeader;
