import useShowTooltip from 'hooks/useShowTooltip';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardBody } from 'reactstrap';

var colors = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529'
  },
  theme: {
    default: '#172b4d',
    primary: '#5e72e4',
    secondary: '#f4f5f7',
    info: '#11cdef',
    success: '#2dce89',
    danger: '#f5365c',
    warning: '#fb6340',
    brand: '#fe5900'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent'
};

const SurveysCompleted = ({ dateFrom, dateUntil, isLoading, data: lineGraphData }) => {
  const chartSurveyData = lineGraphData
    ? lineGraphData.data.map(({ label, count }) => ({ label, count }))
    : [];

  const firstDateIndex = lineGraphData?.data.findIndex((elm) => elm.label === dateFrom);
  if (firstDateIndex < 0) {
    chartSurveyData.unshift({
      label: dateFrom,
      count: 0
    });
  }

  const lastDateIndex = lineGraphData?.data.findIndex((elm) => elm.label === dateUntil);
  if (lastDateIndex < 0) {
    chartSurveyData.push({
      label: dateUntil,
      count: 0
    });
  }

  useShowTooltip();

  const chartSurveyCompleted = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 16
        }
      },
      elements: {
        point: {
          radius: 6,
          backgroundColor: colors.theme['brand']
        },
        line: {
          tension: 0.4,
          borderWidth: 4,
          borderColor: colors.theme['brand'],
          backgroundColor: colors.transparent,
          borderCapStyle: 'rounded'
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[200],
              zeroLineColor: colors.gray[200]
            },
            ticks: {
              beginAtZero: true,
              precision: 0
            }
          }
        ]
      },
      layout: {
        padding: {
          top: 5
        }
      }
    },
    data: {
      labels: chartSurveyData.map((elm) => elm.label),
      datasets: [
        {
          label: 'Total Completed Survey',
          data: chartSurveyData.map((elm) => elm.count)
        }
      ]
    }
  };

  return (
    <Card>
      <CardHeader>
        <h6 className="surtitle text-black">Overview</h6>
        <h5 className="h3 mb-0 text-brand">{lineGraphData?.name}</h5>
      </CardHeader>
      <CardBody style={{ height: '398px' }}>
        {isLoading && (
          <div className="h-100 d-flex flex-column justify-content-center">
            <p className="text-center mb-0">Loading...</p>
          </div>
        )}
        {!isLoading && (
          <div className="chart">
            <Line
              data={chartSurveyCompleted.data}
              options={chartSurveyCompleted.options}
              id="chart-survey-completed"
              className="chart-canvas"
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SurveysCompleted;
