import { configureStore } from '@reduxjs/toolkit';
import surveyReducer from './reducers/survey';
import questionReducer from './reducers/question';
import presetReducer from './reducers/preset';
import accountReducer from './reducers/account';
import teamMemberReducer from './reducers/teamMember';
import authReducer from './reducers/auth';

export default configureStore({
  reducer: {
    survey: surveyReducer,
    question: questionReducer,
    preset: presetReducer,
    account: accountReducer,
    teamMember: teamMemberReducer,
    auth: authReducer
  }
});
