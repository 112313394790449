import useShowTooltip from 'hooks/useShowTooltip';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

var colors = [
  '#a5b592',
  '#f3a447',
  '#e7bc29',
  '#d092a7',
  '#9c85c0',
  '#809ec2',
  '#64754f',
  '#ff6600',
  '#937510',
  '#94405d'
];

const DoughnutChartItem = ({ index, data }) => {
  useShowTooltip();

  const legendId = `legend-${index}`;

  const chart = {
    data: {
      labels: data.map((elm) => elm.label),
      datasets: [
        {
          data: data.map((elm) => elm.count),
          backgroundColor: colors,
          label: 'Dataset'
        }
      ]
    },
    options: {
      responsive: true,
      cutoutPercentage: 80,
      legend: {
        display: false
      },
      legendCallback: function (chart) {
        var text = [];
        text.push('<ul class="pie-chart-custom-legend">');
        var ds = chart.data.datasets[0];
        var sum = ds.data.reduce(function add(a, b) {
          return a + b;
        }, 0);
        for (var i = 0; i < ds.data.length; i++) {
          text.push('<li>');
          var perc = Math.round((100 * ds.data[i]) / sum, 0);
          text.push(
            `<span class="pie-chart-custom-legend__color" style="background-color: ${ds.backgroundColor[i]}"></span>
             <span class="pie-chart-custom-legend__title">
               <span>${chart.data.labels[i]} (${ds.data[i]} - ${perc}%)</span>
             </span>`
          );
          text.push('</li>');
        }
        text.push('</ul>');
        return text.join('');
      },
      animation: {
        animateScale: true,
        animateRotate: true
      }
    }
  };

  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {
        document.getElementById(legendId).innerHTML =
          chartRef.current?.chartInstance.generateLegend();
      }
    }, 500);
  }, [chartRef, legendId]);

  if (chart.data.datasets[0].data.reduce((total, count) => total + count, 0) <= 0) {
    return <p className="mb-0 text-center">There is no data!</p>;
  }

  return (
    <>
      <div id={legendId}></div>
      <Doughnut ref={chartRef} data={chart.data} options={chart.options} className="chart-canvas" />
    </>
  );
};

export default DoughnutChartItem;
