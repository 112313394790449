import React from 'react';
import { Button, Card, CardBody, CardHeader, Input, UncontrolledTooltip } from 'reactstrap';

const ItemCard = ({
  index,
  isFirst,
  isLast,
  clickSort,
  data,
  onChangeData,
  removeItem,
  showConfirmation,
  className
}) => {
  return (
    <Card className={className}>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                clickSort(index, index > 0 ? index - 1 : 0);
              }}
              disabled={isFirst}>
              <i className="fas fa-angle-up" />
            </Button>

            <Button
              size="sm"
              color="primary"
              className="ml-0"
              onClick={() => {
                clickSort(index, index + 1);
              }}
              disabled={isLast}>
              <i className="fas fa-angle-down" />
            </Button>
          </div>

          <button
            type="button"
            className="table-action table-action-delete"
            id={`tooltip-delete-${data.id}`}
            onClick={(e) => {
              e.preventDefault();
              showConfirmation({
                title: 'Are you sure want to delete?',
                onConfirm: () => {
                  removeItem(index);
                }
              });
            }}>
            <i className="fas fa-trash" />
          </button>
          <UncontrolledTooltip delay={0} target={`tooltip-delete-${data.id}`}>
            Delete Data
          </UncontrolledTooltip>
        </div>
      </CardHeader>
      <CardBody>
        <Input
          value={data.public}
          onChange={(e) => {
            onChangeData(index, 'public', e.target.value);
          }}
          placeholder="Public Field"
          className="mb-2"
        />

        <Input
          value={data.internal}
          onChange={(e) => {
            onChangeData(index, 'internal', e.target.value);
          }}
          placeholder="Internal Use Only"
        />
      </CardBody>
    </Card>
  );
};

export default ItemCard;
