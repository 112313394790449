import React from 'react';
import { Container } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Link } from 'react-router-dom';
import ListInvites from './ListInvites';
import ListTeamMembers from './ListTeamMembers';
import TeamMemberPageWrapper from './TeamMemberPageWrapper';
import { TEAM_MEMBER_FULL_PATH } from 'constants/paths';
import Team from './Team';
import { useSelector } from 'react-redux';

function TeamMember() {
  const { me } = useSelector((state) => state.auth);
  return (
    <TeamMemberPageWrapper>
      <SimpleHeader
        name="Company"
        firstColProps={{ xs: 4 }}
        secondColProps={{ xs: 8 }}
        rightElements={
          <>
            <Link
              to={`${TEAM_MEMBER_FULL_PATH}/invite`}
              className="btn btn-neutral btn-sm rounded-pill">
              Invite a Company Member
            </Link>
          </>
        }
      />
      <Container className="mt--6" fluid>
        {me?.level === 'ADMIN' && <Team />}
        <ListTeamMembers />
        <ListInvites />
      </Container>
    </TeamMemberPageWrapper>
  );
}

export default TeamMember;
