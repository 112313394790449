import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import useQueryParams from 'hooks/useQueryParams';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {
  const queryParams = useQueryParams();

  const email = queryParams.get('email');
  const token = queryParams.get('token');

  return (
    <>
      <AuthHeader title="Logo" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                {(!email || !token) && <p className="text-center">NOT FOUND!</p>}
                {email && token && <ResetPasswordForm email={email} token={token} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPassword;
