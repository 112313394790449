import React, { useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect, useParams } from 'react-router';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import FormTeamMember from './FormTeamMember';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TEAM_MEMBER } from 'graphql/queries';
import { TEAM_MEMBER_UPDATE } from 'graphql/mutations';
import { accessToGraphqlLevelMap, graphqlLevelToAccessMap } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import TeamMemberPageWrapper from './TeamMemberPageWrapper';
import { TEAM_MEMBER_FULL_PATH } from 'constants/paths';

const EditTeamMember = () => {
  const { id } = useParams();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const { loading: isLoadingData, data: dataTeamMember } = useQuery(GET_TEAM_MEMBER, {
    variables: { id }
  });

  const [teamMemberUpdate, { loading: isLoadingSave }] = useMutation(TEAM_MEMBER_UPDATE);

  const [isRedirect, setIsRedirect] = useState(false);

  const onSubmit = async (data) => {
    try {
      const { firstName, lastName, access, permissions } = data;

      let presetDataSetDataIds = [];
      if (permissions) {
        const permissionsKeys = Object.keys(permissions);
        for (let i = 0; i < permissionsKeys.length; i++) {
          presetDataSetDataIds = presetDataSetDataIds.concat(
            permissions[permissionsKeys[i]] ? permissions[permissionsKeys[i]] : []
          );
        }
      }

      await teamMemberUpdate({
        variables: {
          input: {
            memberId: id,
            level: accessToGraphqlLevelMap[access],
            firstName,
            lastName,
            presetDataSetDataIds
          }
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have updated the team member successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        case 'update_permissions_not_for_admin_level':
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: 'You cannot change level from Member to Admin'
          });
          break;
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (isRedirect) {
    return <Redirect to={TEAM_MEMBER_FULL_PATH} />;
  }

  const permissions = {};
  if (dataTeamMember && dataTeamMember.getTeamMember) {
    for (let i = 0; i < dataTeamMember.getTeamMember.presetDataSetDatas.length; i++) {
      const presetDataSetData = dataTeamMember.getTeamMember.presetDataSetDatas[i];
      if (!permissions.hasOwnProperty(presetDataSetData.presetDataSetId)) {
        permissions[presetDataSetData.presetDataSetId] = [];
      }
      permissions[presetDataSetData.presetDataSetId].push(presetDataSetData.id);
    }
  }

  return (
    <TeamMemberPageWrapper>
      <SimpleHeader
        name="Edit Company Member"
        firstColProps={{ xs: 8, sm: 10, lg: 10 }}
        secondColProps={{ xs: 4, sm: 2, lg: 2 }}
        parents={[{ to: TEAM_MEMBER_FULL_PATH, label: 'Company' }]}
        rightElements={
          <Link to={TEAM_MEMBER_FULL_PATH} className="btn btn-neutral btn-sm rounded-pill">
            Back
          </Link>
        }
      />

      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Company Member Details</h3>
            </CardHeader>
            <CardBody>
              {isLoadingData && <p>Loading...</p>}
              {!isLoadingData && dataTeamMember && (
                <FormTeamMember
                  onSubmit={onSubmit}
                  type="edit"
                  isLoadingSave={isLoadingSave}
                  defaultValues={{
                    firstName: dataTeamMember.getTeamMember?.firstName,
                    lastName: dataTeamMember.getTeamMember?.lastName,
                    email: dataTeamMember.getTeamMember?.email,
                    access: graphqlLevelToAccessMap[dataTeamMember.getTeamMember?.level],
                    permissions
                  }}
                  isUserAnAdmin={dataTeamMember.getTeamMember?.level === 'ADMIN' ? true : false}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </Container>
    </TeamMemberPageWrapper>
  );
};

export default EditTeamMember;
