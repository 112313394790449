import { Input, Button, Modal, UncontrolledTooltip } from 'reactstrap';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { viewSurveyTitle } from 'helpers/global';

const ModalShareSurvey = ({ url, isShow, onClose, isPublished }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {viewSurveyTitle(isPublished)}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Input type="textarea" value={url} readOnly style={{ resize: 'none' }} />
      </div>
      <div className="modal-footer">
        <CopyToClipboard
          text={url}
          onCopy={() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 2000);
          }}>
          <Button color="primary" type="button" id="buttonCopy" className="rounded-pill">
            Copy Link
          </Button>
        </CopyToClipboard>
        <UncontrolledTooltip delay={0} trigger="hover focus" target="buttonCopy">
          {isCopied ? 'This is Copied!' : 'Copy To Clipboard'}
        </UncontrolledTooltip>
        <a className="btn btn-warning rounded-pill" target="_blank" href={url} rel="noreferrer">
          Preview Survey
        </a>

        <Button
          className="ml-auto rounded-pill"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ModalShareSurvey;
