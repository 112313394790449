// Contain at least 1 lowercase, 1 uppercase, 1 special character, and 1 number.
export const passwordPattern =
  /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_+={}[\]|\\:;"'<>,.?]).*$/;

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const queryStringNamePatern = /^[A-Z][A-Z0-9_]+$/i;

export const questionTypeToGraphqlQuestionTypeMap = {
  emoticon: 'EMOTICON',
  multiSelect: 'MULTI_SELECT',
  singleSelect: 'SINGLE_SELECT',
  commentBox: 'COMMENT_BOX',
  input: 'SINGLE_INPUT',
  datetimePicker: 'DATETIME_PICKER',
  presetData: 'PRESET_DATA',
  statement: 'STATEMENT'
};

export const graphqlQuestionTypeToQuestionTypeMap = {
  EMOTICON: 'emoticon',
  MULTI_SELECT: 'multiSelect',
  SINGLE_SELECT: 'singleSelect',
  COMMENT_BOX: 'commentBox',
  SINGLE_INPUT: 'input',
  DATETIME_PICKER: 'datetimePicker',
  PRESET_DATA: 'presetData',
  STATEMENT: 'statement'
};

export const accessToGraphqlLevelMap = {
  Admin: 'ADMIN',
  Member: 'MEMBER'
};

export const graphqlLevelToAccessMap = {
  ADMIN: 'Admin',
  MEMBER: 'Member'
};

export const listFonts = ['Arial', 'Helvetica'];

export const graphqlStatusToStatusMap = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published'
};

export const logoAlignmentToGraphqlLogoAlignmentMap = {
  Left: 'LEFT',
  Center: 'CENTER',
  Right: 'RIGHT'
};

export const graphqlLogoAlignmentToLogoAlignmentMap = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right'
};

export const stringObjMonths = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
};
