import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GET_TEAM_MEMBERS } from 'graphql/queries';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { TEAM_MEMBER_DELETE } from 'graphql/mutations';
import { graphqlLevelToAccessMap } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import useConfirmation from 'hooks/useConfirmation';
import { useSelector } from 'react-redux';
import { TEAM_MEMBER_FULL_PATH, ACCOUNT_FULL_PATH } from 'constants/paths';

const perPage = 100;
function ListTeamMembers() {
  const { me } = useSelector((state) => state.auth);

  const {
    loading: isLoadingDataTeamMembers,
    data: dataTeamMembers,
    refetch,
    networkStatus
  } = useQuery(GET_TEAM_MEMBERS, {
    variables: { first: perPage, page: 1 },
    notifyOnNetworkStatusChange: true
  });

  const [teamMemberDelete, { loading: isLoadingDestroy }] = useMutation(TEAM_MEMBER_DELETE);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const { showConfirmation, hideConfirmation } = useConfirmation(isLoadingDestroy);

  const onDeleteTeamMember = (id) => {
    showConfirmation({
      title: 'Are you sure want to delete?',
      onConfirm: async () => {
        try {
          await teamMemberDelete({ variables: { memberId: id } });
          hideConfirmation();
          showNotificationAlert({
            title: 'Success',
            message: 'You have deleted the team member successfully'
          });
          refetch();
        } catch (err) {
          switch (err.message) {
            case 'delete_yourself_not_permitted':
              showNotificationAlert({
                type: 'danger',
                title: 'Failed',
                message: 'Delete yourself is not permitted'
              });
              break;
            default:
              showNotificationAlert({
                type: 'danger',
                title: 'Failed',
                message: err.message
              });
              break;
          }
        } finally {
          hideConfirmation();
        }
      }
    });
  };

  return (
    <Row>
      <div className="col">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col>
                <h3 className="mb-0 text-brand">List Joined Company Members</h3>
              </Col>
            </Row>
          </CardHeader>

          {(isLoadingDataTeamMembers || networkStatus === NetworkStatus.refetch) && (
            <CardBody>
              <p className="text-center">Loading...</p>
            </CardBody>
          )}
          {!isLoadingDataTeamMembers && dataTeamMembers && (
            <Table className="align-items-center table-flush" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Access</th>
                  <th>Date Joined</th>
                  <th>Last Login</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {dataTeamMembers.getTeamMembers?.data.map((data) => (
                  <tr key={`team-member-${data.id}`}>
                    <td>{`${data.firstName} ${data.lastName}`}</td>
                    <td>{data.email}</td>
                    <td>{graphqlLevelToAccessMap[data.level]}</td>
                    <td>{data.emailVerifiedAt}</td>
                    <td>{data.lastLoginAt}</td>
                    <td className="table-actions">
                      <div className="d-flex">
                        {(me?.level === 'ADMIN' ||
                          (me?.level === 'MEMBER' && me?.id === data.id)) && (
                          <>
                            <Link
                              className="table-action"
                              to={
                                me?.id === data.id
                                  ? ACCOUNT_FULL_PATH
                                  : `${TEAM_MEMBER_FULL_PATH}/${data.id}/edit`
                              }
                              id={`tooltip-edit-${data.id}`}>
                              <i className="fas fa-user-edit" />
                            </Link>
                            <UncontrolledTooltip delay={0} target={`tooltip-edit-${data.id}`}>
                              {me?.id === data.id
                                ? 'Edit Your Profile'
                                : 'Edit Company Member Data'}
                            </UncontrolledTooltip>
                          </>
                        )}
                        {me?.level === 'ADMIN' && me?.id !== data.id && (
                          <>
                            <button
                              className="table-action table-action-delete"
                              id={`tooltip-delete-${data.id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                onDeleteTeamMember(data.id);
                              }}>
                              <i className="fas fa-trash" />
                            </button>
                            <UncontrolledTooltip delay={0} target={`tooltip-delete-${data.id}`}>
                              Delete Team Member
                            </UncontrolledTooltip>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </div>
    </Row>
  );
}

export default ListTeamMembers;
