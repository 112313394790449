import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import AccountForm from './AccountForm';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { MY_ACCOUNT_UPDATE } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { setMe } from 'redux/reducers/auth';

const Account = () => {
  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'You have updated your account successfully!',
    place: 'br'
  });

  const [myAccountUpdate, { loading: isLoadingSave }] = useMutation(MY_ACCOUNT_UPDATE);

  const { me } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onSubmit = async ({ firstName, lastName, password }, callback) => {
    const input = {
      firstName,
      lastName
    };

    if (password) {
      input.password = password;
    }

    const { data } = await myAccountUpdate({
      variables: {
        input
      }
    });

    dispatch(setMe({ ...me, ...data.myAccountUpdate }));

    callback();

    showNotificationAlert();
  };

  return (
    <>
      <SimpleHeader name="My Account" description="Edit and update your details."
        rightElements={window.location.href.includes('fromCompany') ?
          <Link
            to={'/admin/company'}
            className="btn btn-neutral btn-sm rounded-pill"
            color="default"
            size="sm">
            Back
          </Link> : null
        } />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Account Detail</h3>
            </CardHeader>
            <CardBody>
              <AccountForm
                isLoadingSave={isLoadingSave}
                defaultValues={{
                  firstName: me?.firstName,
                  lastName: me?.lastName
                }}
                email={me?.email}
                onSubmit={onSubmit}
              />
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Account;
