/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/

import axios from 'axios';
import update from 'immutability-helper';

String.prototype.rtrim = function () {
  return this.replace(/((\s*\S+)*)\s*/, '$1');
};

String.prototype.ltrim = function () {
  return this.replace(/\s*((\S+\s*)*)/, '$1');
};

export const textEllipsis = (text, firstDigit = 10, lastDigit = 0) => {
  const firstText = text.substring(0, firstDigit);
  const lastText = text.substring(text.length - lastDigit);

  if (!text) {
    return '';
  }

  if (text.length > firstText.length + lastText.length) {
    return `${firstText.rtrim()}...${lastText.ltrim()}`;
  } else {
    return text;
  }
};

export function replaceRefToInneRef(obj) {
  const oldRef = obj.ref;
  delete obj.ref;
  return {
    ...obj,
    innerRef: oldRef
  };
}

export const tokenRetrievalRequest = () => {
  return axios.get(`${process.env.REACT_APP_PUBLIC_DOMAIN}/sanctum/csrf-cookie`, {
    withCredentials: true
  });
};

export const getImage = (path) => {
  return `${process.env.REACT_APP_PUBLIC_IMAGES_S3}/${path}`;
};

export const getSurveyUrl = (id) => {
  return `${process.env.REACT_APP_FRONTPAGE}/${id}`;
};

export const emailsCommaSeparatedToArray = (emailString) => {
  return emailString
    .split(',')
    .map((email) => email.ltrim().rtrim())
    .filter(function (el) {
      return el !== '';
    });
};

export const viewSurveyTitle = (isPublished) => {
  if (isPublished) return 'Share & Preview Survey';
  return 'Preview Survey';
};

export const removeHtmlTags = (text) => {
  return text.replace(/(<([^>]+)>)/gi, '').replaceAll('&nbsp;', '');
};

export const reSortingData = (currentDatas, currentIndex, nextIndex) => {
  return update(currentDatas, {
    $splice: [
      [currentIndex, 1],
      [nextIndex, 0, currentDatas[currentIndex]]
    ]
  });
};
