import classNames from 'classnames';
import React from 'react';

function QuoteBox({ children, color }) {
  return (
    <div className={classNames('quote-box', color ? `quote-box--${color}` : '')}>
      <span>{children}</span>
    </div>
  );
}

export default QuoteBox;
