import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RowItem from 'components/SortableRow/RowItem';
import { v4 as uuidv4 } from 'uuid';
import useConfirmation from 'hooks/useConfirmation';
import { reSortingData } from 'helpers/global';
import ItemList from './items/List';
import ItemCard from './items/Card';

const Fields = ({ onChange, value }) => {
  const [datas, setDatas] = useState(value || []);

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const insertData = () => {
    setDatas((dts) => [...dts, { id: uuidv4(), public: '', internal: '' }]);
  };

  useEffect(() => {
    onChange(datas);
  }, [onChange, datas]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setDatas((localDatas) => reSortingData(localDatas, dragIndex, hoverIndex));
  }, []);

  const onDrop = useCallback(() => {
    onChange(datas);
  }, [datas, onChange]);

  const onChangeData = useCallback(
    (index, field, value) => {
      setDatas((emt) => {
        const newEmt = [...emt];
        newEmt[index][field] = value;
        return newEmt;
      });
    },
    [setDatas]
  );

  const renderItem = useCallback(
    (data, index) => {
      const removeItem = (index) => {
        setDatas((dts) => {
          const localDatas = [...dts];
          localDatas.splice(index, 1);
          return localDatas;
        });

        hideConfirmation();
      };

      const clickSort = (currentIndex, nextIndex) => {
        const newDatas = reSortingData(datas, currentIndex, nextIndex);
        setDatas(newDatas);
        onChange(newDatas);
      };

      return (
        <RowItem id={data.id} key={data.id} index={index} moveCard={moveCard} onDrop={onDrop}>
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <>
              <ItemList
                index={index}
                dragRef={dragRef}
                previewRef={previewRef}
                handlerId={handlerId}
                isDragging={isDragging}
                data={data}
                onChangeData={onChangeData}
                removeItem={removeItem}
                showConfirmation={showConfirmation}
                className="d-none d-md-flex"
              />

              <ItemCard
                index={index}
                isFirst={index <= 0}
                isLast={index >= datas.length - 1}
                clickSort={clickSort}
                data={data}
                onChangeData={onChangeData}
                removeItem={removeItem}
                showConfirmation={showConfirmation}
                className="d-md-none"
              />
            </>
          )}
        </RowItem>
      );
    },
    [moveCard, onChangeData, hideConfirmation, showConfirmation, datas, onDrop, onChange]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">Data</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="rounded-pill"
                    size="sm"
                    color="primary"
                    type="button"
                    onClick={() => insertData()}>
                    Add Data
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {datas.length <= 0 && <p className="text-center">There is no data!</p>}
              {datas.length >= 1 && (
                <DndProvider backend={HTML5Backend}>
                  {datas.map((data, index) => renderItem(data, index))}
                </DndProvider>
              )}
            </CardBody>
            {datas.length >= 6 && (
              <CardFooter>
                <Button
                  className="rounded-pill ml-auto d-block"
                  size="sm"
                  color="primary"
                  type="button"
                  onClick={() => insertData()}>
                  Add Data
                </Button>
              </CardFooter>
            )}
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Fields;
