import { Card, Button, UncontrolledTooltip, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { graphqlQuestionTypeToQuestionTypeMap } from 'constants/global';
import { questionTypes } from 'constants/questionTypes';
import { textEllipsis } from 'helpers/global';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { removeHtmlTags } from 'helpers/global';

const ItemCard = ({
  index,
  isFirst,
  isLast,
  surveyId,
  question,
  clickSort,
  className,
  showNotificationAlert,
  showConfirmation,
  onDuplicate,
  onDelete,
  status,
  disabled
}) => {
  return (
    <Card className={className}>
      {!disabled && (
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  clickSort(index, index > 0 ? index - 1 : 0);
                }}
                disabled={isFirst}>
                <i className="fas fa-angle-up" />
              </Button>

              <Button
                size="sm"
                color="primary"
                className="ml-0"
                onClick={() => {
                  clickSort(index, index + 1);
                }}
                disabled={isLast}>
                <i className="fas fa-angle-down" />
              </Button>
            </div>
            <div className="d-flex">
              <Link
                onClick={(e) => {
                  if (status === 'PUBLISHED') {
                    e.preventDefault();

                    showNotificationAlert({
                      type: 'danger',
                      title: 'Not Permitted',
                      message: 'You must revert the survey to be a draft to edit a question.'
                    });
                  }
                }}
                className="table-action"
                to={`${SURVEY_FULL_PATH}/${surveyId}/question/${question.id}/edit`}
                id={`tooltip-edit-${question.id}`}>
                <i className="fas fa-user-edit" />
              </Link>
              <UncontrolledTooltip delay={0} target={`tooltip-edit-${question.id}`}>
                Edit Question & Answers
              </UncontrolledTooltip>

              <button
                className="table-action table-action-duplicate"
                id={`tooltip-duplicate-${question.id}`}
                onClick={(e) => {
                  e.preventDefault();

                  if (status !== 'PUBLISHED') {
                    showConfirmation({
                      title: 'Are you sure want to duplicate this question?',
                      onConfirm: () => onDuplicate(question.id)
                    });
                  } else {
                    showNotificationAlert({
                      type: 'danger',
                      title: 'Not Permitted',
                      message: 'You must revert the survey to be a draft to duplicate a question.'
                    });
                  }
                }}>
                <i className="ni ni-ungroup" />
              </button>
              <UncontrolledTooltip delay={0} target={`tooltip-duplicate-${question.id}`}>
                Duplicate Question
              </UncontrolledTooltip>

              <button
                className="table-action table-action-delete"
                id={`tooltip-delete-${question.id}`}
                onClick={(e) => {
                  e.preventDefault();

                  if (status !== 'PUBLISHED') {
                    showConfirmation({
                      title: 'Are you sure want to delete?',
                      onConfirm: () => onDelete(question.id)
                    });
                  } else {
                    showNotificationAlert({
                      type: 'danger',
                      title: 'Not Permitted',
                      message: 'You must revert the survey to be a draft to delete a question.'
                    });
                  }
                }}>
                <i className="fas fa-trash" />
              </button>
              <UncontrolledTooltip delay={0} target={`tooltip-delete-${question.id}`}>
                Delete Question
              </UncontrolledTooltip>
            </div>
          </div>
        </CardHeader>
      )}
      <CardBody>
        <p className="mb-1">
          <small>
            <strong>ID:</strong> {question.id}
          </small>
        </p>
        <p className="mb-1">
          <small>
            <strong>Question:</strong> {textEllipsis(removeHtmlTags(question.question), 100)}
          </small>
        </p>
        <p className="mb-0">
          <small>
            <strong>Type:</strong>{' '}
            {questionTypes[graphqlQuestionTypeToQuestionTypeMap[question.type]].label}
          </small>
        </p>
      </CardBody>
    </Card>
  );
};

export default ItemCard;
