import React, { useState } from 'react';
import FormSurvey from './FormSurvey';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { SURVEY_CREATE } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { logoAlignmentToGraphqlLogoAlignmentMap } from 'constants/global';

const AddSurvey = () => {
  const [redirectTo, setRedirectTo] = useState('');
  const [visibilityControl, setVisibilityControl] = useState(false);

  const [surveyCreate, { loading: isLoadingSave }] = useMutation(SURVEY_CREATE);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const onSubmit = async (data) => {
    if(!visibilityControl){
      data.visibility = null;
    }else if(visibilityControl && !data.visibility){
      data.visibility = '[]'
    }else{
      data.visibility = JSON.stringify(data.visibility)
    }
    try {
      const {
        name,
        backgroundColor,
        backgroundImage,
        buttonColor,
        buttonTextColor,
        font,
        fontColor,
        restartSurvey,
        visibility,
        logo,
        logoAlignment
      } = data;

      const resSurveyCreate = await surveyCreate({
        variables: {
          input: {
            name,
            logo,
            logoAlignment: logoAlignmentToGraphqlLogoAlignmentMap[logoAlignment],
            backgroundColor,
            backgroundImage,
            buttonColor,
            buttonTextColor,
            font,
            fontColor,
            restartSurvey,
            visibility,
            status: 'DRAFT',
            active: true
          }
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have added a survey successfully'
      });

      setRedirectTo(`${SURVEY_FULL_PATH}/${resSurveyCreate.data.surveyCreate.id}/edit`);
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <SimpleHeader
        name="Add Survey"
        description="Use the functions below to make the changes to your surveys that you need - quickly and easily."
        parents={[{ to: SURVEY_FULL_PATH, label: 'My Surveys' }]}
        rightElements={
          <Link
            to={SURVEY_FULL_PATH}
            className="btn btn-neutral btn-sm rounded-pill"
            color="default"
            size="sm">
            Back
          </Link>
        }
      />
      <FormSurvey onSubmit={onSubmit} type="add" isLoadingSave={isLoadingSave}
        onChangeVisibilityControl={(val) => {
          setVisibilityControl(val)
        }}
        showVisibilityInput={visibilityControl}
      />
    </>
  );
};

export default AddSurvey;
