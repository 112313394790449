import { useMutation } from '@apollo/client';
import { LOGOUT } from 'graphql/mutations';
import { useDispatch } from 'react-redux';
import { logout as storeLogout } from 'redux/reducers/auth';
import useConfirmation from './useConfirmation';

function useOnLogout(onSuccess) {
  const dispatch = useDispatch();

  const [logout, { loading: isLoadingLogout }] = useMutation(LOGOUT);

  const { showConfirmation, hideConfirmation } = useConfirmation(isLoadingLogout);

  const onLogout = () => {
    showConfirmation({
      title: 'Are you sure you want to logout?',
      onConfirm: async () => {
        try {
          await logout();
          dispatch(storeLogout());
          hideConfirmation();
          onSuccess();
        } catch (err) {
          console.log('__err', err.message);
        }
      }
    });
  };

  return {
    onLogout
  };
}

export default useOnLogout;
