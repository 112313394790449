import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get as apiGet, save as apiSave } from 'services/fake/accountApi';

export const get = createAsyncThunk('account/get', async () => {
  const data = await apiGet();
  return data;
});

export const save = createAsyncThunk('account/save', async (data) => {
  await apiSave(data);
  return true;
});

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    isLoadingData: false,
    data: undefined,
    isLoadingSave: false
  },
  extraReducers: (builder) => {
    builder.addCase(get.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(get.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(save.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(save.fulfilled, (state) => {
      state.isLoadingSave = false;
    });
  }
});

export default accountSlice.reducer;
