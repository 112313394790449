import React, { useState } from 'react';
import ModalBasicPlanRestriction from 'components/modals/ModalBasicPlanRestriction';
import { useSelector } from 'react-redux';

const TeamMemberPageWrapper = ({ children }) => {
  const { me, currentPlan } = useSelector((state) => state.auth);

  const [showModalBasicPlanRestriction, setShowModalBasicPlanRestriction] = useState(
    currentPlan !== 'PRO'
  );

  return (
    <>
      <ModalBasicPlanRestriction
        isShow={showModalBasicPlanRestriction}
        onClose={() => setShowModalBasicPlanRestriction(false)}
        level={me?.level}
      />
      {children}
    </>
  );
};

export default TeamMemberPageWrapper;
