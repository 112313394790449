import { Input, Button, Modal } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import { UPDATE_EMAIL } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { emailPattern } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';

const UpdateEmailModal = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm({ mode: 'onChange' });

  const [isSuccess, setIsSuccess] = useState(false);

  const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const onSubmit = async ({ email }) => {
    try {
      await updateEmail({
        variables: {
          email
        }
      });

      setIsSuccess(true);
    } catch (err) {
      if (err.message === 'email-taken') {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: 'This email is already in use'
        });
      } else {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: err.message
        });
      }
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={onClose}>
      <div className="modal-header">
        <h6 className="modal-title text-brand" id="modal-title-default">
          Change Email
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {isSuccess && <p className="mb-0">Please check your email to confirm!</p>}
        {!isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)} id="formChangeEmail">
            <Input
              {...replaceRefToInneRef(register('email', { required: true, pattern: emailPattern }))}
              placeholder="Enter your email!"
            />
          </form>
        )}
      </div>
      <div className="modal-footer">
        {isSuccess && (
          <Button
            className="ml-auto"
            data-dismiss="modal"
            type="button"
            color="link"
            onClick={onClose}>
            Close
          </Button>
        )}
        {!isSuccess && (
          <>
            <Button
              type="submit"
              form="formChangeEmail"
              className="rounded-pill"
              color="primary"
              disabled={loading || !isValid}>
              {loading ? 'Loading...' : 'Change Email'}
            </Button>

            <Button
              className="ml-auto rounded-pill"
              data-dismiss="modal"
              type="button"
              color="link"
              onClick={onClose}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateEmailModal;
