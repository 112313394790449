import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  FormFeedback,
  Input
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

const DateFilter = ({ onSubmit, defaultStartDate, defaultEndDate }) => {
  const searchParams = new URLSearchParams(document.location.search)
  const startDateParam = searchParams.get('start_date');
  const endDateParam = searchParams.get('end_date');
  
  const [startDate, setStartDate] = useState(moment(startDateParam ?? defaultStartDate, 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(endDateParam ?? defaultEndDate, 'YYYY-MM-DD'));

  const [errors, setErrors] = useState({
    startDate: false,
    endDate: false
  });

  const handleReactDatetimeChange = (who, date) => {
    if (startDate && who === 'endDate' && new Date(startDate._d + '') > new Date(date._d + '')) {
      setStartDate(date);
      setEndDate(date);
    } else if (
      endDate &&
      who === 'startDate' &&
      new Date(endDate._d + '') < new Date(date._d + '')
    ) {
      setStartDate(date);
      setEndDate(date);
    } else {
      if (who === 'startDate') {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + '' !== endDate._d + '') {
      if (
        new Date(endDate._d + '') > new Date(date._d + '') &&
        new Date(startDate._d + '') < new Date(date._d + '')
      ) {
        return ' middle-date';
      }
      if (endDate._d + '' === date._d + '') {
        return ' end-date';
      }
      if (startDate._d + '' === date._d + '') {
        return ' start-date';
      }
    }
    return '';
  };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0 text-brand">Filter by Date</h3>
      </CardHeader>
      <CardBody>
        <Form>
          <Row className="input-daterange datepicker align-items-center">
            <Col xs={12} sm={4}>
              <label className=" form-control-label">Start date</label>
              <FormGroup>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Date Picker Here',
                    invalid: errors.startDate
                  }}
                  renderInput={(props) => {
                    return (
                      <>
                        <Input {...props} value={startDate ? props.value : ''} />
                        {errors.startDate && <FormFeedback>Start date required!</FormFeedback>}
                      </>
                    );
                  }}
                  value={startDate}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                  onChange={(e) => {
                    handleReactDatetimeChange('startDate', e);
                  }}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(currentDate);
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <label className=" form-control-label">End date</label>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Date Picker Here',
                    invalid: errors.endDate
                  }}
                  renderInput={(props) => {
                    return (
                      <>
                        <Input {...props} value={endDate ? props.value : ''} />{' '}
                        {errors.endDate && <FormFeedback>End date required!</FormFeedback>}
                      </>
                    );
                  }}
                  value={endDate}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                  onChange={(e) => handleReactDatetimeChange('endDate', e)}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(currentDate);
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={2}>
              <Button
                color="primary"
                className="w-100 rounded-pill"
                type="button"
                onClick={() => {
                  setErrors({
                    ...errors,
                    startDate: !startDate,
                    endDate: !endDate
                  });

                  if ((startDate, endDate)) {
                    onSubmit(startDate, endDate);
                  }
                }}>
                Filter
              </Button>
            </Col>
            <Col xs={12} sm={2}>
              <Button
                color="secondary"
                className="w-100 rounded-pill"
                type="button"
                onClick={() => {
                  const start = moment(defaultStartDate, 'YYYY-MM-DD')
                  const end = moment(defaultEndDate, 'YYYY-MM-DD')
                  setStartDate(start);
                  setEndDate(end);
                  onSubmit(start, end);
                }}>
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default DateFilter;
