const DisabledFormImageUploader = ({ image }) => {
  return (
    <img
      src={image ? image : require('assets/img/brand/empty.jpg').default}
      alt="Empty"
      style={{
        maxWidth: '120px',
        borderRadius: '6px',
        border: '1px solid #dedede',
        padding: '5px'
      }}
    />
  );
};

export default DisabledFormImageUploader;
