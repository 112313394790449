import { Container, Row, Col } from 'reactstrap';

function AuthFooter() {
  return (
    <>
      <footer className="footer py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-black">
                © {new Date().getFullYear()}{' '}
                <span className="font-weight-bold text-brand">Gather</span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
