import { FileInput, useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { GENERATE_IMAGE_UPLOAD_TOKEN } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

const UppyUploader = ({ allowedFileTypes, onChange, title, currentImage }) => {
  const [generateImageUploadToken] = useMutation(GENERATE_IMAGE_UPLOAD_TOKEN);

  const [preview, setPreview] = useState();

  const [isLoading, setIsloading] = useState(false);

  const [filePath, setFilePath] = useState('');

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: allowedFileTypes ? allowedFileTypes : ['image/*', '.jpg', '.jpeg', '.png']
      }
    })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 140,
        waitForThumbnailsBeforeUpload: false
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          return generateImageUploadToken({
            variables: {
              filename: file.name,
              contentType: file.type
            }
          })
            .then((result) => {
              const { method, url, path } = result.data.generateImageUploadToken;

              setFilePath(path);

              return {
                method,
                url,
                fields: {},
                headers: {
                  'Content-Type': file.type
                }
              };
            })
            .catch((err) => err);
        }
      })
      .on('upload', function () {
        setIsloading(true);
      })
      .on('thumbnail:generated', (file, preview) => {
        setPreview(preview);
      });
  });

  uppy.on('complete', function (data) {
    uppy.reset();
    onChange(filePath);
    setIsloading(false);
  });

  const onRemove = (e) => {
    uppy.reset();
    setPreview('');
    onChange('');
  };

  useEffect(() => {
    if (currentImage) {
      setPreview(currentImage);
    }
  }, [currentImage]);

  return (
    <div className="uploader">
      {isLoading && <div className="uploader__loading">Loading...</div>}

      <div style={{ display: isLoading ? 'none' : 'block' }}>
        {preview && (
          <div className="uploader__preview">
            <img alt="Preview" src={preview} />
            <Button size="sm" color="link" type="button" onClick={onRemove}>
              Remove
            </Button>
          </div>
        )}
        {!preview && (
          <FileInput
            uppy={uppy}
            pretty
            allowMultipleUploadBatches={false}
            restrictions={{
              maxNumberOfFiles: 1
            }}
            inputName="file[]"
            locale={{
              strings: {
                chooseFiles: title ? title : 'Choose File'
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UppyUploader;
