import { Container, Row, Col } from 'reactstrap';

function SubsriptionHeader() {
  return (
    <>
      <div className="header bg-gradient-brand py-7 py-lg-8 pt-lg-9">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                <h1 className="text-white">Choose the best plan for your business</h1>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SubsriptionHeader;
