/**
 * ADMIN PATH
 */
export const ADMIN_LAYOUT_PATH = '/admin';
export const DASHBOARD_PATH = '/dashboard';
export const SURVEY_PATH = '/my-surveys';
export const ARCHIVED_SURVEY_PATH = `${SURVEY_PATH}/archived`;
export const ADD_SURVEY_PATH = `${SURVEY_PATH}/add`;
export const EDIT_SURVEY_PATH = `${SURVEY_PATH}/:id/edit`;
export const RESULT_SURVEY_PATH = `${SURVEY_PATH}/:id/result`;
export const RESPONSE_SURVEY_PATH = `${SURVEY_PATH}/:surveyId/response/:id`;
export const ADD_QUESTION_PATH = `${SURVEY_PATH}/:surveyId/question/add`;
export const EDIT_QUESTION_PATH = `${SURVEY_PATH}/:surveyId/question/:id/edit`;
export const PRESET_DATA_PATH = '/preset-data';
export const ADD_PRESET_DATA_PATH = `${PRESET_DATA_PATH}/add`;
export const EDIT_PRESET_DATA_PATH = `${PRESET_DATA_PATH}/:id/edit`;
export const TEAM_MEMBER_PATH = '/company';
export const INVITE_TEAM_MEMBER_PATH = `${TEAM_MEMBER_PATH}/invite`;
export const EDIT_TEAM_MEMBER_PATH = `${TEAM_MEMBER_PATH}/:id/edit`;
export const SUBSCRIPTION_PATH = '/subscription';
export const ACCOUNT_PATH = '/account';
export const DASHBOARD_FULL_PATH = `${ADMIN_LAYOUT_PATH}${DASHBOARD_PATH}`;
export const SURVEY_FULL_PATH = `${ADMIN_LAYOUT_PATH}${SURVEY_PATH}`;
export const ARCHIVED_SURVEY_FULL_PATH = `${ADMIN_LAYOUT_PATH}${ARCHIVED_SURVEY_PATH}`;
export const ADD_SURVEY_FULL_PATH = `${ADMIN_LAYOUT_PATH}${ADD_SURVEY_PATH}`;
export const PRESET_DATA_FULL_PATH = `${ADMIN_LAYOUT_PATH}${PRESET_DATA_PATH}`;
export const TEAM_MEMBER_FULL_PATH = `${ADMIN_LAYOUT_PATH}${TEAM_MEMBER_PATH}`;
export const SUBSCRIPTION_FULL_PATH = `${ADMIN_LAYOUT_PATH}${SUBSCRIPTION_PATH}`;
export const ACCOUNT_FULL_PATH = `${ADMIN_LAYOUT_PATH}${ACCOUNT_PATH}`;

/**
 * AUTH PATH
 */
export const AUTH_LAYOUT_PATH = '/auth';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const FORGOT_PASSWORD_PATH = '/forgot';
export const RESET_PASSWORD_PATH = '/reset-password';
export const LOGIN_FULL_PATH = `${AUTH_LAYOUT_PATH}${LOGIN_PATH}`;
export const REGISTER_FULL_PATH = `${AUTH_LAYOUT_PATH}${REGISTER_PATH}`;
export const FORGOT_PASSWORD_FULL_PATH = `${AUTH_LAYOUT_PATH}${FORGOT_PASSWORD_PATH}`;

/**
 * OTHER PATH
 */
export const VERIFY_EMAIL_UPDATE_PATH = '/verify-email-update';
export const VERIFY_PATH = '/verify';
