import queryString from 'query-string';

const usePagination = ({ props }) => {
  const { location } = props;

  const { search, pathname } = location;

  const parsedQuery = queryString.parse(search);

  const goToPage = ({ selected }) => {
    const url = queryString.stringifyUrl({
      url: pathname,
      query: { ...parsedQuery, page: selected + 1 }
    });

    props.history.push(
      parseInt(parsedQuery.page) !== 1 && selected < 1 ? queryString.exclude(url, ['page']) : url
    );
  };

  return {
    pageNumber: parsedQuery.page ? parseInt(parsedQuery.page) : 1,
    goToPage
  };
};

export default usePagination;
