import { Modal, Button } from 'reactstrap';

const ModalEndOfTrial = ({ isShow, onClose, level }) => {
  let title = 'You do not have access to this area';
  let titleDescription = 'Your trial period has ended!';
  let description =
    'Please, get in touch with an admin on your team to Subscribe to a plan and have access to platform again.';

  let actions = null;

  if (level === 'ADMIN') {
    title = '';
    description = 'Please, Subscribe to a Basic or Pro Plan to have access to the platform again.';

    actions = (
      <Button
        className="ml-auto"
        color="white"
        data-dismiss="modal"
        type="button"
        onClick={onClose}>
        Manage Subscription
      </Button>
    );
  }

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isShow}
      toggle={onClose}
      backdrop="static"
      keyboard={false}>
      {title && (
        <div className="modal-header justify-content-center">
          <h6 className="modal-title">{title}</h6>
        </div>
      )}
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">{titleDescription}</h4>
          <p>{description}</p>
        </div>
      </div>
      <div className="modal-footer">{actions}</div>
    </Modal>
  );
};

export default ModalEndOfTrial;
