import { Card, CardHeader, CardBody, Container, Row, Col, Table } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SURVEY_RESPONSE } from 'graphql/queries';
import { textEllipsis } from 'helpers/global';
import { removeHtmlTags } from 'helpers/global';

const ResponseSurvey = () => {
  const { surveyId, id } = useParams();

  const { loading, data } = useQuery(GET_SURVEY_RESPONSE, {
    variables: { id }
  });

  const responses = data?.getSurveyResponse.shownAnswers.map((elm) => {
    return {
      id: elm.surveyQuestionId,
      question: textEllipsis(removeHtmlTags(elm.questionText), 100),
      answer: elm.answerText
    };
  });
  
  const params = new URL(document.location.href).searchParams;
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: '220px'
        }}>
        <span className="mask bg-brand opacity-8" />
        <Container fluid>
          <Row className="align-items-end">
            <Col lg="6" xs="7">
              <h2 className="display-3 text-white">Survey Response</h2>
              <h3 className="text-white">{data?.getSurveyResponse.id}</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
              <Link
                to={`/admin/my-surveys/${surveyId}/result?${params}`}
                className="btn btn-neutral btn-sm rounded-pill"
                color="default"
                size="sm">
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        {loading && (
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Survey Response</h3>
            </CardHeader>
            <CardBody>
              <div className="text-center">{loading && <span>Loading...</span>}</div>
            </CardBody>
          </Card>
        )}

        {!loading && (
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Survey Response</h3>
            </CardHeader>

            <Table className="align-items-center table-flush table-response" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th width="500">Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((elm) => {
                  return (
                    <tr key={elm.id}>
                      <th>{elm.question}</th>
                      <td style={{ whiteSpace: 'normal' }}>{elm.answer}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ResponseSurvey;
