import React from 'react';
import { Button, Card, CardBody, CardHeader, Input, UncontrolledTooltip } from 'reactstrap';

const ItemCard = ({
  index,
  isFirst,
  isLast,
  clickSort,
  error,
  answer,
  onChangeAnswer,
  removeItem,
  showConfirmation,
  className,
  disabled
}) => {
  return (
    <Card className={className}>
      {!disabled && (
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  clickSort(index, index > 0 ? index - 1 : 0);
                }}
                disabled={isFirst}>
                <i className="fas fa-angle-up" />
              </Button>

              <Button
                size="sm"
                color="primary"
                className="ml-0"
                onClick={() => {
                  clickSort(index, index + 1);
                }}
                disabled={isLast}>
                <i className="fas fa-angle-down" />
              </Button>
            </div>
            <button
              type="button"
              className="table-action table-action-delete"
              id={`tooltip-delete-${answer.id}`}
              onClick={(e) => {
                e.preventDefault();
                showConfirmation({
                  title: 'Are you sure want to delete?',
                  onConfirm: () => {
                    removeItem(index);
                  }
                });
              }}>
              <i className="fas fa-trash" />
            </button>
            <UncontrolledTooltip delay={0} target={`tooltip-delete-${answer.id}`}>
              Delete Answer
            </UncontrolledTooltip>
          </div>
        </CardHeader>
      )}
      <CardBody>
        <Input
          className="mb-2"
          value={answer.answer}
          disabled={disabled}
          invalid={error && !answer.answer}
          onChange={(e) => {
            onChangeAnswer(index, 'answer', e.target.value);
          }}
          placeholder="Answer Text"
        />

        <Input
          value={answer.email}
          disabled={disabled}
          onChange={(e) => {
            onChangeAnswer(index, 'email', e.target.value);
          }}
          placeholder="Alert Email"
        />
      </CardBody>
    </Card>
  );
};

export default ItemCard;
