import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback
} from 'reactstrap';
import { questionTypes } from 'constants/questionTypes';
import { Controller, useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import FieldEmoticon from '../optionsFields/Emoticon';
import FieldMultiSelect from '../optionsFields/MultiSelect';
import FieldSingleSelect from '../optionsFields/SingleSelect';
import { useEffect, useState } from 'react';
import UppyUploader from 'components/UppyUploader';
import { GET_SURVEY_QUESTIONS } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { emailsCommaSeparatedToArray } from 'helpers/global';
import { GET_PRESET_DATA_SETS } from 'graphql/queries';
import { Link } from 'react-router-dom';
import { textEllipsis } from 'helpers/global';
import { SURVEY_FULL_PATH } from 'constants/paths';
import FormWYSWYG from 'components/forms/FormWYSWYG';
import { removeHtmlTags } from 'helpers/global';
import { queryStringNamePatern } from 'constants/global';
import DisabledFormTextarea from 'components/disabledForms/Textarea';
import DisabledFormImageUploader from 'components/disabledForms/ImageUploader';

const emoticonQuestionType = questionTypes.emoticon;

/** function to split the default value which the field depend on async datasource */
function getDefaultValuesIncluded(defaultValues) {
  if (defaultValues) {
    const { skipRoute, route, presetDataSet, ...defaultValuesIncluded } = defaultValues;

    return {
      defaultValuesNotIncluded: {
        skipRoute,
        route,
        presetDataSet
      },
      defaultValuesIncluded
    };
  }

  return {
    defaultValuesNotIncluded: undefined,
    defaultValuesIncluded: undefined
  };
}

const FormQuestion = ({
  onSubmit,
  isLoadingSave,
  defaultValues,
  currentQuestionIcon,
  disabled
}) => {
  const { surveyId, id: questionId } = useParams();

  const qsTypes = Object.keys(questionTypes).map((key) => ({
    value: key,
    label: questionTypes[key].label
  }));

  const { defaultValuesNotIncluded, defaultValuesIncluded } =
    getDefaultValuesIncluded(defaultValues);

  const {
    control,
    trigger,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty: formIsDirty, isSubmitted, dirtyFields }
  } = useForm({
    defaultValues: defaultValuesIncluded
      ? defaultValuesIncluded
      : {
          mandatory: true,
          nextOnSelect: true,
          buttonText: 'Next',
          theEnd: false
        }
  });

  const { loading: isLoadingOtherQuestions, data: dataOtherQuestions } = useQuery(
    GET_SURVEY_QUESTIONS,
    {
      variables: { survey_id: surveyId, first: 100, page: 1 }
    }
  );

  const [otherQuestions, setOtherQuestions] = useState([]);
  useEffect(() => {
    if (dataOtherQuestions) {
      const otherQuestionsLocal = dataOtherQuestions?.getSurveyQuestions?.data || [];
      const currentQuestionIndex = otherQuestionsLocal.findIndex((elm) => elm.id === questionId);
      if (currentQuestionIndex >= 0) {
        otherQuestionsLocal.splice(currentQuestionIndex, 1);
      }
      setOtherQuestions(
        otherQuestionsLocal.map((qs) => ({
          ...qs,
          question: textEllipsis(removeHtmlTags(qs.question), 80)
        }))
      );
    }
  }, [dataOtherQuestions, questionId]);

  const { loading: isLoadingPresetDatas, data: presetDatas } = useQuery(GET_PRESET_DATA_SETS, {
    variables: { first: 100, page: 1 }
  });

  const watchQuestionType = watch('questionType');

  const watchMandatory = watch('mandatory');

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [watchMandatory, isSubmitted]);

  const watchSkipOption = watch('skipOption');

  const watchTheEnd = watch('theEnd');

  const watchNextOnSelect = watch('nextOnSelect');

  const watchEmoticon4Options = watch('emoticon4Options');

  let selectedQuestionType = undefined;

  if (watchQuestionType) {
    selectedQuestionType = questionTypes[watchQuestionType];
  } else {
    selectedQuestionType = undefined;
  }

  const onSubmitLocal = (data) => {
    /** Return only needed fields */
    const submitData = {
      questionType: data['questionType']
    };

    for (let i = 0; i < selectedQuestionType.fields.length; i++) {
      const field = selectedQuestionType.fields[i];
      if (field === 'skipOption') {
        submitData.skipOption = data.skipOption;
        submitData.skipText = data.skipText;
        submitData.skipRoute = data.skipRoute;
      } else if (field === 'statement') {
        submitData.question = data.statement;
      } else if (field === 'route') {
        submitData.routeToQuestion = data.route;
      } else if (field === 'alertEmail') {
        submitData.alertEmail =
          data.alertEmail !== '' ? emailsCommaSeparatedToArray(data.alertEmail) : [];
      } else if (field === 'presetDataSet') {
        submitData.presetDataSetId = data.presetDataSet;
      } else {
        submitData[field] = data[field];
      }
    }

    if (selectedQuestionType.hasAnswers && data.hasOwnProperty('answers')) {
      let answers = data.answers[selectedQuestionType.keyAnswer];

      if (selectedQuestionType.returnAnswers !== undefined) {
        answers = selectedQuestionType.returnAnswers(answers);
      }

      answers = answers.map((dt) => {
        const dtReturn = { ...dt };
        if (dt.email !== undefined) {
          dtReturn.email = dt.email !== '' ? emailsCommaSeparatedToArray(dt.email) : [];
        }
        return dtReturn;
      });

      submitData['answers'] = answers;
    }

    onSubmit(submitData);
  };

  useEffect(() => {
    if (defaultValues && watchQuestionType) {
      setValue(
        `answers.${selectedQuestionType.keyAnswer}`,
        getValues(`answers.${selectedQuestionType.keyAnswer}`) ||
          (defaultValues.answers ? defaultValues.answers[selectedQuestionType.keyAnswer] : [])
      );
    }
  }, [defaultValues, watchQuestionType, selectedQuestionType, setValue, getValues]);

  useEffect(() => {
    if (!isLoadingOtherQuestions) {
      setValue(
        'skipRoute',
        formIsDirty
          ? getValues('skipRoute')
          : defaultValuesNotIncluded && defaultValuesNotIncluded.hasOwnProperty('skipRoute')
          ? defaultValuesNotIncluded.skipRoute
          : ''
      );
    }
  }, [isLoadingOtherQuestions, defaultValuesNotIncluded, setValue, getValues, formIsDirty]);

  useEffect(() => {
    if (!isLoadingOtherQuestions) {
      setValue(
        'route',
        formIsDirty
          ? getValues('route')
          : defaultValuesNotIncluded && defaultValuesNotIncluded.hasOwnProperty('route')
          ? defaultValuesNotIncluded.route
          : ''
      );
    }
  }, [isLoadingOtherQuestions, defaultValuesNotIncluded, setValue, getValues, formIsDirty]);

  useEffect(() => {
    if (!isLoadingPresetDatas) {
      setValue(
        'presetDataSet',
        formIsDirty
          ? getValues('presetDataSet')
          : defaultValuesNotIncluded && defaultValuesNotIncluded.hasOwnProperty('presetDataSet')
          ? defaultValuesNotIncluded.presetDataSet
          : ''
      );
    }
  }, [isLoadingPresetDatas, defaultValuesNotIncluded, setValue, getValues, formIsDirty]);

  useEffect(() => {
    if (selectedQuestionType && selectedQuestionType.keyAnswer === 'emoticons') {
      const defaultAnswers = watchEmoticon4Options
        ? emoticonQuestionType.default4OptionsAnswers
        : emoticonQuestionType.defaultAnswers;

      let fieldValue =
        defaultValues && defaultValues.answers
          ? defaultValues.answers[selectedQuestionType.keyAnswer]
          : defaultAnswers;
      const currentValue = getValues(`answers.${selectedQuestionType.keyAnswer}`);

      const isDirty = dirtyFields.hasOwnProperty('answers');

      if (isDirty) {
        fieldValue = currentValue;
      } else if (!isDirty && defaultValues?.emoticon4Options !== watchEmoticon4Options) {
        fieldValue = defaultAnswers;
      }

      setValue(`answers.${selectedQuestionType.keyAnswer}`, fieldValue);
    }
  }, [
    dirtyFields,
    defaultValues,
    selectedQuestionType,
    setValue,
    getValues,
    watchEmoticon4Options
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmitLocal)}>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0 text-brand">Question Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label className="form-control-label" htmlFor="questionType" md="3">
                      Question Type
                    </Label>
                    <Col md="9">
                      <Input
                        id="questionType"
                        type="select"
                        disabled={disabled}
                        {...replaceRefToInneRef(register('questionType', { required: true }))}
                        invalid={errors.hasOwnProperty('questionType')}>
                        <option value="">Select Question Type</option>
                        {qsTypes.map((qsType) => (
                          <option value={qsType.value} key={`question-type-${qsType.value}`}>
                            {qsType.label}
                          </option>
                        ))}
                      </Input>
                      {errors.hasOwnProperty('questionType') && (
                        <FormFeedback>Question Type is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  {selectedQuestionType &&
                    selectedQuestionType.fields.map((field) => (
                      <div key={field}>
                        {field === 'mandatory' && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              Mandatory
                            </Label>
                            <Col md="9">
                              <div className="col-form-label">
                                <label className="custom-toggle mr-1">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    {...register('mandatory')}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </div>
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'skipOption' && !watchMandatory && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              Skip Option
                            </Label>
                            <Col md="2">
                              <div className="col-form-label">
                                <label className="custom-toggle mr-1">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    {...register('skipOption')}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </div>
                            </Col>
                            {watchSkipOption && (
                              <>
                                <Label className="form-control-label" htmlFor="skip-text" md="2">
                                  Skip Text
                                </Label>
                                <Col md="5">
                                  <Input
                                    id="skip-text"
                                    type="text"
                                    disabled={disabled}
                                    {...replaceRefToInneRef(register('skipText'))}
                                  />
                                </Col>
                              </>
                            )}
                          </FormGroup>
                        )}
                        {field === 'skipOption' && !watchMandatory && watchSkipOption && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label ml-auto"
                              htmlFor="skip-route"
                              md="2">
                              Route on Skip
                            </Label>
                            <Col md="5">
                              {isLoadingOtherQuestions ? (
                                <div className="col-form-label">Loading...</div>
                              ) : (
                                <Input
                                  id="skip-route"
                                  type="select"
                                  {...replaceRefToInneRef(register('skipRoute'))}>
                                  <option value="">Next</option>
                                  {otherQuestions.map((question) => (
                                    <option value={question.id} key={question.id}>
                                      {question.sortOrder} - {question.question}
                                    </option>
                                  ))}
                                </Input>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'minimumSelect' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="minimumSelect" md="3">
                              Minimum Select
                            </Label>
                            <Col md="9">
                              <Input
                                id="minimumSelect"
                                type="number"
                                min={0}
                                max={10}
                                disabled={disabled}
                                {...replaceRefToInneRef(
                                  register('minimumSelect', { required: watchMandatory })
                                )}
                                invalid={errors.hasOwnProperty('minimumSelect')}
                              />
                              {errors.hasOwnProperty('minimumSelect') && (
                                <FormFeedback>Minimum Select is required!</FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'questionIcon' && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              Question Icon
                            </Label>
                            <Col md="9">
                              <Controller
                                control={control}
                                name="questionIcon"
                                render={({ field: { onChange } }) => {
                                  if (disabled) {
                                    return (
                                      <DisabledFormImageUploader image={currentQuestionIcon} />
                                    );
                                  }
                                  return (
                                    <UppyUploader
                                      title="Choose Question Icon"
                                      currentImage={currentQuestionIcon}
                                      onChange={(path) => {
                                        onChange(path);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'buttonText' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="buttonText" md="3">
                              Button Text
                            </Label>
                            <Col md="9">
                              <Input
                                id="buttonText"
                                type="text"
                                disabled={disabled}
                                {...replaceRefToInneRef(
                                  register('buttonText', {
                                    validate: {
                                      required: (val) => {
                                        if (!watchNextOnSelect && val === '') {
                                          return 'Button Text is required!';
                                        }
                                      }
                                    }
                                  })
                                )}
                                invalid={errors.hasOwnProperty('buttonText')}
                              />
                              {errors.hasOwnProperty('buttonText') && (
                                <FormFeedback>Button Text is required!</FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'nextOnSelect' && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              Next on Select
                            </Label>
                            <Col md="2">
                              <div className="col-form-label">
                                <label className="custom-toggle mr-1">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    {...register('nextOnSelect')}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </div>
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'emoticon4Options' && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              4 Emoticon Layout
                            </Label>
                            <Col md="2">
                              <div className="col-form-label">
                                <label className="custom-toggle mr-1">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    {...register('emoticon4Options')}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </div>
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'question' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="question" md="3">
                              Question
                            </Label>
                            <Col md="9">
                              <Controller
                                control={control}
                                name="question"
                                rules={{
                                  validate: (val) => {
                                    if (
                                      val === undefined ||
                                      removeHtmlTags(val).ltrim().rtrim() === ''
                                    ) {
                                      return 'Question is required!';
                                    }
                                  }
                                }}
                                render={({ field: { onChange, value } }) => {
                                  if (disabled) {
                                    return <DisabledFormTextarea content={value} />;
                                  }

                                  return (
                                    <FormWYSWYG
                                      value={value}
                                      onChange={onChange}
                                      isError={errors.hasOwnProperty('question')}
                                    />
                                  );
                                }}
                              />
                              {errors.hasOwnProperty('question') && (
                                <FormFeedback style={{ display: 'block' }}>
                                  Question is required!
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'statement' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="statement" md="3">
                              Statement
                            </Label>
                            <Col md="9">
                              <Controller
                                control={control}
                                name="statement"
                                rules={{
                                  validate: (val) => {
                                    if (
                                      val === undefined ||
                                      removeHtmlTags(val).ltrim().rtrim() === ''
                                    ) {
                                      return 'Statement is required!';
                                    }
                                  }
                                }}
                                render={({ field: { onChange, value } }) => {
                                  if (disabled) {
                                    return <DisabledFormTextarea content={value} />;
                                  }

                                  return (
                                    <FormWYSWYG
                                      value={value}
                                      onChange={onChange}
                                      isError={errors.hasOwnProperty('statement')}
                                    />
                                  );
                                }}
                              />
                              {errors.hasOwnProperty('statement') && (
                                <FormFeedback style={{ display: 'block' }}>
                                  Statement is required!
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'theEnd' && (
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3">
                              End of Survey
                            </Label>
                            <Col md="9">
                              <div className="col-form-label">
                                <label className="custom-toggle mr-1">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    {...register('theEnd')}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </div>
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'route' && !watchTheEnd && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="alertEmail" md="3">
                              Route to Question
                            </Label>
                            <Col md="9">
                              {isLoadingOtherQuestions ? (
                                <div className="col-form-label">Loading...</div>
                              ) : (
                                <Input
                                  type="select"
                                  {...replaceRefToInneRef(register('route'))}
                                  disabled={disabled}>
                                  <option value="">Next</option>
                                  {otherQuestions.map((question) => (
                                    <option value={question.id} key={question.id}>
                                      {question.sortOrder} - {question.question}
                                    </option>
                                  ))}
                                </Input>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'externalLink' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="externalLink" md="3">
                              External Link
                            </Label>
                            <Col md="9">
                              <Input
                                id="externalLink"
                                type="text"
                                disabled={disabled}
                                {...replaceRefToInneRef(register('externalLink'))}
                              />
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'alertEmail' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="alertEmail" md="3">
                              Alert Email
                            </Label>
                            <Col md="9">
                              <Input
                                id="alertEmail"
                                type="text"
                                disabled={disabled}
                                {...replaceRefToInneRef(register('alertEmail'))}
                              />
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'fieldName' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="fieldName" md="3">
                              Query String Name
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={disabled}
                                id="fieldName"
                                type="text"
                                {...replaceRefToInneRef(
                                  register('fieldName', {
                                    pattern: queryStringNamePatern
                                  })
                                )}
                                invalid={errors.hasOwnProperty('fieldName')}
                              />
                              {errors.hasOwnProperty('fieldName') && (
                                <FormFeedback>Invalid Format!</FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                        )}

                        {field === 'presetDataSet' && (
                          <FormGroup className="row">
                            <Label className="form-control-label" htmlFor="preset-data" md="3">
                              Data Set to Use
                            </Label>
                            <Col md="9">
                              {isLoadingPresetDatas ? (
                                <div className="col-form-label">Loading...</div>
                              ) : (
                                <>
                                  <Input
                                    id="preset-data"
                                    type="select"
                                    {...replaceRefToInneRef(
                                      register('presetDataSet', { required: true })
                                    )}
                                    disabled={disabled}
                                    invalid={errors.hasOwnProperty('presetDataSet')}>
                                    <option value="">Select</option>
                                    {presetDatas?.getPresetDataSets.data.map((presetData) => (
                                      <option value={presetData.id} key={presetData.id}>
                                        {presetData.name}
                                      </option>
                                    ))}
                                  </Input>
                                  {errors.hasOwnProperty('presetDataSet') && (
                                    <FormFeedback>Data Set is required!</FormFeedback>
                                  )}
                                </>
                              )}
                            </Col>
                          </FormGroup>
                        )}
                      </div>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        {watchQuestionType === 'emoticon' && (
          <Controller
            control={control}
            name={`answers.${selectedQuestionType.keyAnswer}`}
            defaultValue={
              watchEmoticon4Options
                ? emoticonQuestionType.default4OptionsAnswers
                : emoticonQuestionType.defaultAnswers
            }
            rules={{
              validate: (val) => {
                if (val === undefined) {
                  return 'Please complete all answers!';
                } else {
                  for (let i = 0; i < val.length; i++) {
                    if (val[i].answer === '') {
                      return 'Please complete all answers!';
                    }
                  }
                }
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <FieldEmoticon
                  onChange={onChange}
                  value={value}
                  error={error?.type === 'validate' ? error.message : undefined}
                  questionRoutes={otherQuestions}
                  questionRoutesLoading={isLoadingOtherQuestions}
                  disabled={disabled}
                  numberOfOptions={watchEmoticon4Options ? 4 : 5}
                />
              );
            }}
          />
        )}

        {watchQuestionType === 'multiSelect' && (
          <Controller
            control={control}
            name={`answers.${selectedQuestionType.keyAnswer}`}
            rules={{
              validate: (val) => {
                if (val === undefined) {
                  return 'Please add an answer!';
                } else {
                  for (let i = 0; i < val.length; i++) {
                    if (val[i].answer === '') {
                      return 'Please complete all answers!';
                    }
                  }
                }
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FieldMultiSelect
                onChange={onChange}
                value={value}
                error={error?.type === 'validate' ? error.message : undefined}
                disabled={disabled}
              />
            )}
          />
        )}

        {watchQuestionType === 'singleSelect' && (
          <Controller
            control={control}
            name={`answers.${selectedQuestionType.keyAnswer}`}
            rules={{
              validate: (val) => {
                if (val === undefined) {
                  return 'Please add an answer!';
                } else {
                  for (let i = 0; i < val.length; i++) {
                    if (val[i].answer === '') {
                      return 'Please complete all answers!';
                    }
                  }
                }
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FieldSingleSelect
                onChange={onChange}
                value={value}
                error={error?.type === 'validate' ? error.message : undefined}
                questionRoutes={otherQuestions}
                questionRoutesLoading={isLoadingOtherQuestions}
                disabled={disabled}
              />
            )}
          />
        )}
        <Card>
          <CardBody>
            {!disabled && (
              <Button
                color="primary"
                type="submit"
                disabled={isLoadingSave}
                className="rounded-pill">
                {isLoadingSave && <span>Loading...</span>}
                {!isLoadingSave && <span>Save</span>}
              </Button>
            )}
            <Link
              className="btn btn-secondary rounded-pill"
              to={`${SURVEY_FULL_PATH}/${surveyId}/edit`}>
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormQuestion;
