import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Link } from 'react-router-dom';
import { GET_SURVEYS } from 'graphql/queries';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { SURVEY_DELETE, SURVEY_STATUS_UPDATE } from 'graphql/mutations';
import useConfirmation from 'hooks/useConfirmation';
import useNotificationAlert from 'hooks/useNotificationAlert';
import Pagination from 'components/Pagination';
import usePagination from 'hooks/usePagination';
import { SURVEY_FULL_PATH } from 'constants/paths';

const perPage = 10;
const Archived = (props) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const {
    loading: isLoadingDatas,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_SURVEYS, {
    variables: { statuses: ['ARCHIVED'], first: perPage, page: pageNumber },
    notifyOnNetworkStatusChange: true
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [surveyStatusUpdate, { loading: isLoadingSurveyStatusUpdate }] =
    useMutation(SURVEY_STATUS_UPDATE);

  const [surveyDelete, { loading: isLoadingDestroy }] = useMutation(SURVEY_DELETE);

  const { showConfirmation, hideConfirmation } = useConfirmation(
    isLoadingDestroy || isLoadingSurveyStatusUpdate
  );

  const [totalRow, setTotalRow] = useState(0);

  const [surveys, setSurveys] = useState();

  useEffect(() => {
    if (data && data.getSurveys) {
      setTotalRow(data.getSurveys.paginatorInfo.total);
      setSurveys(JSON.parse(JSON.stringify(data.getSurveys.data)));
    }
  }, [data]);

  const onUnArchive = async (id) => {
    try {
      await surveyStatusUpdate({ variables: { id, status: 'DRAFT' } });
      showNotificationAlert({
        title: 'Success',
        message: 'You have unarchived the survey successfully!'
      });
      refetch();
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const onDelete = async (id) => {
    try {
      await surveyDelete({ variables: { id } });
      showNotificationAlert({
        title: 'Success',
        message: 'You have deleted the survey successfully'
      });
      refetch();
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  return (
    <>
      <SimpleHeader
        name="Archived Surveys"
        firstColProps={{ xs: 8, sm: 10, lg: 10 }}
        secondColProps={{ xs: 4, sm: 2, lg: 2 }}
        rightElements={
          <>
            <Link
              to={SURVEY_FULL_PATH}
              className="btn btn-neutral btn-sm rounded-pill text-nowrap"
              color="default"
              size="sm">
              All Surveys
            </Link>
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-brand">Archived Surveys</h3>
                  </Col>
                </Row>
              </CardHeader>
              {(isLoadingDatas || networkStatus === NetworkStatus.refetch) && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getSurveys?.data.length <= 0 && (
                  <CardBody>
                    <p className="text-center">There is no data!</p>
                  </CardBody>
                )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getSurveys?.data.length >= 1 && (
                  <>
                    <Table className="align-items-center table-flush" responsive striped>
                      <thead className="thead-light">
                        <tr>
                          <th>Survey Name</th>
                          <th>Last Edited</th>
                          <th>Last Response</th>
                          <th className="text-right">Responses</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveys?.map((data, index) => (
                          <tr key={`survey-item-${data.id}`}>
                            <td>{data.name}</td>
                            <td>
                              <span className="text-muted">{data.updatedAt}</span>
                            </td>
                            <td>
                              <span className="text-muted">{data.lastResponse}</span>
                            </td>
                            <td className="text-right">{data.totalResponses}</td>
                            <td className="table-actions">
                              <div className="d-flex">
                                <Link
                                  to={`${SURVEY_FULL_PATH}/${data.id}/result`}
                                  className="table-action table-action-result"
                                  id={`tooltip-result-${data.id}`}>
                                  <i className="fas fa-list" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={`tooltip-result-${data.id}`}>
                                  View Results
                                </UncontrolledTooltip>
                                <button
                                  className="table-action table-action-unarchive"
                                  id={`tooltip-unarchive-${data.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showConfirmation({
                                      title: 'Are you sure want to unarchive this survey?',
                                      onConfirm: () => onUnArchive(data.id)
                                    });
                                  }}>
                                  <i className="ni ni-archive-2" />
                                </button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-unarchive-${data.id}`}>
                                  Unarchive Survey
                                </UncontrolledTooltip>
                                <button
                                  className="table-action table-action-delete"
                                  id={`tooltip-delete-${data.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showConfirmation({
                                      title: 'Are you sure you want to delete this survey permanently?',
                                      onConfirm: () => onDelete(data.id)
                                    });
                                  }}>
                                  <i className="fas fa-trash" />
                                </button>
                                <UncontrolledTooltip delay={0} target={`tooltip-delete-${data.id}`}>
                                  Delete Survey
                                </UncontrolledTooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <Pagination
                        totalRow={totalRow}
                        perPage={perPage}
                        initialPage={pageNumber - 1}
                        onPageChange={(data) => {
                          goToPage(data);
                        }}
                      />
                    </CardFooter>
                  </>
                )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Archived;
