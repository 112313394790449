import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ME } from 'graphql/queries';
import client from 'helpers/apolloClient';
import moment from 'moment';

export const getMe = createAsyncThunk('auth/getMe', async () => {
  const { data } = await client.query({
    query: ME
  });

  return data.me;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    me: null,
    isLogin: false,
    isLoadingMe: false,
    currentPlan: null,
    isNoActiveSubscription: false,
    isTrialEnded: false
  },
  reducers: {
    setMe(state, action) {
      state.me = action.payload;
    },
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    },
    setCurrentPlan(state, action) {
      state.currentPlan = action.payload;
    },
    setIsNoActiveSubscription(state, action) {
      state.isNoActiveSubscription = action.payload;
    },
    setIsTrialEnded(state, action) {
      state.isTrialEnded = action.payload;
    },
    logout(state) {
      state.me = null;
      state.currentPlan = null;
      state.isNoActiveSubscription = false;
      state.isTrialEnded = false;
      state.isLogin = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.pending, (state) => {
      state.isLoadingMe = true;
    });

    builder.addCase(getMe.fulfilled, (state, action) => {
      state.me = action.payload;

      const isInBasic =
        state.me?.team.currentPlan === 'BASIC_MONTHLY' ||
        state.me?.team.currentPlan === 'BASIC_YEARLY';

      const isInPro =
        state.me?.team.currentPlan === 'PRO_MONTHLY' || state.me?.team.currentPlan === 'PRO_YEARLY';

      state.currentPlan = isInPro ? 'PRO' : isInBasic ? 'BASIC' : null;

      state.isNoActiveSubscription = !state.me?.team.onTrial && !state.currentPlan;

      const dateNow = moment();
      const trialEnds = moment(state.me?.team.trialEndsAt, 'YYYY-MM-DD').add(3, 'days'); // add how much days to show trial end restriction modal since trial end date.

      state.isTrialEnded =
        !state.me?.team.onTrial && !state.currentPlan && dateNow.diff(trialEnds, 'days') <= 0;

      state.isLogin = state.me ? true : false;
      state.isLoadingMe = false;
    });
  }
});

export const {
  setMe,
  setIsLogin,
  setCurrentPlan,
  setIsNoActiveSubscription,
  setIsTrialEnded,
  logout
} = authSlice.actions;

export default authSlice.reducer;
