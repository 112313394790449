import { FormGroup, InputGroupAddon, InputGroupText, InputGroup, Input } from 'reactstrap';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { GLOBAL_SEARCH } from 'graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { textEllipsis } from 'helpers/global';
import { removeHtmlTags } from 'helpers/global';
import { Link } from 'react-router-dom';

const ControlComponent = ({ children }) => {
  return (
    <InputGroup className="input-group-alternative input-group-merge">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="fas fa-search" />
        </InputGroupText>
      </InputGroupAddon>
      {children}
    </InputGroup>
  );
};

const ValueContainerComponent = ({ children }) => {
  return children;
};

const InputComponent = (props) => {
  const { onChange, onBlur, value } = props;

  return (
    <Input placeholder="Search" type="text" onChange={onChange} onBlur={onBlur} value={value} />
  );
};

const OptionComponent = ({ data: { title, description, path }, selectOption, ...props }) => {
  return (
    <div className="search-item">
      <Link to={path} onClick={() => selectOption('')}>
        <div className="search-item__title">{title}</div>
        <div className="search-item__description">{description}</div>
      </Link>
    </div>
  );
};

const MenuComponent = (props) => {
  const inputValue = props.selectProps.inputValue;

  if (inputValue.length > 3) {
    return <components.Menu {...props}></components.Menu>;
  }
  return null;
};

const GlobalSearch = () => {
  const [globalSearch] = useLazyQuery(GLOBAL_SEARCH);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 3) {
      const dataGlobalSearch = await globalSearch({
        variables: {
          keyword: inputValue
        }
      });

      callback(
        dataGlobalSearch?.data.globalSearch?.map((elm) => {
          let path = '';
          let description = '';

          switch (elm.type) {
            case 'SURVEY':
              path = `/admin/my-surveys/${elm.id}/edit`;
              description = 'Type: Survey';
              break;
            case 'PRESET_DATA':
              path = `/admin/preset-data/${elm.id}/edit`;
              description = 'Type: Preset Data';
              break;
            case 'MEMBER':
              path = `/admin/company/${elm.id}/edit`;
              description = 'Type: Member';
              break;
            case 'QUESTION':
              const ids = elm.id.split('_');
              path = `/admin/my-surveys/${ids[0]}/question/${ids[1]}/edit`;
              description = 'Type: Question';
              break;
            default:
              path = '/';
              description = '';
              break;
          }

          return {
            title: textEllipsis(removeHtmlTags(elm.title), 100),
            description,
            path
          };
        })
      );
    }
  };

  return (
    <FormGroup className="mb-0">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        components={{
          IndicatorsContainer: () => null,
          Control: ControlComponent,
          Placeholder: () => null,
          ValueContainer: ValueContainerComponent,
          Input: InputComponent,
          SingleValue: () => null,
          Option: OptionComponent,
          Menu: MenuComponent
        }}
      />
    </FormGroup>
  );
};

export default GlobalSearch;
