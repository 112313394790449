import {
  PRESET_DATA_FULL_PATH,
  DASHBOARD_FULL_PATH,
  ACCOUNT_FULL_PATH,
  TEAM_MEMBER_FULL_PATH,
  LOGIN_FULL_PATH,
  SUBSCRIPTION_FULL_PATH,
  SURVEY_FULL_PATH
} from 'constants/paths';
import useOnLogout from 'hooks/useOnLogout';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Media
} from 'reactstrap';

const SubscriptionNavbar = () => {
  const { me } = useSelector((state) => state.auth);

  const [redirect, setRedirect] = useState(false);

  const { onLogout } = useOnLogout(() => {
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to={LOGIN_FULL_PATH} />;
  }

  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main">
        <Container>
          <NavbarBrand to="/admin/dashboard" tag={Link}>
            <img
              alt="Gather Logo"
              src={require('assets/img/brand/WhiteTransparentGatherLogo.png').default}
            />
          </NavbarBrand>
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to={DASHBOARD_FULL_PATH}>
                    <img alt="..." src={require('assets/img/brand/GatherLogo.png').default} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to={DASHBOARD_FULL_PATH} tag={Link}>
                  <span className="nav-link-inner--text">Dashboard</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={SURVEY_FULL_PATH} tag={Link}>
                  <span className="nav-link-inner--text">My Surveys</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={PRESET_DATA_FULL_PATH} tag={Link}>
                  <span className="nav-link-inner--text">Preset Data</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={TEAM_MEMBER_FULL_PATH} tag={Link}>
                  <span className="nav-link-inner--text">Company</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={ACCOUNT_FULL_PATH} tag={Link}>
                  <span className="nav-link-inner--text">My Account</span>
                </NavLink>
              </NavItem>
              {me?.level === 'ADMIN' && (
                <NavItem>
                  <NavLink to={SUBSCRIPTION_FULL_PATH} tag={Link}>
                    <span className="nav-link-inner--text">My Subscription</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </UncontrolledCollapse>
          <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link nav-link--account pr-0" color="" tag="a">
                <Media className="align-items-center">
                  <i className="ni ni-circle-08" />
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">{me?.firstName}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right className="mt-2 mt-lg-0">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <Link to={ACCOUNT_FULL_PATH} role="menuitem" className="dropdown-item">
                  <i className="ni ni-single-02"></i>
                  <span>My Account</span>
                </Link>
                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default SubscriptionNavbar;
