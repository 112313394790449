import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, FormFeedback } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import FormPassword from 'components/forms/FormPassword';
import { passwordPattern } from 'constants/global';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCEPT_INVITATION_DATA } from 'graphql/queries';
import { TEAM_ACCEPT_INVITATION } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { Link } from 'react-router-dom';
import { LOGIN_FULL_PATH } from 'constants/paths';

const AcceptInvitationForm = ({ email, token }) => {
  const { loading: isLoadingData, data } = useQuery(GET_ACCEPT_INVITATION_DATA, {
    variables: { email, token }
  });

  const [teamAcceptInvitation, { loading: isLoadingAccept }] = useMutation(TEAM_ACCEPT_INVITATION);

  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (data) {
      setValue('firstName', data.getAcceptInvitationData.memberName);
      setValue('lastName', data.getAcceptInvitationData.lastName);
    }
  }, [data, setValue]);

  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'You have updated your account successfully!',
    place: 'bc'
  });

  const onSubmit = async (data) => {
    try {
      const { firstName, lastName, password } = data;

      await teamAcceptInvitation({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            password,
            token
          }
        }
      });

      reset();
      showNotificationAlert();
      setIsSuccess(true);
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (isLoadingData) {
    return <p className="text-center mb-0">Loading...</p>;
  }

  if (!data) {
    return (
      <>
        <p className="text-center">NOT FOUND!</p>
        <p className="text-center mb-0">
          Go to <Link to={LOGIN_FULL_PATH}>Login</Link>.
        </p>
      </>
    );
  }

  return (
    <>
      {isSuccess && (
        <div>
          <p className="text-center">SUCCESS!</p>
          <p className="text-center mb-0">
            Go to <Link to={LOGIN_FULL_PATH}>Login</Link>.
          </p>
        </div>
      )}
      {!isSuccess && (
        <>
          <div className="text-center text-muted mb-1">
            <small>{email}</small>
          </div>
          <div className="text-center text-muted mb-4">
            <small>You’ve been invited to join {data.getAcceptInvitationData.teamName}.</small>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                placeholder="First Name"
                type="text"
                {...replaceRefToInneRef(register('firstName', { required: true }))}
                invalid={errors.hasOwnProperty('firstName')}
              />
              {errors.hasOwnProperty('firstName') && (
                <FormFeedback>First Name is required!</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Last Name"
                type="text"
                {...replaceRefToInneRef(register('lastName', { required: true }))}
                invalid={errors.hasOwnProperty('lastName')}
              />
              {errors.hasOwnProperty('lastName') && (
                <FormFeedback>Last Name is required!</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <FormPassword
                {...register('password', {
                  required: true,
                  minLength: 8,
                  pattern: passwordPattern
                })}
                placeholder="Password"
                invalid={errors.hasOwnProperty('password')}
                feedbackElements={
                  <>
                    {errors?.password?.type === 'required' && (
                      <FormFeedback>Password is required!</FormFeedback>
                    )}
                    {errors?.password?.type === 'minLength' && (
                      <FormFeedback>The minimum password length is 8!</FormFeedback>
                    )}
                    {errors?.password?.type === 'pattern' && (
                      <FormFeedback>
                        The password should contain at least 1 lowercase, 1 uppercase, 1 special
                        character, and 1 number.
                      </FormFeedback>
                    )}
                  </>
                }
              />
            </FormGroup>

            <FormGroup>
              <FormPassword
                {...register('confirmPassword', {
                  required: true,
                  minLength: 8,
                  pattern: passwordPattern,
                  validate: (val) => {
                    if (watch('password') !== val) {
                      return 'The password fields do not match';
                    }
                  }
                })}
                placeholder="Confirm Password"
                invalid={errors.hasOwnProperty('confirmPassword')}
                feedbackElements={
                  <>
                    {errors?.confirmPassword?.type === 'required' && (
                      <FormFeedback>Confirm Password is required!</FormFeedback>
                    )}
                    {errors?.confirmPassword?.type === 'minLength' && (
                      <FormFeedback>The minimum password length is 8!</FormFeedback>
                    )}
                    {errors?.confirmPassword?.type === 'pattern' && (
                      <FormFeedback>
                        The password should contain at least 1 lowercase, 1 uppercase, 1 special
                        character, and 1 number.
                      </FormFeedback>
                    )}
                    {errors?.confirmPassword?.type === 'validate' && (
                      <FormFeedback>{errors.confirmPassword.message}</FormFeedback>
                    )}
                  </>
                }
              />
            </FormGroup>

            <div className="text-center">
              <Button className="my-4" color="info" type="submit" disabled={isLoadingAccept}>
                {isLoadingAccept && <span>Loading...</span>}
                {!isLoadingAccept && <span>Accept Invite</span>}
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default AcceptInvitationForm;
