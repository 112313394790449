import React, { useEffect, useState } from 'react';
import FormQuestion from './FormQuestion';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { textEllipsis } from 'helpers/global';
import { GET_SURVEY, GET_SURVEY_QUESTION, GET_SURVEY_QUESTION_ANSWERS } from 'graphql/queries';
import { SURVEY_QUESTION_UPDATE } from 'graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import {
  graphqlQuestionTypeToQuestionTypeMap,
  questionTypeToGraphqlQuestionTypeMap
} from 'constants/global';
import { questionTypes } from 'constants/questionTypes';
import { SURVEY_QUESTION_ANSWER_CRUD } from 'graphql/mutations';
import { getImage } from 'helpers/global';
import { v4 as uuidv4 } from 'uuid';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { SURVEY_FULL_PATH } from 'constants/paths';
import ModalPublishedSurveyRestriction from 'components/modals/ModalPublishedSurveyRestriction';

const EditQuestion = () => {
  const { surveyId, id } = useParams();

  const [isRedirect, setIsRedirect] = useState(false);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [defaultValues, setDefaultValues] = useState(undefined);

  const { data: dataSurvey } = useQuery(GET_SURVEY, {
    variables: { id: surveyId }
  });

  const { loading: isLoadingData, data } = useQuery(GET_SURVEY_QUESTION, {
    variables: { id }
  });

  const { loading: isLoadingDataAnswers, data: dataAnswers } = useQuery(
    GET_SURVEY_QUESTION_ANSWERS,
    {
      variables: { survey_question_id: id, first: 100 }
    }
  );

  const [surveyQuestionUpdate, { loading: isLoadingSave }] = useMutation(SURVEY_QUESTION_UPDATE);

  const [surveyQuestionAnswerCrud, { loading: isLoadingSaveAnswers }] = useMutation(
    SURVEY_QUESTION_ANSWER_CRUD
  );

  useEffect(() => {
    if (data && data.getSurveyQuestion && dataAnswers) {
      const questionType = graphqlQuestionTypeToQuestionTypeMap[data.getSurveyQuestion.type];
      const selectedQuestionType = questionTypes[questionType];

      const localDefaultValues = {};

      for (let i = 0; i < selectedQuestionType.fields.length; i++) {
        const field = selectedQuestionType.fields[i];

        localDefaultValues.questionType = questionType;

        if (field === 'skipOption') {
          localDefaultValues.skipOption = data.getSurveyQuestion.skippable || false;
          localDefaultValues.skipText = data.getSurveyQuestion.skippableText || '';
          localDefaultValues.skipRoute = data.getSurveyQuestion.skipRoute || '';
        } else if (field === 'minimumSelect') {
          localDefaultValues.minimumSelect = data.getSurveyQuestion.minimumSelects || '';
        } else if (field === 'route') {
          localDefaultValues.route = data.getSurveyQuestion.routeToQuestion || '';
        } else if (field === 'alertEmail') {
          localDefaultValues.alertEmail = data.getSurveyQuestion.alertEmail?.join(', ') || '';
        } else if (field === 'presetDataSet') {
          localDefaultValues.presetDataSet = data.getSurveyQuestion.presetDataSetId || '';
        } else {
          localDefaultValues[field] = data.getSurveyQuestion[field];
        }
      }

      if (questionType === 'statement') {
        localDefaultValues['statement'] = data.getSurveyQuestion.question || '';
      }

      if (selectedQuestionType.hasOwnProperty('hasAnswers') && selectedQuestionType.hasAnswers) {
        localDefaultValues.answers = {};
        localDefaultValues.answers[selectedQuestionType.keyAnswer] =
          dataAnswers.getSurveyQuestionAnswers.data.length >= 1
            ? dataAnswers.getSurveyQuestionAnswers.data.map((dt) => {
                let dtReturn = {
                  id: uuidv4(),
                  backendId: dt.id,
                  sortOrder: dt.sortOrder
                };

                if (dt.data.answerText) {
                  dtReturn.answer = dt.data.answerText;
                }

                if (dt.data.alertEmail) {
                  dtReturn.email = dt.data.alertEmail.join(', ');
                }

                if (dt.data.icon) {
                  dtReturn.currentIcon = dt.data.icon ? getImage(dt.data.icon) : undefined;
                }

                if (dt.data.routeToQuestion) {
                  dtReturn.route = dt.data.routeToQuestion;
                }

                return dtReturn;
              })
            : [];
      }

      setDefaultValues(localDefaultValues);
    }
  }, [data, dataAnswers]);

  const onSubmit = async (data) => {
    try {
      const input = {
        id,
        type: questionTypeToGraphqlQuestionTypeMap[data.questionType],
        question: data.question
        // sortOrder: 1
      };

      if (data.mandatory !== undefined) {
        input.mandatory = data.mandatory;
      }

      if (data.questionIcon !== undefined) {
        input.icon = data.questionIcon;
      }

      if (data.buttonText !== undefined) {
        input.buttonText = data.buttonText;
      }

      if (data.nextOnSelect !== undefined) {
        input.nextOnSelect = data.nextOnSelect;
      }

      if (data.emoticon4Options !== undefined) {
        input.emoticon4Options = data.emoticon4Options;
      }

      if (data.skipOption !== undefined) {
        input.skippable = data.skipOption;
      }

      if (data.skipText !== undefined) {
        input.skippableText = data.skipText;
      }

      if (data.skipRoute !== undefined) {
        input.skipRoute = data.skipRoute;
      }

      if (data.routeToQuestion !== undefined) {
        input.routeToQuestion = data.routeToQuestion;
      }

      if (data.alertEmail !== undefined) {
        input.alertEmail = data.alertEmail;
      }

      if (data.fieldName !== undefined) {
        input.fieldName = data.fieldName;
      }

      if (data.presetDataSetId !== undefined) {
        input.presetDataSetId = data.presetDataSetId;
      }

      if (data.externalLink !== undefined) {
        input.externalLink = data.externalLink;
      }

      if (data.theEnd !== undefined) {
        input.theEnd = data.theEnd;
      }

      if (data.minimumSelect) {
        input.minimumSelects = parseInt(data.minimumSelect);
      }

      await surveyQuestionUpdate({ variables: { input } });

      if (data.answers !== undefined) {
        const questionAnswers = [];

        console.log('__input', input.emoticon4Options,data.answers.length, input.emoticon4Options ? 4 : data.answers.length);

        for (let i = 0; i < (input.emoticon4Options ? 4 : data.answers.length); i++) {
          const answer = data.answers[i];

          const questionAnswer = {
            sortOrder: i + 1
          };

          if (answer.backendId !== undefined) {
            questionAnswer.id = answer.backendId;
          }

          if (answer.answer !== undefined) {
            questionAnswer.answerText = answer.answer;
          }

          if (answer.email !== undefined) {
            questionAnswer.alertEmail = answer.email;
          }

          if (answer.icon !== undefined) {
            questionAnswer.icon = answer.icon;
          }

          if (answer.route !== undefined) {
            questionAnswer.routeToQuestion = answer.route;
          }

          questionAnswers.push(questionAnswer);
        }

        await surveyQuestionAnswerCrud({
          variables: {
            questionId: id,
            questionAnswers
          }
        });
      }

      showNotificationAlert({
        title: 'Success',
        message: 'You have updated the question successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  const disabled = dataSurvey?.getSurvey.status === 'PUBLISHED';

  const [showModalBasicPlanRestriction, setShowModalBasicPlanRestriction] = useState(false);

  useEffect(() => {
    setShowModalBasicPlanRestriction(disabled);
  }, [disabled]);

  if (isRedirect) {
    return <Redirect to={`${SURVEY_FULL_PATH}/${surveyId}/edit`} />;
  }

  return (
    <>
      <ModalPublishedSurveyRestriction
        isShow={showModalBasicPlanRestriction}
        onClose={() => setShowModalBasicPlanRestriction(false)}
      />

      <SimpleHeader
        name="Edit Question & Answers"
        firstColProps={{ xs: 8, sm: 11, lg: 11 }}
        secondColProps={{ xs: 4, sm: 1, lg: 1 }}
        parents={[
          { to: SURVEY_FULL_PATH, label: 'My Surveys' },
          {
            to: `${SURVEY_FULL_PATH}/${surveyId}/edit`,
            label: dataSurvey ? textEllipsis(dataSurvey.getSurvey.name) : '',
            title: dataSurvey?.getSurvey.name
          }
        ]}
        rightElements={
          <Link
            to={`${SURVEY_FULL_PATH}/${surveyId}/edit`}
            className="btn btn-neutral btn-sm rounded-pill"
            color="default"
            size="sm">
            Back
          </Link>
        }
      />
      {(isLoadingData || isLoadingDataAnswers || defaultValues === undefined) && (
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Question Details</h3>
            </CardHeader>
            <CardBody>Loading...</CardBody>
          </Card>
        </Container>
      )}
      {!isLoadingData && !isLoadingDataAnswers && defaultValues !== undefined && (
        <FormQuestion
          onSubmit={onSubmit}
          isLoadingSave={isLoadingSave || isLoadingSaveAnswers}
          currentQuestionIcon={
            data.getSurveyQuestion.icon ? getImage(data.getSurveyQuestion.icon) : ''
          }
          defaultValues={defaultValues}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default EditQuestion;
