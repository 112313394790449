import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate
} from 'services/fake/surveyApi';

export const getAll = createAsyncThunk('survey/getAll', async () => {
  const data = await apiGetAll();
  return data;
});

export const getOne = createAsyncThunk('survey/getOne', async (id) => {
  const data = await apiGetOne(id);
  return data;
});

export const insert = createAsyncThunk('survey/insert', async (data) => {
  await apiInsert(data);
  return true;
});

export const update = createAsyncThunk('survey/update', async (id, data) => {
  await apiUpdate(id, data);
  return true;
});

export const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    isLoadingDatas: false,
    datas: [],
    isLoadingData: false,
    data: undefined,
    isLoadingSave: false
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });
  }
});

export const { formReset } = surveySlice.actions;

export default surveySlice.reducer;
