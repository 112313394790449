import { SUBSCRIPTION_FULL_PATH } from 'constants/paths';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';

const ModalBasicPlanRestriction = ({ isShow, onClose, level }) => {
  let title = 'You do not have access to this area';
  let titleDescription = 'You need a Pro Plan to access Company';
  let description =
    'Please, get in touch with an admin on your team to upgrade to Pro and have access to Company';

  let actions = (
    <Link to="/admin" className="text-white ml-auto btn btn-link" onClick={onClose}>
      Back to Dashboard
    </Link>
  );

  if (level === 'ADMIN') {
    description = 'Please, upgrade to Pro to have access to Company';

    actions = (
      <>
        <Link to={SUBSCRIPTION_FULL_PATH} className="btn btn-white">
          Manage Subscription
        </Link>
        <Link to="/admin" className="text-white ml-auto btn btn-link" onClick={onClose}>
          Back to Dashboard
        </Link>
      </>
    );
  }

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isShow}
      toggle={onClose}
      backdrop="static"
      keyboard={false}>
      <div className="modal-header justify-content-center">
        <h6 className="modal-title">{title}</h6>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">{titleDescription}</h4>
          <p>{description}</p>
        </div>
      </div>
      <div className="modal-footer">{actions}</div>
    </Modal>
  );
};

export default ModalBasicPlanRestriction;
