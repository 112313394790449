import React, { useState } from 'react';
import { Button, FormGroup, Input, FormFeedback } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import FormPassword from 'components/forms/FormPassword';
import { passwordPattern } from 'constants/global';
import { useMutation, useQuery } from '@apollo/client';
import { GET_UNVERIFIED_USER_DATA } from 'graphql/queries';
import { USER_REGISTRATION_VERIFY, LOGIN } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { useDispatch } from 'react-redux';
import { setMe } from 'redux/reducers/auth';
import { setIsLogin } from 'redux/reducers/auth';
import { Redirect } from 'react-router';
import { DASHBOARD_FULL_PATH } from 'constants/paths';

const VerifyForm = ({ email, email_verification_token }) => {
  const { loading: isLoadingData, data } = useQuery(GET_UNVERIFIED_USER_DATA, {
    variables: { email, token: email_verification_token }
  });

  const [redirect, setRedirect] = useState(false);

  const [userRegistrationVerify, { loading: isLoadingRegistrationVerify }] =
    useMutation(USER_REGISTRATION_VERIFY);

  const [login, { loading: isLoadingLogin }] = useMutation(LOGIN);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'You have created your account successfully!',
    place: 'bc'
  });

  const onSubmit = async (data) => {
    try {
      const { teamName, password } = data;

      await userRegistrationVerify({
        variables: {
          input: {
            teamName,
            email,
            password,
            emailVerificationToken: email_verification_token
          }
        }
      });

      const { data: dataLogin } = await login({ variables: { input: { email, password } } });
      dispatch(setMe(dataLogin.login));
      dispatch(setIsLogin(true));

      reset();
      showNotificationAlert();
      setRedirect(true);
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (isLoadingData) {
    return <p className="text-center mb-0">Loading...</p>;
  }

  if (!data) {
    return <p className="text-center mb-0">NOT FOUND!</p>;
  }

  if (redirect) {
    return <Redirect to={DASHBOARD_FULL_PATH} />;
  }

  return (
    <>
      <div className="text-center text-black mb-1">
        <small>Your email address has been confirmed!</small>
      </div>
      <div className="text-center text-black mb-4">
        <small>
          Please complete the details below to finish creating your account and start your Free
          Trial on Gather.
        </small>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Input
            placeholder="Company Name"
            type="text"
            {...replaceRefToInneRef(register('teamName', { required: true }))}
            invalid={errors.hasOwnProperty('teamName')}
          />
          {errors.hasOwnProperty('teamName') && (
            <FormFeedback>Company Name is required!</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <FormPassword
            {...register('password', {
              required: true,
              minLength: 8,
              pattern: passwordPattern
            })}
            placeholder="Password"
            invalid={errors.hasOwnProperty('password')}
            feedbackElements={
              <>
                {errors?.password?.type === 'required' && (
                  <FormFeedback>Password is required!</FormFeedback>
                )}
                {errors?.password?.type === 'minLength' && (
                  <FormFeedback>The minimum password length is 8!</FormFeedback>
                )}
                {errors?.password?.type === 'pattern' && (
                  <FormFeedback>
                    The password should contain at least 1 lowercase, 1 uppercase, 1 special
                    character, and 1 number.
                  </FormFeedback>
                )}
              </>
            }
          />
        </FormGroup>

        <FormGroup>
          <FormPassword
            {...register('confirmPassword', {
              required: true,
              minLength: 8,
              pattern: passwordPattern,
              validate: (val) => {
                if (watch('password') !== val) {
                  return 'The password fields do not match';
                }
              }
            })}
            placeholder="Confirm Password"
            invalid={errors.hasOwnProperty('confirmPassword')}
            feedbackElements={
              <>
                {errors?.confirmPassword?.type === 'required' && (
                  <FormFeedback>Confirm Password is required!</FormFeedback>
                )}
                {errors?.confirmPassword?.type === 'minLength' && (
                  <FormFeedback>The minimum password length is 8!</FormFeedback>
                )}
                {errors?.confirmPassword?.type === 'pattern' && (
                  <FormFeedback>
                    The password should contain at least 1 lowercase, 1 uppercase, 1 special
                    character, and 1 number.
                  </FormFeedback>
                )}
                {errors?.confirmPassword?.type === 'validate' && (
                  <FormFeedback>{errors.confirmPassword.message}</FormFeedback>
                )}
              </>
            }
          />
        </FormGroup>

        <div className="text-center">
          <Button
            className="mt-4 rounded-pill w-100"
            color="brand"
            type="submit"
            disabled={isLoadingRegistrationVerify}>
            {(isLoadingRegistrationVerify || isLoadingLogin) && <span>Loading...</span>}
            {!isLoadingRegistrationVerify && !isLoadingLogin && <span>Confirm</span>}
          </Button>
        </div>
      </form>
    </>
  );
};

export default VerifyForm;
