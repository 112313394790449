import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import { useParams } from 'react-router-dom';
import FormColorPicker from 'components/forms/FormColorPicker';
import ListQuestions from './ListQuestions';
import UppyUploader from 'components/UppyUploader';
import { useState, useEffect } from 'react';
import { getSurveyUrl } from 'helpers/global';
import { GET_TEAM_MEMBERS, GET_LIST_FONTS } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import ModalShareSurvey from './ModalShareSurvey';
import { viewSurveyTitle } from 'helpers/global';
import DisabledFormColorPicker from 'components/disabledForms/ColorPicker';
import DisabledFormImageUploader from 'components/disabledForms/ImageUploader';
import Select from 'react-select'

const FormSurvey = ({
  onSubmit,
  type,
  isLoadingSave,
  defaultValues,
  currentLogo,
  currentBackgroundImage,
  status,
  onPublish,
  totalQuestion,
  disabled,
  onChangeVisibilityControl,
  showVisibilityInput
}) => {
  const { id: surveyId } = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ defaultValues });

  const [isModalShareShow, setIsModalShareShow] = useState();
  const [isModalSharePublished, setIsModalSharePublished] = useState();

  const { loading: isLoadingFonts, data: dataFonts } = useQuery(GET_LIST_FONTS);
  const { loading: isLoadingTeamMembers, data: dataTeamMembers } = useQuery(GET_TEAM_MEMBERS, {
    variables: { first: 100, page: 1 },
    notifyOnNetworkStatusChange: true
  });

  const [totalQuestionLocal, setTotalQuestionLocal] = useState(totalQuestion || 0);
  const [visibilityOpts, setVisibilityOpts] = useState([]);
  const [visibilityDefault, setVisibilityDefault] = useState();
  const [visibilityValue, setVisibilityValue] = useState(defaultValues?.visibility);

  const localSubmit = (data) => {
    data.visibility = visibilityValue
    onSubmit(data);
  };

  useEffect(() => {
    if (!isLoadingTeamMembers) {
      if (!dataTeamMembers?.getTeamMembers) {
        return
      }
      let visibilityOpts = dataTeamMembers.getTeamMembers.data?.map((member) => {
        return { value: member.id, label: member.firstName + ' ' + member.lastName }
      });
      setVisibilityOpts(visibilityOpts)
      setVisibilityDefault(visibilityOpts.filter(opt => defaultValues?.visibility.includes(opt.value)))
    }
  }, [isLoadingTeamMembers, dataTeamMembers]);

  return (
    <>
      <ModalShareSurvey
        url={getSurveyUrl(surveyId)}
        onClose={() => setIsModalShareShow(false)}
        isShow={isModalShareShow}
        isPublished={isModalSharePublished}
      />
      <Container className="mt--6" fluid>
        <form onSubmit={handleSubmit(localSubmit)}>
          <Row>
            <Col>
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-brand">Survey Details</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="survey-name" md="3">
                        Survey Name
                      </Label>
                      <Col md="9">
                        <Input
                          id="survey-name"
                          type="text"
                          {...replaceRefToInneRef(register('name', { required: true }))}
                          invalid={errors.hasOwnProperty('name')}
                          disabled={disabled}
                        />
                        {errors.hasOwnProperty('name') && (
                          <FormFeedback>Survey Name is required!</FormFeedback>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="example-text-input" md="3">
                        Logo
                      </Label>
                      <Col md="9">
                        <Controller
                          control={control}
                          name="logo"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormImageUploader image={currentLogo} />;
                            }

                            return (
                              <UppyUploader
                                currentImage={currentLogo}
                                title="Choose Logo"
                                onChange={(path) => {
                                  onChange(path);
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="logoAlignment" md="3">
                        Logo Alignment
                      </Label>
                      <Col md="9">
                        <Input
                          id="logoAlignment"
                          type="select"
                          disabled={disabled}
                          {...replaceRefToInneRef(register('logoAlignment'))}>
                          <option value="Left">Left</option>
                          <option value="Center">Center</option>
                          <option value="Right">Right</option>
                        </Input>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="backgroundColor" md="3">
                        Background Colour
                      </Label>
                      <Col md="9" className="d-flex align-items-center">
                        <Controller
                          control={control}
                          name="backgroundColor"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormColorPicker color={value} />;
                            }

                            return <FormColorPicker value={value} onChange={onChange} />;
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="backgroundImage" md="3">
                        Background Image
                      </Label>
                      <Col md="9">
                        <Controller
                          control={control}
                          name="backgroundImage"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormImageUploader image={currentBackgroundImage} />;
                            }

                            return (
                              <UppyUploader
                                currentImage={currentBackgroundImage}
                                title="Choose Background Image"
                                onChange={(path) => {
                                  onChange(path);
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="buttonColor" md="3">
                        Button Colour
                      </Label>
                      <Col md="9" className="d-flex align-items-center">
                        <Controller
                          control={control}
                          name="buttonColor"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormColorPicker color={value} />;
                            }

                            return <FormColorPicker value={value} onChange={onChange} />;
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="buttonTextColor" md="3">
                        Button Text Colour
                      </Label>
                      <Col md="9" className="d-flex align-items-center">
                        <Controller
                          control={control}
                          name="buttonTextColor"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormColorPicker color={value} />;
                            }

                            return <FormColorPicker value={value} onChange={onChange} />;
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="font" md="3">
                        Font
                      </Label>
                      <Col md="9">
                        {isLoadingFonts && <div className="col-form-label">Loading...</div>}
                        {!isLoadingFonts && (
                          <Input
                            id="font"
                            type="select"
                            {...replaceRefToInneRef(register('font'))}
                            disabled={disabled}>
                            <option value="">Select a Font</option>
                            {dataFonts?.getListFonts?.map((font) => (
                              <option value={font.family} key={font.family}>
                                {font.family}
                              </option>
                            ))}
                          </Input>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="survey-name" md="3">
                        Font Colour
                      </Label>
                      <Col md="9">
                        <Controller
                          control={control}
                          name="fontColor"
                          render={({ field: { onChange, value } }) => {
                            if (disabled) {
                              return <DisabledFormColorPicker color={value} />;
                            }

                            return <FormColorPicker value={value} onChange={onChange} />;
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="example-text-input" md="3">
                        Restart Survey after submission
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          <label className="custom-toggle mr-1">
                            <input type="checkbox" {...register('restartSurvey')} />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row visibility">
                      <Label className="form-control-label" htmlFor="example-text-input" md="3">
                        Survey Visibility
                      </Label>
                      <Col md="3">
                        <div className="col-form-label">
                          <label className={`${"custom-toggle mr-1" + (showVisibilityInput ? ' on' : '')}`}>
                            <input type="checkbox" {...register('visibilityControl')} onChange={(e) => {
                              onChangeVisibilityControl(e.target.checked)
                            }} />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Public"
                              data-label-on="Private"
                            />
                          </label>
                        </div>
                      </Col>
                      <Col md="6">
                        {showVisibilityInput && (<FormGroup className="row">
                          <Label className="form-control-label" htmlFor="font" md="2">
                            Visible to
                          </Label>
                          <Col md="6">
                            {isLoadingTeamMembers && <div className="col-form-label">Loading...</div>}
                            {!isLoadingTeamMembers && visibilityDefault && (
                              <Select options={visibilityOpts}
                                isMulti
                                defaultValue={visibilityDefault}
                                onChange={(e) => {
                                  setVisibilityValue(e.map(e => {
                                    return e.value
                                  }))
                                }}
                              />
                            )}
                          </Col>
                        </FormGroup>)}
                      </Col>
                    </FormGroup>

                    {(type === 'add' || status === 'DRAFT') && (
                      <Button color="primary" disabled={isLoadingSave} className="rounded-pill">
                        {isLoadingSave && <span>Loading...</span>}
                        {!isLoadingSave && (
                          <span>{type === 'add' ? 'Save and Continue' : 'Save'}</span>
                        )}
                      </Button>
                    )}
                    {type === 'edit' && status === 'DRAFT' && totalQuestionLocal >= 1 && (
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => onPublish('toPublish')}
                        className="rounded-pill">
                        Publish Survey
                      </Button>
                    )}
                    {type === 'edit' && status === 'PUBLISHED' && (
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => onPublish('toDraft')}
                        className="rounded-pill">
                        Revert to Draft
                      </Button>
                    )}
                    {type === 'edit' && totalQuestionLocal >= 1 && (
                      <Button
                        type="button"
                        className="rounded-pill"
                        onClick={() => {
                          setIsModalShareShow(true);
                          setIsModalSharePublished(status === 'PUBLISHED');
                        }}>
                        {viewSurveyTitle(status === 'PUBLISHED')}
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </form>
        <ListQuestions
          surveyId={surveyId}
          type={type}
          status={status}
          disabled={disabled}
          onCalculateLength={(total) => {
            setTotalQuestionLocal(total);
          }}
        />
      </Container>
    </>
  );
};

export default FormSurvey;
