const DisabledFormColorPicker = ({ color }) => {
  return (
    <span
      style={{
        width: '24px',
        height: '24px',
        border: '1px solid #dedede',
        display: 'inline-block',
        backgroundColor: color ?? '#ffffff'
      }}></span>
  );
};

export default DisabledFormColorPicker;
