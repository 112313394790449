import React, { useState } from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const FormWYSWYG = ({ value, onChange, isError }) => {
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHtml = htmlToDraft(value || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  });

  return (
    <Editor
      editorState={editorState}
      textAlignment="center"
      toolbarClassName=""
      wrapperClassName=""
      editorClassName={`form-control${isError ? ' is-invalid' : ''}`}
      onEditorStateChange={(editorState) => {
        setEditorState(editorState);
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }}
      toolbar={{
        options: ['inline', 'textAlign', 'link', 'fontSize'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']
        },
        textAlign: { options: ['left', 'center', 'right'] },
        fontSize: {
          options: ['50%', '100%', '150%']
        }
      }}
      stripPastedStyles={true}
    />
  );
};

export default FormWYSWYG;
