import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'select2/dist/css/select2.min.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.2.0';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import AcceptInvitation from 'views/pages/AcceptInvitation';
import { Provider } from 'react-redux';
import store from 'redux/store.js';
import { ApolloProvider } from '@apollo/client';
import { noCacheClient } from 'helpers/apolloClient';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import App from 'layouts/App';
import AuthenticatedOrNot from 'layouts/AuthenticatedOrNot';
import VerifyEmailUpdate from 'views/pages/VerifyEmailUpdate';
import Verify from 'views/pages/Verify';
import { DASHBOARD_FULL_PATH, VERIFY_EMAIL_UPDATE_PATH, VERIFY_PATH } from 'constants/paths';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={noCacheClient}>
      <BrowserRouter>
        <App>
          <Switch>
            <Route
              path={['/admin', '/auth']}
              render={(props) => <AuthenticatedOrNot {...props} />}
            />
            <Route path="/accept-invitation" render={(props) => <AcceptInvitation {...props} />} />
            <Route
              path={VERIFY_EMAIL_UPDATE_PATH}
              render={(props) => <VerifyEmailUpdate {...props} />}
            />
            <Route path={VERIFY_PATH} render={(props) => <Verify {...props} />} />
            <Redirect from="*" to={DASHBOARD_FULL_PATH} />
          </Switch>
        </App>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);
