//import { textEllipsis } from 'helpers/global';
import { removeHtmlTags } from 'helpers/global';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import PieChartItem from './PieChartItem';
import DoughnutChartItem from './DoughnutChartItem';
import { graphqlQuestionTypeToQuestionTypeMap } from 'constants/global';
import { questionTypes } from 'constants/questionTypes';

const PieChart = ({ datas }) => {
  return (
    <Row>
      {datas?.map((reportChart, index) => {
        const label =
          questionTypes[graphqlQuestionTypeToQuestionTypeMap[reportChart.questionType]].label;

        return (
          <Col xs={12} sm={6} key={`pie-chart-${reportChart.name}`} className="mb-card">
            <Card className="mb-0 h-100">
              <CardHeader>
                <h6 className="surtitle text-black">{label}</h6>
                <h5 className="h3 mb-0 text-brand" dangerouslySetInnerHTML={{__html:removeHtmlTags(reportChart.name) }}></h5>
              </CardHeader>
              <CardBody className="d-flex">
                <div className="w-100 mt-auto">
                  {reportChart.type === 'DOUGHNUT_CHART' ? (
                    <DoughnutChartItem index={index} data={reportChart.data} />
                  ) : (
                    <PieChartItem index={index} data={reportChart.data} avg={reportChart.avg} />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default PieChart;
