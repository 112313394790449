import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Link } from 'react-router-dom';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { GET_PRESET_DATA_SETS } from 'graphql/queries';
import { PRESET_DATA_SET_DELETE } from 'graphql/mutations';
import useConfirmation from 'hooks/useConfirmation';
import useNotificationAlert from 'hooks/useNotificationAlert';
import usePagination from 'hooks/usePagination';
import Pagination from 'components/Pagination';
import PresetPageWrapper from './PresetPageWrapper';
import { PRESET_DATA_FULL_PATH } from 'constants/paths';

const perPage = 10;
function Preset(props) {
  const { pageNumber, goToPage } = usePagination({ props });

  const {
    loading: isLoadingDatas,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_PRESET_DATA_SETS, {
    variables: { first: perPage, page: pageNumber },
    notifyOnNetworkStatusChange: true
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [presetDataSetDelete, { loading: isLoadingDestroy }] = useMutation(PRESET_DATA_SET_DELETE);

  const { showConfirmation, hideConfirmation } = useConfirmation(isLoadingDestroy);

  const onDelete = async (id) => {
    try {
      await presetDataSetDelete({ variables: { id: id } });

      showNotificationAlert({
        title: 'Success',
        message: 'You have deleted the preset successfully'
      });
      refetch();
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  return (
    <PresetPageWrapper>
      <SimpleHeader
        name="Preset Data"
        description="Preset Data increases your efficiency by enabling you to create data sets that you can refer to in your surveys thereby reducing the need to retype these each time. For example demographic information such as Age Groups."
        rightElements={
          <>
            <Link
              to={`${PRESET_DATA_FULL_PATH}/add`}
              className="btn btn-neutral btn-sm rounded-pill"
              color="default"
              size="sm">
              Add New Data Set
            </Link>
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-brand">List Preset Data</h3>
                  </Col>
                </Row>
              </CardHeader>

              {(isLoadingDatas || networkStatus === NetworkStatus.refetch) && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getPresetDataSets?.data.length <= 0 && (
                  <CardBody>
                    <p className="text-center">There is no data!</p>
                  </CardBody>
                )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getPresetDataSets?.data.length >= 1 && (
                  <>
                    <Table className="align-items-center table-flush" responsive striped>
                      <thead className="thead-light">
                        <tr>
                          <th>Data Set</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.getPresetDataSets?.data.map((preset) => (
                          <tr key={`preset-item-${preset.id}`}>
                            <td>{preset.name}</td>
                            <td className="table-actions">
                              <div className="d-flex">
                                <Link
                                  className="table-action"
                                  to={`${PRESET_DATA_FULL_PATH}/${preset.id}/edit`}
                                  id={`tooltip-edit-${preset.id}`}>
                                  <i className="fas fa-user-edit" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={`tooltip-edit-${preset.id}`}>
                                  Edit Preset Data
                                </UncontrolledTooltip>

                                <button
                                  className="table-action table-action-delete"
                                  id={`tooltip-delete-${preset.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showConfirmation({
                                      title: 'Are you sure want to delete?',
                                      onConfirm: () => onDelete(preset.id)
                                    });
                                  }}>
                                  <i className="fas fa-trash" />
                                </button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-delete-${preset.id}`}>
                                  Delete Preset Data
                                </UncontrolledTooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <Pagination
                        totalRow={data.getPresetDataSets?.paginatorInfo.total}
                        perPage={perPage}
                        initialPage={pageNumber - 1}
                        onPageChange={(data) => {
                          goToPage(data);
                        }}
                      />
                    </CardFooter>
                  </>
                )}
            </Card>
          </div>
        </Row>
      </Container>
    </PresetPageWrapper>
  );
}

export default Preset;
