import React from 'react';
import { useMutation } from '@apollo/client';
import Toggle from 'components/Toggle';
import { SURVEY_ACTIVE_UPDATE } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';

function ToggleSurvey({ id, active, onChange }) {
  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [surveyActiveUpdate, { loading }] = useMutation(SURVEY_ACTIVE_UPDATE);

  const onChangeToggle = async (value) => {
    try {
      await surveyActiveUpdate({ variables: { id, active: value } });
      onChange(value);
      showNotificationAlert({
        title: 'Success',
        message: 'You have updated successfully'
      });
    } catch (err) {
      switch (err.message) {
        case 'update_not_permitted':
          showNotificationAlert({
            title: 'Failed',
            type: 'danger',
            message: 'Update Not Permitted!'
          });
          break;
        default:
          showNotificationAlert({
            title: 'Failed',
            type: 'danger',
            message: err.message
          });
          break;
      }
    }
  };

  return <Toggle active={active} onChange={(value) => onChangeToggle(value)} isLoading={loading} />;
}

export default ToggleSurvey;
