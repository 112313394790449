const datas = [
  {
    id: 1,
    surveyId: 1,
    question: 'How satisfied were you with your visit to our shop?',
    questionType: 'emoticon',
    type: 'Emoticons',
    mandatory: true,
    skipOption: true,
    skipText: 'Skip',
    buttonText: 'Continue',
    nextOnSelect: true,
    values: [
      {
        answer: 'It was crap!',
        alert: 'del@chickenshop.com, caleb@chcken',
        route: 45
      },
      {
        answer: 'Not the best',
        alert: 'del@chickenshop.com, caleb@chcken',
        route: 45
      },
      {
        answer: 'It was average',
        alert: 'del@chickenshop.com, caleb@chcken',
        route: 45
      },
      {
        answer: 'Pretty good',
        alert: '',
        route: 0
      },
      {
        answer: 'Loved it',
        alert: '',
        route: 0
      }
    ]
  },
  {
    id: 5,
    surveyId: 1,
    question: 'Which dishes did you eat during your visit?',
    questionType: 'multiSelect',
    type: 'Multi Select',
    mandatory: true,
    minimumSelect: 3,
    buttonText: 'Continue',
    values: [
      {
        answer: 'Popcorn Chicken',
        email: ''
      },
      {
        answer: 'Spicy Chicken Wings',
        email: ''
      },
      {
        answer: 'Filet Burger',
        email: 'del@chickenshop.com, caleb@chckenshop.com'
      },
      {
        answer: '2 Piece Combo',
        email: 'del@chickenshop.com, caleb@chckenshop.com'
      },
      {
        answer: 'Family Bucket',
        email: ''
      }
    ]
  },
  {
    id: 7,
    surveyId: 1,
    question: 'Would you order these again?',
    questionType: 'singleSelect',
    type: 'Single Select',
    mandatory: true,
    skipOption: true,
    skipText: 'Move on',
    buttonText: 'Continue',
    values: [
      {
        answer: 'No',
        email: 'del@chickenshop.com, caleb@chckenshop.com'
      },
      {
        answer: 'Maybe',
        email: 'del@chickenshop.com, caleb@chckenshop.com'
      },
      {
        answer: 'Yes',
        email: ''
      }
    ]
  },
  {
    id: 2,
    surveyId: 1,
    question: 'Please tell us the best thing about your visit',
    questionType: 'commentBox',
    type: 'Comment Box',
    mandatory: true,
    skipOption: true,
    skipText: 'Move on',
    buttonText: 'Continue',
    route: 29,
    alert: 'del@chickenshop.com, caleb@chckenshop.com'
  },
  {
    id: 8,
    surveyId: 2,
    question: 'Can we get your email?',
    questionType: 'input',
    type: 'Input',
    mandatory: true,
    skipOption: true,
    skipText: 'Move on',
    buttonText: 'Continue',
    route: 29,
    alert: 'del@chickenshop.com, caleb@chckenshop.com'
  },
  {
    id: 9,
    surveyId: 2,
    question: 'Which one of our locations did you visit?',
    questionType: 'presetData',
    type: 'Preset Data',
    mandatory: true,
    skipOption: true,
    skipText: 'Move on',
    buttonText: 'Continue',
    route: 0,
    dataSet: ''
  },
  {
    id: 10,
    surveyId: 2,
    question: 'Which one of our locations did you visit?',
    questionType: 'statement',
    type: 'Statement'
  }
];

export const getAll = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas);
    }, 1000);
  });
};

export const getOne = async (surveyId, id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas.find((elm) => elm.surveyId === parseInt(surveyId) && elm.id === parseInt(id)));
    }, 1000);
  });
};

export const insert = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const update = async (id, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const destroy = async (surveyId, id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
