import { Card, CardBody } from 'reactstrap';
import ResponseTable from './ResponseTable';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GET_SURVEY_REPORT_CHARTS,
  GET_SURVEY_REPORT_RESPONSES,
  GET_SURVEY_QUESTIONS
} from 'graphql/queries';
import DateFilter from './DateFilter';
import SurveysCompleted from './SurveysCompleted';
import PieChart from './PieChart';
import AnswersFilter from './AnswersFilter';
import { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import ResultContext from './ResultContext';
import { endDate, startDate } from './constant';

const Content = () => {
  const { id } = useParams();

  const {
    filterDateFrom,
    setFilterDateFrom,
    filterDateUntil,
    setFilterDateUntil,
    setFilterAnswers, // TODO: move all filter to filterAnswers only. Don't need split to emoticonAnswers or presetDataAnswers
    setFilterEmoticonAnswers,
    setFilterPresetDataAnswers
  } = useContext(ResultContext);

  const {
    loading: isLoadingReportResponses,
    data: dataReportResponses,
    refetch: refetchReportResponses
  } = useQuery(GET_SURVEY_REPORT_RESPONSES, {
    variables: {
      first: 6000,
      page: 1,
      id,
      responsesFrom: startDate,
      responsesUntil: endDate
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: isLoadingReportCharts,
    data: dataReportCharts,
    refetch: refetchDataReportCharts
  } = useQuery(GET_SURVEY_REPORT_CHARTS, {
    variables: {
      first: 100,
      page: 1,
      id,
      responsesFrom: startDate,
      responsesUntil: endDate
    },
    notifyOnNetworkStatusChange: true
  });

  const { loading: isLoadingQuestions, data: dataQuestions } = useQuery(GET_SURVEY_QUESTIONS, {
    variables: { survey_id: id, first: 100, page: 1 },
    notifyOnNetworkStatusChange: true
  });

  const onDateFilterSubmit = (dateFrom, dateUntil) => {
    if ((dateFrom = dateFrom.format('YYYY-MM-DD'))) setFilterDateFrom(dateFrom);
    if ((dateUntil = dateUntil.format('YYYY-MM-DD'))) setFilterDateUntil(dateUntil);

    const variables = {
      responsesFrom: dateFrom,
      responsesUntil: dateUntil
    };

    refetchDataReportCharts(variables);
    refetchReportResponses(variables);

    // update params
    const params = new URL(document.location.href).searchParams;
    params.set('start_date', dateFrom);
    params.set('end_date', dateUntil);
    window.history.replaceState(null, null, '?' + params);
  };

  const [answerFilterDatas, setAnswerFilterDatas] = useState([]);

  const [answerFilters, setAnswerFilters] = useState({});

  useEffect(() => {
    if (dataQuestions) {
      const localAnswerFilterDatas = dataQuestions?.getSurveyQuestions.data.filter(
        (elm) =>
          elm.type === 'EMOTICON' ||
          elm.type === 'SINGLE_SELECT' ||
          elm.type === 'MULTI_SELECT' ||
          elm.type === 'PRESET_DATA'
      );

      const localAnswerFilters = {};
      for (let i = 0; i < localAnswerFilterDatas.length; i++) {
        localAnswerFilters[localAnswerFilterDatas[i].type] = [];
      }

      setAnswerFilterDatas(localAnswerFilterDatas);
      setAnswerFilters(localAnswerFilters);

      // filter by URL query params
      setTimeout(() => {
        filterByUrlParams(localAnswerFilters);
      }, 100);
    }
  }, [dataQuestions]);

  const filterByUrlParams = (answerFilters) => {
    const searchParams = new URLSearchParams(document.location.search);
    const startDateParam = searchParams.get('start_date');
    const endDateParam = searchParams.get('end_date');
    const answersParam = searchParams.get('answers');

    if (!(startDateParam || endDateParam || answersParam)) {
      return;
    }

    let dateVariables = {
      responsesFrom: startDateParam,
      responsesUntil: endDateParam
    };

    let variables = {};
    if (answersParam) {
      let answers = JSON.parse(answersParam);
      if (answers) {
        Object.keys(answers).forEach(function (key) {
          let answers2 = answers[key];
          Object.keys(answers2).forEach(function (key2) {
            if (answers2[key2]) {
              variables = getVariablesFromAnswer(key, key2, answers2[key2], answerFilters);
            }
          });
        });
      }
    }

    refetchDataReportCharts({ ...dateVariables, ...variables });
    refetchReportResponses({ ...dateVariables, ...variables });
  };

  const getVariablesFromAnswer = (questionType, questionId, dataId, answerFilters) => {
    const newAnswerFilters = { ...answerFilters };

    if (!newAnswerFilters[questionType]) {
      newAnswerFilters[questionType] = [];
    }

    const selectedIndex = newAnswerFilters[questionType].findIndex(
      (elm) => elm.questionId === questionId
    );

    if (!dataId) {
      newAnswerFilters[questionType].splice(selectedIndex, 1);
    } else if (selectedIndex < 0) {
      newAnswerFilters[questionType].push({
        questionId,
        dataId
      });
    } else {
      newAnswerFilters[questionType][selectedIndex] = {
        questionId,
        dataId
      };
    }

    setAnswerFilters(newAnswerFilters);

    const emoticonAnswers =
      newAnswerFilters.EMOTICON?.length >= 1
        ? newAnswerFilters.EMOTICON.map((elm) => elm.dataId)
        : undefined;

    const presetDataAnswers =
      newAnswerFilters.PRESET_DATA?.length >= 1
        ? newAnswerFilters.PRESET_DATA.map((elm) => elm.dataId)
        : undefined;

    /**
     * TODO: move all filter to filterAnswers only. Don't need split to emoticonAnswers or presetDataAnswers
     */
    const answers = [];
    Object.keys(newAnswerFilters).forEach((key) => {
      answers.push(...newAnswerFilters[key].map((elm) => elm.dataId));
    });

    const variables = {
      answers,
      emoticonAnswers,
      presetDataAnswers
    };

    setFilterAnswers(answers);
    setFilterEmoticonAnswers(emoticonAnswers);
    setFilterPresetDataAnswers(presetDataAnswers);

    return variables;
  };

  const onAnswersFilterChange = useCallback(
    (questionType, questionId, dataId) => {
      let variables = getVariablesFromAnswer(questionType, questionId, dataId, answerFilters);
      refetchDataReportCharts(variables);
      refetchReportResponses(variables);
    },
    [
      answerFilters,
      refetchDataReportCharts,
      refetchReportResponses,
      setFilterEmoticonAnswers,
      setFilterPresetDataAnswers,
      setFilterAnswers
    ]
  );

  return (
    <>
      <div className="d-print-none">
        <DateFilter
          onSubmit={onDateFilterSubmit}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          className="d-print-none"
        />
      </div>

      <div className="d-print-none">
        <AnswersFilter datas={answerFilterDatas} onChange={onAnswersFilterChange} />
      </div>

      <SurveysCompleted
        dateFrom={filterDateFrom}
        dateUntil={filterDateUntil}
        isLoading={isLoadingReportCharts}
        data={dataReportCharts?.getSurveyReportCharts.find((elm) => elm.type === 'LINE_GRAPH')}
      />

      <PieChart
        datas={dataReportCharts?.getSurveyReportCharts.filter(
          (elm) => elm.type === 'PIE_CHART' || elm.type === 'DOUGHNUT_CHART'
        )}
      />

      <Card className='d-print-none'>
        <CardBody>
          {(isLoadingQuestions || isLoadingReportResponses) && (
            <p className="text-center mb-0">Loading...</p>
          )}
          {!isLoadingQuestions &&
            !isLoadingReportResponses &&
            dataQuestions &&
            dataReportResponses &&
            dataReportResponses.getSurveyReportResponses &&
            dataReportResponses.getSurveyReportResponses.data.length >= 1 && (
              <ResponseTable
                surveyId={id}
                questions={dataQuestions.getSurveyQuestions.data}
                data={dataReportResponses.getSurveyReportResponses.data}
              />
            )}
        </CardBody>
      </Card>
    </>
  );
};

export default Content;
