import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import useQueryParams from 'hooks/useQueryParams';
import VerifyForm from './VerifyForm';

function Verify() {
  const queryParams = useQueryParams();

  const email = queryParams.get('email');
  const email_verification_token = queryParams.get('email_verification_token');

  return (
    <>
      <AuthHeader title="Logo" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                {(!email || !email_verification_token) && <p className="text-center">NOT FOUND!</p>}
                {email && email_verification_token && (
                  <VerifyForm email={email} email_verification_token={email_verification_token} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Verify;
