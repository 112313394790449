const DisabledFormTextarea = ({ content }) => {
  return (
    <div
      className="w-100 px-3 py-3 overflow-auto"
      style={{
        height: '200px',
        border: '1px solid #dedede',
        backgroundColor: '#ffffff'
      }} dangerouslySetInnerHTML={{__html: content}}></div>
  );
};

export default DisabledFormTextarea;
