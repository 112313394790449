import { Button, Modal } from 'reactstrap';

const ModalPublishedSurveyRestriction = ({ isShow, onRevert, onClose }) => {
  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isShow}
      toggle={onClose}
      keyboard={false}>
      <div className="modal-body">
        <div className="py-3 text-center">
          <h4 className="heading mt-4">Survey is not editable</h4>
          <p>
            Published surveys can't be edited. Please revert this survey to Draft to make any
            changes.
          </p>
        </div>
      </div>
      <div className="modal-footer pt-0">
        <Button color="link" className="text-white mx-auto btn btn-link" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ModalPublishedSurveyRestriction;
