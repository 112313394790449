const datas = [
  {
    id: 1,
    name: 'Locations',
    datas: [
      {
        id: '1',
        public: 'Belize',
        internal: 'Caleb'
      },
      {
        id: '2',
        public: 'Brighton',
        internal: 'Roger Rabbit'
      },
      {
        id: '3',
        public: 'Milton Keynes',
        internal: 'Big Red'
      },
      {
        id: '4',
        public: 'Leeds',
        internal: 'Frankie Four Fingers'
      },
      {
        id: '5',
        public: 'Cardiff',
        internal: 'Reggle'
      }
    ]
  },
  {
    id: 2,
    name: 'Staff'
  }
];

export const getAll = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas);
    }, 1000);
  });
};

export const getOne = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas.find((elm) => elm.id === parseInt(id)));
    }, 1000);
  });
};

export const insert = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const update = async (id, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const destroy = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
