import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';
import queryString from 'query-string';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import { LOGIN } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { Redirect, useLocation } from 'react-router';
import { setMe, setIsLogin } from 'redux/reducers/auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { setCurrentPlan } from 'redux/reducers/auth';
import { setIsNoActiveSubscription } from 'redux/reducers/auth';
import { setIsTrialEnded } from 'redux/reducers/auth';
import moment from 'moment';
import {
  DASHBOARD_FULL_PATH,
  REGISTER_FULL_PATH,
  FORGOT_PASSWORD_FULL_PATH
} from 'constants/paths';

function Login() {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);

  const { register, handleSubmit } = useForm();

  const [redirect, setRedirect] = useState(false);

  const [login, { loading }] = useMutation(LOGIN);

  const dispatch = useDispatch();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      const { data } = await login({ variables: { input: { email, password } } });
      dispatch(setMe(data.login));
      dispatch(setIsLogin(true));
      /**
       * BEGIN
       * Make this logic the same with auth/getMe async thunk
       * TODO: Make this 1 function with auth/getMe async thunk
       */
      const isInBasic =
        data.login?.team.currentPlan === 'BASIC_MONTHLY' ||
        data.login?.team.currentPlan === 'BASIC_YEARLY';

      const isInPro =
        data.login?.team.currentPlan === 'PRO_MONTHLY' ||
        data.login?.team.currentPlan === 'PRO_YEARLY';

      const currentPlan = isInPro ? 'PRO' : isInBasic ? 'BASIC' : null;

      dispatch(setCurrentPlan(currentPlan));
      dispatch(setIsNoActiveSubscription(!data.login?.team.onTrial && !currentPlan));

      const dateNow = moment();
      const trialEnds = moment(data.login?.team.trialEndsAt, 'YYYY-MM-DD').add(3, 'days');
      // add how much days to show trial end restriction modal since trial end date.

      dispatch(
        setIsTrialEnded(
          !data.login?.team.onTrial && !currentPlan && dateNow.diff(trialEnds, 'days') <= 0
        )
      );

      /**
       * END
       */
      setRedirect(true);
    } catch (err) {
      if (err.message === 'already_logged') {
        dispatch(setIsLogin(true));
        setRedirect(true);
      } else if (err.message === 'invalid_credentials') {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: 'Invalid Credentials'
        });
      } else {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: err.message
        });
      }
    }
  };

  const location = useLocation();

  if (redirect) {
    const searchObj = queryString.parse(location.search);
    const searches = Object.keys(searchObj);
    if (searches.includes('redirect')) {
      return <Redirect to={searchObj.redirect} />;
    }

    return <Redirect to={DASHBOARD_FULL_PATH} />;
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-white border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        {...replaceRefToInneRef(register('email', { required: true }))}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        {...replaceRefToInneRef(register('password', { required: true }))}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor=" customCheckLogin">
                      <span className="text-black">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-4 rounded-pill w-100"
                      color="primary"
                      type="submit"
                      disabled={loading}>
                      {loading && <span>Loading...</span>}
                      {!loading && <span>Sign in</span>}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-brand text-underline" to={FORGOT_PASSWORD_FULL_PATH}>
                  <small className="font-weight-bold">Forgot password?</small>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="text-brand text-underline" to={REGISTER_FULL_PATH}>
                  <small className="font-weight-bold">Create new account</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
