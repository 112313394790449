import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate,
  destroy as apiDestroy
} from 'services/fake/presetApi';

export const getAll = createAsyncThunk('preset/getAll', async () => {
  const data = await apiGetAll();
  return data;
});

export const getOne = createAsyncThunk('preset/getOne', async (id) => {
  const data = await apiGetOne(id);
  return data;
});

export const insert = createAsyncThunk('preset/insert', async (data) => {
  await apiInsert(data);
  return true;
});

export const update = createAsyncThunk('preset/update', async (id, data) => {
  await apiUpdate(id, data);
  return true;
});

export const destroy = createAsyncThunk('preset/destroy', async (id) => {
  await apiDestroy(id);
  return true;
});

export const presetSlice = createSlice({
  name: 'preset',
  initialState: {
    isLoadingDatas: false,
    datas: [],
    isLoadingData: false,
    data: undefined,
    isLoadingSave: false,
    isLoadingDestroy: false
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  }
});

export default presetSlice.reducer;
