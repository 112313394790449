import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Link } from 'react-router-dom';
import { GET_SURVEYS } from 'graphql/queries';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { SURVEY_STATUS_UPDATE, SURVEY_DUPLICATE } from 'graphql/mutations';
import ToggleSurvey from './ToggleSurvey';
import useConfirmation from 'hooks/useConfirmation';
import useNotificationAlert from 'hooks/useNotificationAlert';
import Pagination from 'components/Pagination';
import usePagination from 'hooks/usePagination';
import { graphqlStatusToStatusMap } from 'constants/global';
import ModalShareSurvey from './ModalShareSurvey';
import { getSurveyUrl } from 'helpers/global';
import { viewSurveyTitle } from 'helpers/global';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { ARCHIVED_SURVEY_FULL_PATH } from 'constants/paths';
import { ADD_SURVEY_FULL_PATH } from 'constants/paths';

const perPage = 10;
const Survey = (props) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const {
    loading: isLoadingDatas,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_SURVEYS, {
    variables: { statuses: ['PUBLISHED', 'DRAFT'], first: perPage, page: pageNumber },
    notifyOnNetworkStatusChange: true
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [surveyStatusUpdate, { loading: isLoadingSurveyStatusUpdate }] =
    useMutation(SURVEY_STATUS_UPDATE);

  const [surveyDuplicate, { loading: isLoadingSurveyDuplicate }] = useMutation(SURVEY_DUPLICATE);

  const { showConfirmation, hideConfirmation } = useConfirmation(
    isLoadingSurveyDuplicate || isLoadingSurveyStatusUpdate
  );

  const [totalRow, setTotalRow] = useState(0);

  const [surveys, setSurveys] = useState();

  useEffect(() => {
    if (data && data.getSurveys) {
      setTotalRow(data.getSurveys.paginatorInfo.total);
      setSurveys(JSON.parse(JSON.stringify(data.getSurveys.data)));
    }
  }, [data]);

  const onArchive = async (id) => {
    try {
      await surveyStatusUpdate({ variables: { id, status: 'ARCHIVED' } });
      showNotificationAlert({
        title: 'Success',
        message: 'You have archived the survey successfully!'
      });
      refetch();
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const onDuplicate = async (surveyId) => {
    try {
      await surveyDuplicate({ variables: { surveyId } });
      showNotificationAlert({
        title: 'Success',
        message: 'You have duplicated the survey successfully!'
      });
      refetch();
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const onChangeToggle = async (index, value) => {
    setSurveys((localSurveys) => {
      const newSurveys = [...localSurveys];
      newSurveys[index].active = value;
      return newSurveys;
    });
  };

  const [modalShareSurvey, setModalShareSurvey] = useState({
    url: '',
    isShow: false
  });

  const onPreviewSurvey = (surveyId, status) => {
    if (status !== 'PUBLISHED') {
      window.open(getSurveyUrl(surveyId));
    } else {
      setModalShareSurvey((localData) => ({
        ...localData,
        isShow: true,
        isPublished: status === 'PUBLISHED',
        url: getSurveyUrl(surveyId)
      }));
    }
  };

  return (
    <>
      <ModalShareSurvey
        url={modalShareSurvey.url}
        onClose={() => setModalShareSurvey((localData) => ({ ...localData, isShow: false }))}
        isShow={modalShareSurvey.isShow}
        isPublished={modalShareSurvey.isPublished}
      />
      <SimpleHeader
        name="My Surveys"
        description="See your surveys below. Simply select from the icons as to whether you want to; Share
        & Preview, View results, Edit or Archive."
        firstColProps={{ xs: 12 }}
        secondColProps={{ xs: 12, className: 'mt-3 mt-sm-0' }}
        rightElements={
          <>
            <Link
              to={ARCHIVED_SURVEY_FULL_PATH}
              className="btn btn-black btn-sm rounded-pill flex-grow-1 flex-sm-grow-0"
              color="default"
              size="sm">
              Archived Surveys
            </Link>
            <Link
              to={ADD_SURVEY_FULL_PATH}
              className="btn btn-neutral btn-sm rounded-pill flex-grow-1 flex-sm-grow-0"
              color="default"
              size="sm">
              Start a New Survey
            </Link>
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-brand">My Surveys</h3>
                  </Col>
                </Row>
              </CardHeader>
              {(isLoadingDatas || networkStatus === NetworkStatus.refetch) && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getSurveys?.data.length <= 0 && (
                  <CardBody>
                    <p className="text-center">There is no data!</p>
                  </CardBody>
                )}

              {!isLoadingDatas &&
                networkStatus !== NetworkStatus.refetch &&
                data?.getSurveys?.data.length >= 1 && (
                  <>
                    <Table className="align-items-center table-flush" responsive striped>
                      <thead className="thead-light">
                        <tr>
                          <th>Survey Name</th>
                          <th>Last Edited</th>
                          <th>Last Response</th>
                          <th className="text-right">Responses</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveys?.map((data, index) => (
                          <tr key={`survey-item-${data.id}`}>
                            <td>
                              {data.name} &nbsp;
                              {data.visibility && <i className="fas fa-lock" />}
                            </td>
                            <td>
                              <span className="text-muted">{data.updatedAt}</span>
                            </td>
                            <td>
                              <span className="text-muted">{data.lastResponse}</span>
                            </td>
                            <td className="text-right">{data.totalResponses}</td>
                            <td>
                              <h4>
                                <Badge color={data.status === 'PUBLISHED' ? 'success' : 'danger'}>
                                  {graphqlStatusToStatusMap[data.status]}
                                </Badge>
                              </h4>
                            </td>
                            <td>
                              {data.status === 'PUBLISHED' && (
                                <ToggleSurvey
                                  id={data.id}
                                  active={data.active}
                                  onChange={(value) => onChangeToggle(index, value)}
                                />
                              )}
                            </td>
                            <td className="table-actions">
                              <div className="d-flex">
                                {data.totalQuestions >= 1 && (
                                  <>
                                    <button
                                      className="table-action table-action-preview"
                                      id={`tooltip-preview-${data.id}`}
                                      onClick={(e) => onPreviewSurvey(data.id, data.status)}>
                                      <i className="fas fa-eye" />
                                    </button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`tooltip-preview-${data.id}`}>
                                      {viewSurveyTitle(data.status === 'PUBLISHED')}
                                    </UncontrolledTooltip>
                                  </>
                                )}
                                <Link
                                  to={`${SURVEY_FULL_PATH}/${data.id}/result`}
                                  className="table-action table-action-result"
                                  id={`tooltip-result-${data.id}`}>
                                  <i className="fas fa-list" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={`tooltip-result-${data.id}`}>
                                  View Results
                                </UncontrolledTooltip>
                                <Link
                                  className="table-action"
                                  to={`${SURVEY_FULL_PATH}/${data.id}/edit`}
                                  id={`tooltip-edit-${data.id}`}>
                                  <i className="fas fa-user-edit" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={`tooltip-edit-${data.id}`}>
                                  Edit Survey
                                </UncontrolledTooltip>

                                <button
                                  className="table-action table-action-duplicate"
                                  id={`tooltip-duplicate-${data.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showConfirmation({
                                      title: `Are you sure want to duplicate survey "${data.name}"?`,
                                      onConfirm: () => onDuplicate(data.id)
                                    });
                                  }}>
                                  <i className="ni ni-ungroup" />
                                </button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-duplicate-${data.id}`}>
                                  Duplicate Survey
                                </UncontrolledTooltip>

                                <button
                                  className="table-action table-action-archive"
                                  id={`tooltip-archive-${data.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showConfirmation({
                                      title: 'Are you sure want to archive this survey?',
                                      onConfirm: () => onArchive(data.id)
                                    });
                                  }}>
                                  <i className="ni ni-archive-2" />
                                </button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-archive-${data.id}`}>
                                  Archive Survey
                                </UncontrolledTooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <Pagination
                        totalRow={totalRow}
                        perPage={perPage}
                        initialPage={pageNumber - 1}
                        onPageChange={(data) => {
                          goToPage(data);
                        }}
                      />
                    </CardFooter>
                  </>
                )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Survey;
