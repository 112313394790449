const datas = [
  {
    id: 1,
    firstName: 'Nick',
    lastName: 'Didia',
    email: 'nickdidia@gmail.com',
    access: 'Member',
    dateJoined: 'Invited',
    status: 'invited',
    lastLogin: '',
    permissions: {
      1: ['London', 'Berlin'],
      2: ['Swift', 'Java']
    }
  },
  {
    id: 2,
    firstName: 'Brendan',
    lastName: 'O’Brien',
    email: 'brendanbrien@gmail.com',
    access: 'Member',
    dateJoined: '04/25/2022',
    status: 'joined',
    lastLogin: '04/25/2022',
    permissions: {
      1: ['Bali', 'El Salvador'],
      2: ['DevOps', 'React Native']
    }
  },
  {
    id: 3,
    firstName: 'Andy',
    lastName: 'Wallace',
    email: 'AndyWallace@gmail.com',
    access: 'Admin',
    dateJoined: '04/25/2022',
    status: 'joined',
    lastLogin: '04/25/2022'
  },
  {
    id: 4,
    firstName: 'Chris',
    lastName: 'Sheldon',
    email: 'chrissheldon@gmail.com',
    access: 'Member',
    dateJoined: '04/25/2022',
    status: 'joined',
    lastLogin: '04/25/2022',
    permissions: {
      1: ['San Diego', 'Dominican Republic'],
      2: ['Frontend Development', 'Backend Development']
    }
  }
];

export const getAll = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas);
    }, 1000);
  });
};

export const getOne = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas.find((elm) => elm.id === parseInt(id)));
    }, 1000);
  });
};

export const invite = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const update = async (id, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const resend = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const destroy = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
