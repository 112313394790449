import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useMemo } from 'react';
import { textEllipsis } from 'helpers/global';
import ReactTooltip from 'react-tooltip';
import { removeHtmlTags } from 'helpers/global';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

function Table({ columns, data }) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 150,
      width: 200,
      maxWidth: 400
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useBlockLayout,
    useSticky
  );

  // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
  const footerGroups = headerGroups.slice().reverse();
  
  return (
    <div className="response-table">
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.tooltip ? (
                    <span data-tip={htmlEntities.decode(removeHtmlTags(column.tooltip))} dangerouslySetInnerHTML={{__html:column.render('Header')}}></span>
                  ) : (
                    column.render('Header')
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="footer">
          {footerGroups.map((footerGroup) => (
            <div {...footerGroup.getHeaderGroupProps()} className="tr">
              {footerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="td" dangerouslySetInnerHTML={{__html: column.render('Footer')}}></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ResponseTable({ surveyId, questions, data, loading }) {
  const params = new URL(document.location.href).searchParams;
  const columns = useMemo(
    () => [
      {
        Header: 'Questions',
        Footer: 'Questions',
        columns: questions?.map((elm) => {
          return {
            Header: textEllipsis(removeHtmlTags(elm.question), 40),
            tooltip: elm.question,
            Footer: textEllipsis(removeHtmlTags(elm.question), 40),
            accessor: elm.id
          };
        })
      },
      {
        Header: 'Response Info',
        Footer: 'Response Info',
        sticky: 'right',
        columns: [
          {
            Header: 'Response UUID',
            Footer: 'Response UUID',
            accessor: 'responseUuid',
            minWidth: 180,
            width: 180,
            Cell: ({ cell: { value } }) => (
              <Link
                className="font-weight-bold text-brand text-underline"
                to={`/admin/my-surveys/${surveyId}/response/${value}?${params}`}>
                {textEllipsis(value, 9, 9)}
              </Link>
            )
          },
          {
            Header: 'Started on',
            Footer: 'Started on',
            accessor: 'startedAt',
            minWidth: 120,
            width: 120
          },
          {
            Header: 'Completed on',
            Footer: 'Completed on',
            accessor: 'completedAt',
            minWidth: 120,
            width: 120
          }
        ]
      }
    ],
    [questions, surveyId]
  );

  return (
    <>
      <Table
        columns={columns}
        data={data.map((elm) => {
          const returnData = {
            ...elm,
            responseUuid: elm.id,
            startedAt: moment(elm.startedAt).format('DD-MM-YYYY'),
            completedAt: moment(elm.completedAt).format('DD-MM-YYYY')
          };

          if (elm.shownAnswers) {
            for (let i = 0; i < elm.shownAnswers.length; i++) {
              const questionData = elm.shownAnswers[i];
              if (questionData && questionData.surveyQuestionId) {
                returnData[questionData.surveyQuestionId] = questionData.answerText;
              }
            }
          }

          return returnData;
        })}
      />
      <ReactTooltip className="question-tooltip" />
    </>
  );
}

export default ResponseTable;
