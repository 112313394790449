import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const PresetPageWrapper = ({ children }) => {
  const { me } = useSelector((state) => state.auth);

  if (me?.level !== 'ADMIN') {
    return <Redirect to="/admin" />;
  }

  return <>{children}</>;
};

export default PresetPageWrapper;
