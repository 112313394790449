import React, { useState } from 'react';
import FormPreset from './FormPreset';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect, useParams } from 'react-router';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GET_PRESET_DATA_SET } from 'graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { PRESET_DATA_SET_UPDATE, PRESET_DATA_SET_DATA_CRUD } from 'graphql/mutations';
import { GET_PRESET_DATA_SET_DATAS } from 'graphql/queries';
import useNotificationAlert from 'hooks/useNotificationAlert';
import PresetPageWrapper from './PresetPageWrapper';
import { PRESET_DATA_FULL_PATH } from 'constants/paths';
import ModalPresetLimit from 'components/modals/ModalPresetLimit';

const EditPreset = () => {
  const { id } = useParams();

  const { loading: isLoadingData, data } = useQuery(GET_PRESET_DATA_SET, {
    variables: { id }
  });

  const { loading: isLoadingSetDatas, data: dataSetDatas } = useQuery(GET_PRESET_DATA_SET_DATAS, {
    variables: { presetDataSetId: id, first: 100 }
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [presetDataSetUpdate, { loading: isLoadingSave }] = useMutation(PRESET_DATA_SET_UPDATE);

  const [presetDataSetDataCrud, { loading: isLoadingSaveSetDatas }] =
    useMutation(PRESET_DATA_SET_DATA_CRUD);

  const [isRedirect, setIsRedirect] = useState(false);

  const onSubmit = async (data) => {
    const { name, datas } = data;
    try {
      await presetDataSetUpdate({
        variables: { input: { id, name } }
      });

      await presetDataSetDataCrud({
        variables: {
          setId: id,
          setDatas: datas.map((dt, index) => {
            const dataReturn = { name: dt.public, internalName: dt.internal, sortOrder: index + 1 };
            if (dt.hasOwnProperty('backendId')) {
              dataReturn.id = dt.backendId;
            }
            return dataReturn;
          })
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have updated the preset successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        case 'only_10_entries_allowed_for_non_enterprise':
          setShowModalPresetLimit(true);
          break;
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  const [showModalPresetLimit, setShowModalPresetLimit] = useState(false);

  if (isRedirect) {
    return <Redirect to={PRESET_DATA_FULL_PATH} />;
  }

  return (
    <>
      <ModalPresetLimit
        isShow={showModalPresetLimit}
        onClose={() => setShowModalPresetLimit(false)}
      />
      <PresetPageWrapper>
        <SimpleHeader
          name="Edit Preset Data"
          firstColProps={{ xs: 8, sm: 10, lg: 10 }}
          secondColProps={{ xs: 4, sm: 2, lg: 2 }}
          parents={[{ to: PRESET_DATA_FULL_PATH, label: 'Preset' }]}
          rightElements={
            <Link
              to={PRESET_DATA_FULL_PATH}
              className="btn btn-neutral btn-sm rounded-pill"
              color="default"
              size="sm">
              Back
            </Link>
          }
        />
        {(isLoadingData || isLoadingSetDatas) && (
          <Container className="mt--6" fluid>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0 text-brand">Preset Data Details</h3>
                </CardHeader>
                <CardBody>Loading...</CardBody>
              </Card>
            </div>
          </Container>
        )}
        {!isLoadingData && !isLoadingSetDatas && (
          <FormPreset
            onSubmit={onSubmit}
            type="edit"
            isLoadingSave={isLoadingSave || isLoadingSaveSetDatas}
            isLoadingSetDatas={isLoadingSetDatas}
            defaultValues={{
              name: data?.getPresetDataSet?.name,
              datas: dataSetDatas?.getPresetDataSetDatas?.data.map(
                ({ id, internalName, name }) => ({
                  id,
                  public: name,
                  internal: internalName,
                  backendId: id
                })
              )
            }}
          />
        )}
      </PresetPageWrapper>
    </>
  );
};

export default EditPreset;
