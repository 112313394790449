import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate,
  destroy as apiDestroy
} from 'services/fake/questionApi';

export const getAll = createAsyncThunk('question/getAll', async (surveyId) => {
  const data = await apiGetAll(surveyId);
  return data;
});

export const getOne = createAsyncThunk('question/getOne', async ({ surveyId, id }) => {
  const data = await apiGetOne(surveyId, id);
  return data;
});

export const insert = createAsyncThunk('question/insert', async ({ surveyId, data }) => {
  await apiInsert(surveyId, data);
  return true;
});

export const update = createAsyncThunk('question/update', async ({ surveyId, id, data }) => {
  await apiUpdate(surveyId, id, data);
  return true;
});

export const destroy = createAsyncThunk('question/destroy', async ({ surveyId, id }) => {
  await apiDestroy(surveyId, id);
  return true;
});

export const questionSlice = createSlice({
  name: 'question',
  initialState: {
    nextId: 10,
    isLoadingDatas: false,
    datas: [],
    isLoadingData: false,
    data: undefined,
    isLoadingDestroy: false
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  }
});

export default questionSlice.reducer;
