import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import routes from 'constants/routes';
import { useSelector } from 'react-redux';
import { DASHBOARD_FULL_PATH, LOGIN_FULL_PATH } from 'constants/paths';

function Auth() {
  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add('bg-secondary');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove('bg-secondary');
    };
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to={LOGIN_FULL_PATH} />
        </Switch>
      </div>
      <AuthFooter />
    </>
  );
}

const AuthOutter = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { isLogin } = useSelector((state) => state.auth);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (isLogin && !isMounted) {
    return <Redirect to={DASHBOARD_FULL_PATH} />;
  }

  return <Auth />;
};

export default AuthOutter;
