import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { FORGOT_PASSWORD } from 'graphql/mutations';
import { LOGIN_FULL_PATH, REGISTER_FULL_PATH } from 'constants/paths';

function Forgot() {
  const [focusedEmail, setfocusedEmail] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [redirect, setRedirect] = useState(false);

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const onSubmit = async (data) => {
    const { email } = data;

    try {
      const { data } = await forgotPassword({ variables: { input: { email } } });
      if (!data.forgotPassword.success) {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: data.forgotPassword.message
        });
      } else {
        showNotificationAlert({
          title: 'Success',
          message: data.forgotPassword.message
        });
        setRedirect(true);
      }
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (redirect) {
    return <Redirect to={LOGIN_FULL_PATH} />;
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-white border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail
                    })}>
                    <InputGroup
                      className={classnames('input-group-merge input-group-alternative', {
                        'mb-3': !errors.hasOwnProperty('email')
                      })}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Enter your email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                        {...replaceRefToInneRef(register('email', { required: true }))}
                      />
                    </InputGroup>
                    {errors?.email?.type === 'required' && (
                      <FormFeedback className="d-block">Email is required!</FormFeedback>
                    )}
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4 rounded-pill w-100"
                      color="primary"
                      type="submit"
                      disabled={loading}>
                      {loading && <span>Loading...</span>}
                      {!loading && <span>Submit</span>}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-brand text-underline" to={LOGIN_FULL_PATH}>
                  <small className="font-weight-bold">Back to Login</small>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="text-brand text-underline" to={REGISTER_FULL_PATH}>
                  <small className="font-weight-bold">Create new account</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Forgot;
