import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RowItem from 'components/SortableRow/RowItem';
import { v4 as uuidv4 } from 'uuid';
import useConfirmation from 'hooks/useConfirmation';
import { reSortingData } from 'helpers/global';
import ItemList from './items/List';
import ItemCard from './items/Card';

const FieldMultiSelect = ({ onChange, value, error, disabled }) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setAnswers(value || []);
  }, [value]);

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const insertAnswer = () => {
    const newAnswers = [...answers, { id: uuidv4(), answer: '', email: '' }];
    setAnswers(newAnswers);
    onChange(newAnswers);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setAnswers((localAnswers) => reSortingData(localAnswers, dragIndex, hoverIndex));
  }, []);

  const onDrop = useCallback(() => {
    onChange(answers);
  }, [answers, onChange]);

  const onChangeAnswer = useCallback(
    (index, field, value) => {
      const newAnswers = [...answers];
      newAnswers[index][field] = value;

      setAnswers(newAnswers);
      onChange(newAnswers);
    },
    [answers, setAnswers, onChange]
  );

  const renderItem = useCallback(
    (answer, index) => {
      const removeItem = (index) => {
        const newAnswers = [...answers];
        newAnswers.splice(index, 1);

        setAnswers(newAnswers);
        onChange(newAnswers);

        hideConfirmation();
      };

      const clickSort = (currentIndex, nextIndex) => {
        const newAnswers = reSortingData(answers, currentIndex, nextIndex);
        setAnswers(newAnswers);
        onChange(newAnswers);
      };

      return (
        <RowItem id={answer.id} key={answer.id} index={index} moveCard={moveCard} onDrop={onDrop}>
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <>
              <ItemCard
                index={index}
                isFirst={index <= 0}
                isLast={index >= answers.length - 1}
                clickSort={clickSort}
                error={error}
                answer={answer}
                onChangeAnswer={onChangeAnswer}
                removeItem={removeItem}
                showConfirmation={showConfirmation}
                className="d-md-none"
                disabled={disabled}
              />

              <ItemList
                dragRef={dragRef}
                previewRef={previewRef}
                handlerId={handlerId}
                isDragging={isDragging}
                answer={answer}
                error={error}
                onChangeAnswer={onChangeAnswer}
                index={index}
                showConfirmation={showConfirmation}
                removeItem={removeItem}
                className="d-none d-md-flex"
                disabled={disabled}
              />
            </>
          )}
        </RowItem>
      );
    },
    [
      moveCard,
      onChangeAnswer,
      answers,
      hideConfirmation,
      onChange,
      showConfirmation,
      onDrop,
      error,
      disabled
    ]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">
                    Answers
                    {error ? (
                      <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                        - {error}
                      </span>
                    ) : (
                      ''
                    )}
                  </h3>
                </Col>
                {!disabled && (
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-neutral"
                      size="sm"
                      color="default"
                      type="button"
                      onClick={() => insertAnswer()}>
                      Add Answer
                    </Button>
                  </Col>
                )}
              </Row>
            </CardHeader>
            <CardBody>
              {answers.length <= 0 && <p className="text-center">There is no data!</p>}
              {answers.length >= 1 && (
                <DndProvider backend={HTML5Backend}>
                  {answers.map((answer, index) => renderItem(answer, index))}
                </DndProvider>
              )}
            </CardBody>
            {answers.length >= 6 && (
              <CardFooter>
                <Button
                  className="btn-neutral ml-auto d-block"
                  size="sm"
                  color="default"
                  type="button"
                  onClick={() => insertAnswer()}>
                  Add Answer
                </Button>
              </CardFooter>
            )}
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default FieldMultiSelect;
