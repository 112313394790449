import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      firstName
      lastName
      level
      email
      emailVerifiedAt
      lastLoginAt
      createdAt
      updatedAt
      team {
        id
        name
        logo
        onTrial
        trialEndsAt
        currentPlan
        subscription {
          id
          name
          stripeId
          stripeStatus
          stripePrice
          quantity
          onTrial
          trialEndsAt
          endsAt
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    logout {
      firstName
    }
  }
`;

export const PRESET_DATA_SET_CREATE = gql`
  mutation PresetDataSetCreate($input: presetDataSetCreateInput!) {
    presetDataSetCreate(input: $input) {
      id
      name
    }
  }
`;

export const PRESET_DATA_SET_UPDATE = gql`
  mutation PresetDataSetUpdate($input: presetDataSetUpdateInput!) {
    presetDataSetUpdate(input: $input) {
      id
      name
    }
  }
`;

export const PRESET_DATA_SET_DELETE = gql`
  mutation PresetDataSetDelete($id: String!) {
    presetDataSetDelete(id: $id) {
      success
      message
    }
  }
`;

export const TEAM_SEND_INVITATION = gql`
  mutation TeamSendInvitation($input: SendInvitationInput!) {
    teamSendInvitation(input: $input) {
      success
      message
    }
  }
`;

export const TEAM_INVITATION_DELETE = gql`
  mutation TeamInvitationDelete($invitationId: String!) {
    teamInvitationDelete(invitationId: $invitationId) {
      success
      message
    }
  }
`;

export const TEAM_MEMBER_UPDATE = gql`
  mutation TeamMemberUpdate($input: TeamMemberUpdateInput!) {
    teamMemberUpdate(input: $input) {
      firstName
      lastName
    }
  }
`;

export const TEAM_MEMBER_DELETE = gql`
  mutation TeamMemberDelete($memberId: String!) {
    teamMemberDelete(memberId: $memberId) {
      success
      message
    }
  }
`;

export const PRESET_DATA_SET_DATA_CRUD = gql`
  mutation PresetDataSetDataCrud($setId: String!, $setDatas: [presetDataSetDataCrudInput!]) {
    presetDataSetDataCrud(setId: $setId, setDatas: $setDatas) {
      id
      presetDataSetId
      name
      internalName
      sortOrder
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const SURVEY_CREATE = gql`
  mutation SurveyCreate($input: surveyCreateInput!) {
    surveyCreate(input: $input) {
      id
    }
  }
`;

export const SURVEY_UPDATE = gql`
  mutation SurveyUpdate($input: surveyUpdateInput!) {
    surveyUpdate(input: $input) {
      id
    }
  }
`;

export const SURVEY_DELETE = gql`
  mutation SurveyDelete($id: String!) {
    surveyDelete(id: $id) {
      success
      message
    }
  }
`;

export const SURVEY_QUESTION_CREATE = gql`
  mutation SurveyQuestionCreate($input: surveyQuestionCreateInput!) {
    surveyQuestionCreate(input: $input) {
      id
    }
  }
`;

export const SURVEY_QUESTION_UPDATE = gql`
  mutation SurveyQuestionUpdate($input: surveyQuestionUpdateInput!) {
    surveyQuestionUpdate(input: $input) {
      id
    }
  }
`;

export const SURVEY_QUESTION_DUPLICATE = gql`
  mutation SurveyQuestionDuplicate($questionId: String!) {
    surveyQuestionDuplicate(questionId: $questionId) {
      id
    }
  }
`;

export const SURVEY_QUESTION_DELETE = gql`
  mutation SurveyQuestionDelete($id: String!) {
    surveyQuestionDelete(id: $id) {
      success
      message
    }
  }
`;

export const SURVEY_QUESTION_ANSWER_CRUD = gql`
  mutation SurveyQuestionAnswerCrud(
    $questionId: String!
    $questionAnswers: [surveyQuestionAnswerCrudInput!]
  ) {
    surveyQuestionAnswerCrud(questionId: $questionId, questionAnswers: $questionAnswers) {
      id
      surveyQuestionId
      sortOrder
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const GENERATE_IMAGE_UPLOAD_TOKEN = gql`
  mutation GenerateImageUploadToken($filename: String, $contentType: String) {
    generateImageUploadToken(filename: $filename, contentType: $contentType) {
      method
      url
      path
    }
  }
`;

export const TEAM_ACCEPT_INVITATION = gql`
  mutation TeamAcceptInvitation($input: AcceptInvitationInput!) {
    teamAcceptInvitation(input: $input) {
      firstName
    }
  }
`;

export const USER_REGISTRATION = gql`
  mutation UserRegistration($input: UserRegistrationInput!) {
    userRegistration(input: $input) {
      success
      message
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendVerificationEmail(email: $email) {
      success
      message
    }
  }
`;

export const USER_REGISTRATION_VERIFY = gql`
  mutation UserRegistrationVerify($input: UserRegistrationVerifyInput!) {
    userRegistrationVerify(input: $input) {
      firstName
    }
  }
`;

export const SURVEY_QUESTION_SORT_ORDER_UPDATE = gql`
  mutation SurveyQuestionSortOrderUpdate(
    $surveyId: String!
    $sortOrders: [QuestionIdAndSortOrder]!
  ) {
    surveyQuestionSortOrderUpdate(surveyId: $surveyId, sortOrders: $sortOrders) {
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      message
    }
  }
`;

export const MY_ACCOUNT_UPDATE = gql`
  mutation MyAccountUpdate($input: MyAccountUpdateInput!) {
    myAccountUpdate(input: $input) {
      firstName
      lastName
      updatedAt
    }
  }
`;

export const SURVEY_STATUS_UPDATE = gql`
  mutation SurveyStatusUpdate($id: String!, $status: SurveyStatus!) {
    surveyStatusUpdate(id: $id, status: $status) {
      id
    }
  }
`;

export const SURVEY_DUPLICATE = gql`
  mutation SurveyDuplicate($surveyId: String!) {
    surveyDuplicate(surveyId: $surveyId) {
      id
    }
  }
`;

export const SURVEY_ACTIVE_UPDATE = gql`
  mutation SurveyActiveUpdate($id: String!, $active: Boolean!) {
    surveyActiveUpdate(id: $id, active: $active) {
      id
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($email: String!) {
    updateEmail(email: $email)
  }
`;

export const VERIFY_UPDATE_EMAIL = gql`
  mutation VerifyUpdateEmail($token: String!) {
    verifyUpdateEmail(token: $token)
  }
`;

export const GET_CHECKOUT_URL = gql`
  mutation GetCheckoutUrl($subscriptionType: SubscriptionType!) {
    getCheckoutUrl(subscriptionType: $subscriptionType)
  }
`;

export const TEAM_UPDATE = gql`
  mutation TeamUpdate($input: TeamUpdateInput!) {
    teamUpdate(input: $input) {
      id
      name
      logo
    }
  }
`;

export const DO_SUBSCRIPTION_DOWNGRADE = gql`
  mutation {
    doSubscriptionDowngrade {
      id
      teamId
      name
      stripeId
      stripeStatus
      stripePrice
      quantity
      onTrial
      trialEndsAt
      endsAt
      createdAt
      updatedAt
      paymentLogs {
        teamId
        subtotal
        tax
        total
        subscriptionId
        invoiceId
        chargeid
      }
    }
  }
`;

export const DO_SUBSCRIPTION_UPRADE = gql`
  mutation {
    doSubscriptionUpgrade {
      id
      teamId
      name
      stripeId
      stripeStatus
      stripePrice
      quantity
      onTrial
      trialEndsAt
      endsAt
      createdAt
      updatedAt
      paymentLogs {
        teamId
        subtotal
        tax
        total
        subscriptionId
        invoiceId
        chargeid
      }
    }
  }
`;

export const DO_CANCEL_DOWNGRADE = gql`
  mutation {
    doCancelDowngrade {
      success
      message
    }
  }
`;

export const TRIGGER_SURVEY_REPORT_RESPONSES_EXPORT = gql`
  mutation TriggerSurveyReportResponsesExport(
    $id: String!
    $responsesFrom: Date
    $responsesUntil: Date
    $presetDataAnswers: [String!]
    $emoticonAnswers: [String!]
  ) {
    triggerSurveyReportResponsesExport(
      id: $id
      responsesFrom: $responsesFrom
      responsesUntil: $responsesUntil
      presetDataAnswers: $presetDataAnswers
      emoticonAnswers: $emoticonAnswers
    )
  }
`;
