import React from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Button } from 'reactstrap';

function Confirmation({
  onConfirm = () => {
    console.log('Confirm!');
  },
  onCancel = () => {
    console.log('Cancel!');
  },
  title = 'Are you sure?',
  isLoadingConfirm = false,
  isShow = false
}) {
  return (
    <ReactBSAlert
      custom
      title=""
      show={isShow}
      onCancel={onCancel}
      style={{ display: 'block', marginTop: '-100px' }}
      onConfirm={onConfirm}
      customIcon={
        <div
          className="swal2-icon swal2-question swal2-animate-question-icon"
          style={{ display: 'flex' }}>
          <span className="swal2-icon-text">
            <i className="fa fa-question" />
          </span>
        </div>
      }
      customButtons={
        <>
          <Button
            type="button"
            color="link"
            className="rounded-pill"
            onClick={() => {
              if (onCancel !== undefined) {
                onCancel();
              }
            }}>
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            className="rounded-pill"
            onClick={() => {
              if (onConfirm !== undefined) {
                onConfirm();
              }
            }}
            disabled={isLoadingConfirm}>
            {isLoadingConfirm ? 'Loading...' : 'Sure!'}
          </Button>
        </>
      }>
      {title}
    </ReactBSAlert>
  );
}

export default Confirmation;
