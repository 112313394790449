import React from 'react';
import { Col, Input, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

const ItemList = ({
  index,
  dragRef,
  previewRef,
  handlerId,
  isDragging,
  data,
  onChangeData,
  removeItem,
  showConfirmation,
  className
}) => {
  return (
    <div
      className={classnames('row row--answers row--answers--with-delete sortable-row', className)}
      ref={previewRef}
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0 : 1 }}>
      <Col>
        <div
          ref={dragRef}
          className={classnames('sortable-row__pointer', {
            'sortable-row__pointer--dragging': isDragging
          })}>
          <i className="fas fa-sort"></i>
        </div>
      </Col>
      <Col>
        <Input
          value={data.public}
          onChange={(e) => {
            onChangeData(index, 'public', e.target.value);
          }}
          placeholder="Public Field"
        />
      </Col>
      <Col>
        <Input
          value={data.internal}
          onChange={(e) => {
            onChangeData(index, 'internal', e.target.value);
          }}
          placeholder="Internal Use Only"
        />
      </Col>
      <Col>
        <button
          type="button"
          className="table-action table-action-delete"
          id={`tooltip-delete-${data.id}`}
          onClick={(e) => {
            e.preventDefault();
            showConfirmation({
              title: 'Are you sure want to delete?',
              onConfirm: () => {
                removeItem(index);
              }
            });
          }}>
          <i className="fas fa-trash" />
        </button>
        <UncontrolledTooltip delay={0} target={`tooltip-delete-${data.id}`}>
          Delete Data
        </UncontrolledTooltip>
      </Col>
    </div>
  );
};

export default ItemList;
