import React from 'react';
import { Col, Input, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

const ItemList = ({
  index,
  dragRef,
  previewRef,
  handlerId,
  isDragging,
  answer,
  error,
  onChangeAnswer,
  questionRoutesLoading,
  questionRoutes,
  showConfirmation,
  removeItem,
  className,
  disabled
}) => {
  return (
    <div
      className={classnames(
        'row row--answers',
        { 'sortable-row row--answers--with-delete': !disabled },
        className
      )}
      ref={previewRef}
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0 : 1 }}>
      {!disabled && (
        <Col>
          <div
            ref={dragRef}
            className={classnames('sortable-row__pointer', {
              'sortable-row__pointer--dragging': isDragging
            })}>
            <i className="fas fa-sort"></i>
          </div>
        </Col>
      )}
      <Col>
        <Input
          value={answer.answer}
          disabled={disabled}
          invalid={error && !answer.answer}
          onChange={(e) => {
            onChangeAnswer(index, 'answer', e.target.value);
          }}
          placeholder="Answer"
        />
      </Col>
      <Col>
        <Input
          value={answer.email}
          disabled={disabled}
          onChange={(e) => {
            onChangeAnswer(index, 'email', e.target.value);
          }}
          placeholder="Email Alert"
        />
      </Col>
      <Col>
        {questionRoutesLoading && (
          <div>
            <small>Loading...</small>
          </div>
        )}
        {!questionRoutesLoading && (
          <Input
            type="select"
            value={answer.route || ''}
            disabled={disabled}
            onChange={(e) => {
              onChangeAnswer(index, 'route', e.target.value);
            }}>
            <option value="">Next</option>
            {questionRoutes.map((question) => (
              <option value={question.id} key={question.id}>
                {question.sortOrder} - {question.question}
              </option>
            ))}
          </Input>
        )}
      </Col>
      {!disabled && (
        <Col>
          <button
            type="button"
            className="table-action table-action-delete"
            id={`tooltip-delete-${answer.id}`}
            onClick={(e) => {
              e.preventDefault();
              showConfirmation({
                title: 'Are you sure want to delete?',
                onConfirm: () => {
                  removeItem(index);
                }
              });
            }}>
            <i className="fas fa-trash" />
          </button>
          <UncontrolledTooltip delay={0} target={`tooltip-delete-${answer.id}`}>
            Delete Answer
          </UncontrolledTooltip>
        </Col>
      )}
    </div>
  );
};

export default ItemList;
