import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import QuoteBox from 'components/QuoteBox';
import classNames from 'classnames';

const SubscriptionCard = ({
  title,
  price,
  priceInfo,
  features,
  isHighlighted,
  isCurrent,
  buttonTitle,
  onButtonClick,
  isButtonLoading,
  additionalComponent,
  secondAdditionalComponent,
  hideButton
}) => {
  return (
    <Card
      className={classNames(
        'card-pricing border-0 text-center mb-4 position-relative',
        isHighlighted ? 'bg-brand zoom-in shadow-lg rounded' : ''
      )}>
      {additionalComponent}
      <CardHeader className="bg-transparent">
        <QuoteBox color={isCurrent ? 'black' : isHighlighted ? 'white' : ''}>
          <h4
            className={classNames(
              'text-uppercase ls-1 py-3 mb-0',
              isCurrent ? 'text-white' : isHighlighted ? 'text-brand' : 'text-white'
            )}>
            {title}
          </h4>
        </QuoteBox>
      </CardHeader>
      <CardBody className="px-lg-5">
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center',
            isHighlighted ? 'text-white' : 'text-brand'
          )}>
          <span className="display-2">{price}</span>
          <span className="font-weight-bold ml-2">+VAT</span>
        </div>
        <span
          className={classNames('font-weight-bold', isHighlighted ? 'text-white' : 'text-brand')}>
          {priceInfo}
        </span>
        <ul className="list-unstyled my-4">
          {features.map(({ title, icon }) => (
            <li key={title}>
              <div className="d-flex">
                <div>
                  <div
                    className={classNames(
                      'icon icon-xs icon-shape shadow rounded-circle',
                      isHighlighted ? 'bg-white text-brand' : 'bg-gradient-brand text-white'
                    )}>
                    {icon}
                  </div>
                </div>
                <div className="text-left pl-2 text-plan-features">
                  <span className={classNames(isHighlighted ? 'text-white' : 'text-black')}>
                    {title}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {!hideButton && (
          <Button
            className={classNames(
              'mb-3 mx-0 rounded-pill',
              isHighlighted ? 'btn-neutral' : 'btn-primary'
            )}
            color="primary"
            type="button"
            onClick={onButtonClick}
            disabled={isButtonLoading}>
            {isButtonLoading && <span>Loading...</span>}
            {!isButtonLoading && <span>{buttonTitle}</span>}
          </Button>
        )}
      </CardBody>
      {secondAdditionalComponent}
    </Card>
  );
};

export default SubscriptionCard;
