import { gql } from '@apollo/client';

export const ME = gql`
  query {
    me {
      id
      firstName
      lastName
      level
      email
      emailVerifiedAt
      lastLoginAt
      createdAt
      updatedAt
      team {
        id
        name
        logo
        onTrial
        trialEndsAt
        currentSubscriptionInfo {
          currentPeriodStart
          currentPeriodEnd
        }
        currentPlan
        subscription {
          id
          name
          stripeId
          stripeStatus
          stripePrice
          quantity
          onTrial
          trialEndsAt
          endsAt
        }
        enterprise
        subscriptionManagedOffline
      }
    }
  }
`;

export const GET_PRESET_DATA_SET = gql`
  query GetPresetDataSet($id: String!) {
    getPresetDataSet(id: $id) {
      id
      name
    }
  }
`;

export const GET_PRESET_DATA_SETS = gql`
  query GetPresetDataSets($first: Int!, $page: Int) {
    getPresetDataSets(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }

      data {
        id
        name
        presetDataSetDatas {
          id
          name
        }
      }
    }
  }
`;

export const GET_PRESET_DATA_SET_DATAS = gql`
  query GetPresetDataSetDatas($presetDataSetId: String!, $first: Int!, $page: Int) {
    getPresetDataSetDatas(presetDataSetId: $presetDataSetId, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        internalName
        sortOrder
      }
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers($first: Int!, $page: Int) {
    getTeamMembers(first: $first, page: $page) {
      data {
        id
        firstName
        lastName
        level
        email
        emailVerifiedAt
        lastLoginAt
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const GET_TEAM_INVITES = gql`
  query GetTeamInvites($team_id: String!, $first: Int!, $page: Int) {
    getTeamInvites(team_id: $team_id, first: $first, page: $page) {
      data {
        id
        teamId
        name
        email
        level
        createdAt
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const GET_TEAM_MEMBER = gql`
  query GetTeamMember($id: String!) {
    getTeamMember(id: $id) {
      id
      firstName
      lastName
      level
      email
      emailVerifiedAt
      lastLoginAt
      createdAt
      updatedAt
      team {
        id
        name
      }
      presetDataSetDatas {
        id
        presetDataSetId
      }
    }
  }
`;

export const GET_SURVEYS = gql`
  query GetSurveys($statuses: [SurveyStatus], $first: Int!, $page: Int) {
    getSurveys(statuses: $statuses, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
      data {
        id
        name
        updatedAt
        createdAt
        lastResponse
        totalResponses
        visibility
        status
        active 
        totalQuestions
      }
    }
  }
`;

export const GET_SURVEY = gql`
  query GetSurvey($id: String!) {
    getSurvey(id: $id) {
      id
      teamId
      name
      logo
      logoAlignment
      backgroundColor
      backgroundImage
      buttonColor
      buttonTextColor
      font
      fontColor
      restartSurvey
      visibility
      status
      lastResponse
      createdBy
      createdAt
      updatedAt
      active
      questions {
        id
      }
    }
  }
`;

export const GET_SURVEY_QUESTIONS = gql`
  query GetSurveyQuestions($survey_id: String, $first: Int!, $page: Int) {
    getSurveyQuestions(survey_id: $survey_id, first: $first, page: $page) {
      data {
        id
        question
        type
        sortOrder
        presetDataSet {
          presetDataSetDatas {
            id
            name
          }
        }
        answers {
          id
          data {
            ... on EMOTICON_FIELDS {
              icon
              answerText
              routeToQuestion
            }
            ... on MULTI_SELECT_FIELDS {
              answerText
            }
            ... on SINGLE_SELECT_FIELDS {
              answerText
              routeToQuestion
            }
          }
          sortOrder
        }
      }
    }
  }
`;

export const GET_SURVEY_QUESTION = gql`
  query GetSurveyQuestion($id: String!) {
    getSurveyQuestion(id: $id) {
      id
      surveyId
      question
      type
      mandatory
      skippable
      skippableText
      skipRoute
      icon
      buttonText
      nextOnSelect
      emoticon4Options
      minimumSelects
      presetDataSetId
      alertEmail
      fieldName
      routeToQuestion
      externalLink
      theEnd
      sortOrder
      createdBy
    }
  }
`;

export const GET_SURVEY_QUESTION_ANSWERS = gql`
  query GetSurveyQuestionAnswers($survey_question_id: String, $first: Int!, $page: Int) {
    getSurveyQuestionAnswers(survey_question_id: $survey_question_id, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        surveyQuestionId
        data {
          ... on EMOTICON_FIELDS {
            icon
            answerText
            alertEmail
            routeToQuestion
          }
          ... on MULTI_SELECT_FIELDS {
            answerText
            alertEmail
          }
          ... on SINGLE_SELECT_FIELDS {
            answerText
            alertEmail
            routeToQuestion
          }
        }
        sortOrder
        createdBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ACCEPT_INVITATION_DATA = gql`
  query GetAcceptInvitationData($email: String!, $token: String!) {
    getAcceptInvitationData(email: $email, token: $token) {
      senderEmail
      teamName
      memberName
      lastName
    }
  }
`;

export const GET_UNVERIFIED_USER_DATA = gql`
  query GetUnverifiedUserData($email: String!, $token: String!) {
    getUnverifiedUserData(email: $email, token: $token) {
      firstName
      lastName
      teamName
    }
  }
`;

export const GET_SURVEY_REPORT_RESPONSES = gql`
  query GetSurveyReportResponses(
    $first: Int!
    $page: Int
    $id: String!
    $responsesFrom: Date
    $responsesUntil: Date
    $answers: [String!]
    $presetDataAnswers: [String!]
    $emoticonAnswers: [String!]
  ) {
    getSurveyReportResponses(
      first: $first
      page: $page
      id: $id
      responsesFrom: $responsesFrom
      responsesUntil: $responsesUntil
      answers: $answers
      presetDataAnswers: $presetDataAnswers
      emoticonAnswers: $emoticonAnswers
    ) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
      data {
        id
        surveyId
        status
        shownAnswers {
          surveyQuestionId
          answer
          answerText
        }
        startedAt
        completedAt
      }
    }
  }
`;

export const GET_SURVEY_RESPONSE = gql`
  query GetSurveyResponse($id: String!) {
    getSurveyResponse(id: $id) {
      id
      surveyId
      status
      shownAnswers {
        surveyQuestionId
        questionText
        answer
        answerText
      }
      startedAt
      completedAt
    }
  }
`;

export const GET_SURVEY_REPORT_CHARTS = gql`
  query GetSurveyReportCharts(
    $id: String!
    $responsesFrom: Date
    $responsesUntil: Date
    $answers: [String!]
    $presetDataAnswers: [String!]
    $emoticonAnswers: [String!]
  ) {
    getSurveyReportCharts(
      id: $id
      responsesFrom: $responsesFrom
      responsesUntil: $responsesUntil
      answers: $answers
      presetDataAnswers: $presetDataAnswers
      emoticonAnswers: $emoticonAnswers
    ) {
      name
      questionType
      type
      data {
        label
        count
      }
      avg
    }
  }
`;

export const GET_LIST_FONTS = gql`
  {
    getListFonts {
      family
      variants
      subsets
      version
      lastModified
      files
      category
      kind
    }
  }
`;

export const GET_TEAM = gql`
  {
    getTeam {
      id
      name
      logo
    }
  }
`;

export const GET_STRIPE_PORTAL_URL = gql`
  {
    getStripePortalUrl
  }
`;

export const GET_SURVEYS_COMPLETED_COUNT = gql`
  query getSurveysCompletedCount($days: Int!) {
    getSurveysCompletedCount(days: $days)
  }
`;

export const GET_SURVEYS_COMPLETED_TOTAL = gql`
  {
    getSurveysCompletedTotal
  }
`;

export const GET_MOST_POPULAR_SURVEY_NAME = gql`
  query getMostPopularSurveyName($days: Int!) {
    getMostPopularSurveyName(days: $days)
  }
`;

export const GET_RESPONSES_COUNT_PER_SURVEY = gql`
  query GetResponsesCountPerSurvey($days: Int!) {
    getResponsesCountPerSurvey(days: $days) {
      name
      count
    }
  }
`;

export const GET_RESPONSES_COUNT_MONTH = gql`
  {
    getResponsesCountPerMonth {
      month
      year
      total
    }
  }
`;

export const GLOBAL_SEARCH = gql`
  query globalSearch($keyword: String!) {
    globalSearch(keyword: $keyword) {
      type
      id
      title
    }
  }
`;
