import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import routes from 'constants/routes';
import { useSelector } from 'react-redux';
import SubscriptionFooter from 'components/Footers/SubscriptionFooter';
import SubscriptionNavbar from 'components/Navbars/SubscriptionNavbar';
import {
  ACCOUNT_FULL_PATH,
  DASHBOARD_FULL_PATH,
  SUBSCRIPTION_FULL_PATH,
  LOGIN_FULL_PATH
} from 'constants/paths';

function Admin() {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);

  const { me, currentPlan, isNoActiveSubscription } = useSelector((state) => state.auth);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route exact={true} path={prop.layout + prop.path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  const isSubscriptionRouteDisabled = me?.team.enterprise && me?.team.subscriptionManagedOffline;

  if (
    currentPlan &&
    me?.level !== 'ADMIN' &&
    location.pathname === SUBSCRIPTION_FULL_PATH &&
    !isSubscriptionRouteDisabled
  ) {
    return <Redirect to="/admin" />;
  }

  if (location.pathname === SUBSCRIPTION_FULL_PATH) {
    return (
      <>
        <div className="main-content" ref={mainContentRef}>
          <SubscriptionNavbar />
          <Switch>
            {getRoutes(
              routes.filter((elm) => {
                if (isSubscriptionRouteDisabled && elm.hideOnSubscriptionManagedOffline) {
                  return false;
                }

                return true;
              })
            )}
            <Redirect from="*" to={DASHBOARD_FULL_PATH} />
          </Switch>
        </div>
        <SubscriptionFooter />
      </>
    );
  }

  if (
    isNoActiveSubscription &&
    !isSubscriptionRouteDisabled &&
    location.pathname !== ACCOUNT_FULL_PATH
  ) {
    return <Redirect to={SUBSCRIPTION_FULL_PATH} />;
  }

  return (
    <>
      <Sidebar
        routes={routes
          .filter((elm) => elm.accessLevels.includes(me?.level))
          .filter((elm) => {
            return (
              !elm.hideOnSubscriptionManagedOffline ||
              !me?.team.enterprise ||
              !me?.team.subscriptionManagedOffline
            );
          })}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: DASHBOARD_FULL_PATH,
          imgSrc: require('assets/img/brand/GatherLogo.png').default,
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes.filter((elm) => elm.accessLevels.includes(me?.level)))}
          <Redirect from="*" to={DASHBOARD_FULL_PATH} />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
    </>
  );
}

const AdminOutter = () => {
  const { isLogin } = useSelector((state) => state.auth);
  const location = useLocation();

  if (!isLogin && location.pathname !== 'Privacy-Policy.pdf') {
    return (
      <Redirect
        to={`${LOGIN_FULL_PATH}${location.pathname ? '?redirect=' + location.pathname : ''}`}
      />
    );
  }

  return <Admin />;
};

export default AdminOutter;
