import React, { useState } from 'react';
import FormQuestion from './FormQuestion';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { textEllipsis } from 'helpers/global';
import { SURVEY_QUESTION_CREATE } from 'graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SURVEY } from 'graphql/queries';
import { SURVEY_QUESTION_ANSWER_CRUD } from 'graphql/mutations';
import { questionTypeToGraphqlQuestionTypeMap } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { SURVEY_FULL_PATH } from 'constants/paths';

const AddQuestion = () => {
  const { surveyId } = useParams();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const { data: dataSurvey } = useQuery(GET_SURVEY, {
    variables: { id: surveyId }
  });

  const [surveyQuestionCreate, { loading: isLoadingSave }] = useMutation(SURVEY_QUESTION_CREATE);

  const [surveyQuestionAnswerCrud, { loading: isLoadingSaveAnswers }] = useMutation(
    SURVEY_QUESTION_ANSWER_CRUD
  );

  const [isRedirect, setIsRedirect] = useState(false);

  const onSubmit = async (data) => {
    try {
      const input = {
        surveyId,
        type: questionTypeToGraphqlQuestionTypeMap[data.questionType],
        question: data.question,
        mandatory: data.mandatory,
        skippable: false
      };

      if (data.questionIcon !== undefined) {
        input.icon = data.questionIcon;
      }

      if (data.buttonText !== undefined) {
        input.buttonText = data.buttonText;
      }

      if (data.nextOnSelect !== undefined) {
        input.nextOnSelect = data.nextOnSelect;
      }

      if (data.emoticon4Options !== undefined) {
        input.emoticon4Options = data.emoticon4Options;
      }

      if (data.skipOption !== undefined) {
        input.skippable = data.skipOption;
      }

      if (data.skipText !== undefined) {
        input.skippableText = data.skipText;
      }

      if (data.skipRoute !== undefined) {
        input.skipRoute = data.skipRoute;
      }

      if (data.routeToQuestion !== undefined) {
        input.routeToQuestion = data.routeToQuestion;
      }

      if (data.alertEmail !== undefined) {
        input.alertEmail = data.alertEmail;
      }

      if (data.fieldName !== undefined) {
        input.fieldName = data.fieldName;
      }

      if (data.presetDataSetId !== undefined) {
        input.presetDataSetId = data.presetDataSetId;
      }

      if (data.externalLink !== undefined) {
        input.externalLink = data.externalLink;
      }

      if (data.theEnd !== undefined) {
        input.theEnd = data.theEnd;
      }

      if (data.minimumSelect) {
        input.minimumSelects = parseInt(data.minimumSelect);
      }

      const resSurveyQuestionCreate = await surveyQuestionCreate({
        variables: { input }
      });

      if (data.answers !== undefined) {
        const questionAnswers = [];
        for (let i = 0; i < (input.emoticon4Options ? 4 : data.answers.length); i++) {
          const answer = data.answers[i];

          const questionAnswer = { sortOrder: i + 1 };

          if (answer.answer !== undefined) {
            questionAnswer.answerText = answer.answer;
          }

          if (answer.email !== undefined) {
            questionAnswer.alertEmail = answer.email;
          }

          if (answer.icon !== undefined) {
            questionAnswer.icon = answer.icon;
          }

          if (answer.route !== undefined) {
            questionAnswer.routeToQuestion = answer.route;
          }

          if (answer.externalLink !== undefined) {
            questionAnswer.externalLink = answer.externalLink;
          }

          if (answer.presetDataSet !== undefined) {
            questionAnswer.presetDataSetId = answer.presetDataSet;
          }

          questionAnswers.push(questionAnswer);
        }

        await surveyQuestionAnswerCrud({
          variables: {
            questionId: resSurveyQuestionCreate.data.surveyQuestionCreate.id,
            questionAnswers
          }
        });
      }

      showNotificationAlert({
        title: 'Success',
        message: 'You have added a question successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (isRedirect) {
    return <Redirect to={`${SURVEY_FULL_PATH}/${surveyId}/edit`} />;
  }

  return (
    <>
      <SimpleHeader
        name="Add Question & Answers"
        firstColProps={{ xs: 8, sm: 11, lg: 11 }}
        secondColProps={{ xs: 4, sm: 1, lg: 1 }}
        parents={[
          { to: SURVEY_FULL_PATH, label: 'My Surveys' },
          {
            to: `${SURVEY_FULL_PATH}/${surveyId}/edit`,
            label: dataSurvey ? textEllipsis(dataSurvey.getSurvey.name) : '',
            title: dataSurvey?.getSurvey.name
          }
        ]}
        rightElements={
          <Link
            to={`${SURVEY_FULL_PATH}/${surveyId}/edit`}
            className="btn btn-neutral btn-sm rounded-pill"
            color="default"
            size="sm">
            Back
          </Link>
        }
      />

      <FormQuestion onSubmit={onSubmit} isLoadingSave={isLoadingSave || isLoadingSaveAnswers} />
    </>
  );
};

export default AddQuestion;
