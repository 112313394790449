import { Card, CardHeader, CardBody, Container, Row, Col, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Content from './Content';
import { GET_SURVEY } from 'graphql/queries';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { ResultContextProvider } from './ResultContext';
import { useState } from 'react';
import { endDate, startDate } from './constant';
import { TRIGGER_SURVEY_REPORT_RESPONSES_EXPORT } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';

const ResultSurvey = () => {
  const { id } = useParams();

  const [filterDateFrom, setFilterDateFrom] = useState(startDate);

  const [filterDateUntil, setFilterDateUntil] = useState(endDate);

  /**
   * TODO: move all filter to filterAnswers only. Don't need split to emoticonAnswers or presetDataAnswers
   */
  const [filterAnswers, setFilterAnswers] = useState();

  const [filterEmoticonAnswers, setFilterEmoticonAnswers] = useState();

  const [filterPresetDataAnswers, setFilterPresetDataAnswers] = useState();

  const { loading, data } = useQuery(GET_SURVEY, {
    variables: { id }
  });

  const { show: showTriggerSurveyAlert } = useNotificationAlert({
    title: 'Success',
    message:
      'Your export is being generated. You will receive an email once it has been completed.',
    place: 'br'
  });

  const [triggerSurveyReportResponsesExport, { loading: isLoadingTriggerReport }] = useMutation(
    TRIGGER_SURVEY_REPORT_RESPONSES_EXPORT
  );

  const isEmpty = !data?.getSurvey.lastResponse;

  return (
    <ResultContextProvider
      value={{
        filterDateFrom,
        setFilterDateFrom,
        filterDateUntil,
        setFilterDateUntil,
        filterAnswers,
        setFilterAnswers,
        filterEmoticonAnswers,
        setFilterEmoticonAnswers,
        filterPresetDataAnswers,
        setFilterPresetDataAnswers
      }}>
      <div
        className="header pb-6 d-flex align-items-center d-print-none"
        style={{
          minHeight: '300px'
        }}>
        <span className="mask bg-brand opacity-8" />
        <Container fluid>
          <Row className="align-items-end">
            <Col xs="12" md="8">
              <h2 className="display-3 text-white">Survey Results</h2>
              <h3 className="text-white">{data?.getSurvey.name}</h3>
              <p className="text-white mt-0 mb-0">
                Your survey results are shown in the dashboard below - you can filter the data
                further and commit your changes by clicking the 'filter' button
              </p>
            </Col>
            <Col xs="12" md="4" className="mt-3 mt-md-0 text-md-right">
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  color="black"
                  size="sm"
                  className="rounded-pill"
                  disabled={isLoadingTriggerReport}
                  onClick={async () => {
                    window.print();
                  }}>
                  Print Graphs
                </Button>
                {/* // download report directly (open new tab) {<Button
                  color="black"
                  size="sm"
                  className="rounded-pill flex-grow-1 flex-sm-grow-0"
                  onClick={() => {
                    const url = `${
                      process.env.REACT_APP_PUBLIC_DOMAIN
                    }/survey-report-responses-export?survey_id=${id}${
                      filterDateFrom ? `&responsesFrom=${filterDateFrom}` : ''
                    }${filterDateUntil ? `&responsesUntil=${filterDateUntil}` : ''}${
                      filterPresetDataAnswers
                        ? `&presetDataAnswers=${JSON.stringify(filterPresetDataAnswers)}`
                        : ''
                    }${
                      filterEmoticonAnswers
                        ? `&emoticonAnswers=${JSON.stringify(filterEmoticonAnswers)}`
                        : ''
                    }`;
                    window.open(url, '_blank');
                  }}>
                  <span>Export Results</span>
                </Button>} */}
                <Button
                  color="black"
                  size="sm"
                  className="rounded-pill"
                  disabled={isLoadingTriggerReport}
                  onClick={async () => {
                    await triggerSurveyReportResponsesExport({
                      variables: {
                        id,
                        responsesFrom: null,
                        responsesUntil: null,
                        emoticonAnswers: null,
                        presetDataAnswers: null
                      }
                    });

                    showTriggerSurveyAlert();
                  }}>
                  {isLoadingTriggerReport ? (
                    <span>Loading...</span>
                  ) : (
                    <span>Export All Results</span>
                  )}
                </Button>
                <Button
                  color="black"
                  size="sm"
                  className="rounded-pill"
                  disabled={isLoadingTriggerReport}
                  onClick={async () => {
                    await triggerSurveyReportResponsesExport({
                      variables: {
                        id,
                        responsesFrom: filterDateFrom,
                        responsesUntil: filterDateUntil,
                        emoticonAnswers: filterAnswers,
                        presetDataAnswers: []
                      }
                    });

                    showTriggerSurveyAlert();
                  }}>
                  {isLoadingTriggerReport ? (
                    <span>Loading...</span>
                  ) : (
                    <span>Export Filtered Results</span>
                  )}
                </Button>
                <Link
                  to={SURVEY_FULL_PATH}
                  className="btn btn-neutral btn-sm rounded-pill flex-grow-1 flex-sm-grow-0"
                  color="default"
                  size="sm">
                  Back
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        {(loading || isEmpty) && (
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Survey Results</h3>
            </CardHeader>
            <CardBody>
              <div className="text-center">
                {loading && <span>Loading...</span>}
                {!loading && isEmpty && <span>There is no any responses!</span>}
              </div>
            </CardBody>
          </Card>
        )}

        {!loading && !isEmpty && <Content />}
      </Container>
    </ResultContextProvider>
  );
};

export default ResultSurvey;
