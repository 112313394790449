import useShowTooltip from 'hooks/useShowTooltip';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Pie } from 'react-chartjs-2';

var colors = {
  veryHappy: '#39b050',
  happy: '#92d050',
  ok: '#f9c000',
  unHappy: '#ed7d31',
  veryUnHappy: '#f30104'
};

const PieChartItem = ({ index, data, avg }) => {
  useShowTooltip();

  const legendId = `legend-${index}`;

  const chart = {
    data: {
      labels: data.map((elm) => elm.label),
      datasets: [
        {
          data: data.map((elm) => elm.count),
          backgroundColor: [
            colors.veryHappy,
            colors.happy,
            colors.ok,
            colors.unHappy,
            colors.veryUnHappy
          ],
          label: 'Dataset'
        }
      ]
    },
    options: {
      responsive: true,
      legend: {
        display: false
      },
      legendCallback: function (chart) {
        var text = [];
        text.push('<ul class="pie-chart-custom-legend">');
        var ds = chart.data.datasets[0];
        var sum = ds.data.reduce(function add(a, b) {
          return a + b;
        }, 0);
        for (var i = 0; i < ds.data.length; i++) {
          text.push('<li>');
          var perc = Math.round((100 * ds.data[i]) / sum, 0);
          text.push(
            `<span class="pie-chart-custom-legend__color" style="background-color: ${ds.backgroundColor[i]}"></span>
             <span class="pie-chart-custom-legend__title">
               <span>${chart.data.labels[i]} (${ds.data[i]} - ${perc}%)</span>
             </span>`
          );
          text.push('</li>');
        }
        text.push('</ul>');
        return text.join('');
      },
      animation: {
        animateScale: true,
        animateRotate: true
      }
    }
  };

  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {
        document.getElementById(legendId).innerHTML =
          chartRef.current?.chartInstance.generateLegend();
      }
    }, 500);
  }, [chartRef, legendId]);

  if (chart.data.datasets[0].data.reduce((total, count) => total + count, 0) <= 0) {
    return <p className="mb-0 text-center">There is no data!</p>;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div id={legendId}></div>
        {avg && <div className="text-sm font-weight-bold">Average Value: {avg}</div>}
      </div>
      <Pie ref={chartRef} data={chart.data} options={chart.options} className="chart-canvas" />
    </>
  );
};

export default PieChartItem;
