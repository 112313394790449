import { Collapse, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import FormCheckboxes from 'components/forms/FormCheckboxes/FormCheckboxes';
import { useState } from 'react';
import { useEffect } from 'react';

const Permission = ({ id, title, datas, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);

  const [localValue, setLocalValue] = useState(value);

  const key = `permission-${id}`;

  useEffect(() => {
    setIsSelectAll(datas.length === localValue?.length || 0);
  }, [datas, localValue]);

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom-0 card-header--checkboxes"
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <CardTitle className="mb-0 font-weight-bold">{title}</CardTitle>
              {!isOpen && <i className="ni ni-bold-right ml-2" />}
              {isOpen && <i className="ni ni-bold-down ml-2" />}
            </div>
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                id={key}
                type="checkbox"
                checked={isSelectAll}
                onChange={(e) => {
                  const newIsSelectAll = e.target.checked;
                  setIsSelectAll(newIsSelectAll);

                  if (newIsSelectAll) {
                    const newValue = datas.map((elm) => elm.id);
                    setLocalValue(newValue);
                    onChange(newValue);
                  } else {
                    setLocalValue([]);
                    onChange([]);
                  }
                }}
              />
              <label className="custom-control-label" htmlFor={key}>
                Select All
              </label>
            </div>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            {datas.length >= 1 && (
              <FormCheckboxes
                items={datas.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
                value={localValue}
                onChange={(value) => {
                  setLocalValue(value);
                  onChange(value);
                }}
              />
            )}
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
};

export default Permission;
