import { Card, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import RowItem from 'components/SortableRow/RowItem';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GET_SURVEY_QUESTIONS } from 'graphql/queries';
import { SURVEY_QUESTION_DUPLICATE, SURVEY_QUESTION_DELETE } from 'graphql/mutations';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import useConfirmation from 'hooks/useConfirmation';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { SURVEY_QUESTION_SORT_ORDER_UPDATE } from 'graphql/mutations';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { reSortingData } from 'helpers/global';
import ItemList from './items/List';
import ItemCard from './items/Card';

const perPage = 100;
const ListQuestions = ({ surveyId, type, status, onCalculateLength, disabled }) => {
  const {
    loading: isLoadingDatas,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_SURVEY_QUESTIONS, {
    variables: {
      survey_id: surveyId,
      first: perPage
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      onCalculateLength(data.getSurveyQuestions.data.length);
    }
  });

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [surveyQuestionDuplicate, { loading: isLoadingDuplicate }] =
    useMutation(SURVEY_QUESTION_DUPLICATE);

  const [surveyQuestionDelete, { loading: isLoadingDestroy }] = useMutation(SURVEY_QUESTION_DELETE);

  const { showConfirmation, hideConfirmation } = useConfirmation(
    isLoadingDuplicate || isLoadingDestroy
  );

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (data) {
      setQuestions(data.getSurveyQuestions?.data || []);
    }
  }, [data]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setQuestions((localQuestions) => reSortingData(localQuestions, dragIndex, hoverIndex));
  }, []);

  const [surveyQuestionSortOrderUpdate, { loading: sortOrderUpdateLoading }] = useMutation(
    SURVEY_QUESTION_SORT_ORDER_UPDATE
  );

  const sortingQuestions = useCallback(
    async (dataQuestions) => {
      const sortOrders = [];

      for (let i = 0; i < dataQuestions.length; i++) {
        const question = dataQuestions[i];

        sortOrders.push({
          questionId: question.id,
          sortOrder: i + 1
        });
      }
      try {
        await surveyQuestionSortOrderUpdate({
          variables: {
            surveyId,
            sortOrders
          }
        });

        showNotificationAlert({
          title: 'Success',
          message: 'You have updated the questions sort order!',
          place: 'br'
        });
      } catch (err) {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: err.message
        });
      }
    },
    [showNotificationAlert, surveyId, surveyQuestionSortOrderUpdate]
  );

  const onDrop = useCallback(async () => {
    sortingQuestions(questions);
  }, [questions, sortingQuestions]);

  const clickSort = (currentIndex, nextIndex) => {
    const newQuestions = reSortingData(questions, currentIndex, nextIndex);
    setQuestions(newQuestions);
    sortingQuestions(newQuestions);
  };

  const onDuplicate = useCallback(
    async (questionId) => {
      try {
        await surveyQuestionDuplicate({
          variables: { questionId }
        });
        showNotificationAlert({
          title: 'Success',
          message: 'You have duplicated the question successfully'
        });
        refetch();
      } catch (err) {
        switch (err.message) {
          default:
            showNotificationAlert({
              type: 'danger',
              title: 'Failed',
              message: err.message
            });
            break;
        }
      } finally {
        hideConfirmation();
      }
    },
    [hideConfirmation, refetch, showNotificationAlert, surveyQuestionDuplicate]
  );

  const onDelete = useCallback(
    async (id) => {
      try {
        await surveyQuestionDelete({
          variables: { id }
        });
        showNotificationAlert({
          title: 'Success',
          message: 'You have deleted the question successfully'
        });
        refetch();
      } catch (err) {
        switch (err.message) {
          default:
            showNotificationAlert({
              type: 'danger',
              title: 'Failed',
              message: err.message
            });
            break;
        }
      } finally {
        hideConfirmation();
      }
    },
    [hideConfirmation, refetch, showNotificationAlert, surveyQuestionDelete]
  );

  const renderItem = useCallback(
    (question, index) => {
      return (
        <RowItem
          id={question.id}
          key={question.id}
          index={index}
          moveCard={moveCard}
          onDrop={onDrop}>
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <ItemList
              dragRef={dragRef}
              previewRef={previewRef}
              handlerId={handlerId}
              isDragging={isDragging}
              question={question}
              showConfirmation={showConfirmation}
              showNotificationAlert={showNotificationAlert}
              onDuplicate={onDuplicate}
              onDelete={onDelete}
              surveyId={surveyId}
              status={status}
              disabled={disabled}
            />
          )}
        </RowItem>
      );
    },
    [
      moveCard,
      surveyId,
      showConfirmation,
      onDrop,
      showNotificationAlert,
      status,
      onDuplicate,
      onDelete,
      disabled
    ]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">Questions</h3>
                </Col>
                {type === 'edit' && !disabled && (
                  <Col className="text-right" xs="6">
                    <Link
                      onClick={(e) => {
                        if (status === 'PUBLISHED') {
                          e.preventDefault();

                          showNotificationAlert({
                            type: 'danger',
                            title: 'Not Permitted',
                            message: 'You must revert the survey to be a draft to add a question.'
                          });
                        }
                      }}
                      className="btn btn-sm btn-primary rounded-pill"
                      color="default"
                      to={`${SURVEY_FULL_PATH}/${surveyId}/question/add`}>
                      Add a Question
                    </Link>
                  </Col>
                )}
              </Row>
            </CardHeader>
            <CardBody className="card-body--list-questions">
              {sortOrderUpdateLoading && (
                <div className="card-body--list-questions__loader">Sorting...</div>
              )}
              {type === 'add' && (
                <p className="text-center">You must save the survey before adding questions!</p>
              )}
              {type === 'edit' && (
                <>
                  {(isLoadingDatas || networkStatus === NetworkStatus.refetch) && (
                    <p className="text-center">Loading...</p>
                  )}
                  {!isLoadingDatas && questions.length <= 0 && (
                    <p className="text-center">There is no data!</p>
                  )}
                  {!isLoadingDatas && questions.length >= 1 && (
                    <>
                      {questions.map((question, index) => (
                        <ItemCard
                          key={question.id}
                          index={index}
                          isFirst={index <= 0}
                          isLast={index >= questions.length - 1}
                          clickSort={clickSort}
                          question={question}
                          surveyId={surveyId}
                          status={status}
                          showNotificationAlert={showNotificationAlert}
                          showConfirmation={showConfirmation}
                          onDuplicate={onDuplicate}
                          onDelete={onDelete}
                          className="d-md-none"
                          disabled={disabled}
                        />
                      ))}
                      <div className="d-none d-md-block">
                        <Table className="align-items-center table-flush" responsive striped>
                          <thead className="thead-light">
                            <tr>
                              {!disabled && <th />}
                              <th />
                              <th>Sort Order</th>
                              <th>Question</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            <DndProvider backend={HTML5Backend}>
                              {questions.map((question, index) => renderItem(question, index))}
                            </DndProvider>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ListQuestions;
