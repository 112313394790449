import { removeHtmlTags } from 'helpers/global';
// import { textEllipsis } from 'helpers/global';
import { useCallback } from 'react';
import { useState } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Input, Button } from 'reactstrap';

const AnswersFilter = ({ datas, onChange }) => {
  const searchParams = new URLSearchParams(document.location.search)
  const answersParam = searchParams.get('answers')
  const [value, setValue] = useState(answersParam ? JSON.parse(answersParam) : {});

  const getOptions = useCallback((data) => {
    if (data.type === 'PRESET_DATA') {
      return data.presetDataSet.presetDataSetDatas.map((elm) => ({
        value: elm.id,
        label: elm.name
      }));
    } else {
      return data.answers.map((elm) => {
        return {
          value: elm.id,
          label: elm.data.answerText
        };
      });
    }
  }, []);

  const updateQueryParams = (answers) => {
    const params = new URL(document.location.href).searchParams;
    params.set("answers", answers)
    window.history.replaceState(null, null, '?' + params);
  }
  return (
    <Row>
      {datas?.map((data) => (
        <Col sm={12} md={6} xl={4} key={data.id} className="mb-card">
          <Card key={data.id} className="mb-0 h-100">
            <CardHeader>
              <h3 className="mb-0 text-brand" dangerouslySetInnerHTML={{__html: removeHtmlTags(data.question)}}></h3>
            </CardHeader>
            <CardBody className="d-flex">
              <div className="w-100 mt-auto">
                <Form>
                  <Row>
                    <Col xs={8}>
                      <Input
                        type="select"
                        className="form-control"
                        value={value[data.type]?.[data.id]}
                        onChange={(e) => {
                          let newValue = {
                            ...value,
                            [data.type]: {
                              ...value[data.type],
                              [data.id]: e.target.value
                            }
                          }
                          setValue(newValue);
                          updateQueryParams(JSON.stringify(newValue))
                          onChange(data.type, data.id, e.target.value);
                        }}>
                        <option value="">All</option>
                        {getOptions(data).map((elm) => (
                          <option value={elm.value} key={elm.value}>
                            {elm.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="w-100 px-0 rounded-pill"
                        type="button"
                        style={{ fontSize: '0.8rem' }}
                        onClick={() => {
                          let newValue = {
                            ...value,
                            [data.type]: {
                              ...value[data.type],
                              [data.id]: ''
                            }
                          };
                          setValue(newValue);
                          updateQueryParams(JSON.stringify(newValue))
                          onChange(data.type, data.id, '');
                        }}>
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AnswersFilter;
