import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import Fields from './Fields';
import { Link } from 'react-router-dom';
import { PRESET_DATA_FULL_PATH } from 'constants/paths';

const FormPreset = ({ onSubmit, isLoadingSave, defaultValues }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0 text-brand">Preset Data Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label className="form-control-label" htmlFor="preset-name" md="3">
                      Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="preset-name"
                        type="text"
                        {...replaceRefToInneRef(register('name', { required: true }))}
                        invalid={errors.hasOwnProperty('name')}
                      />
                      {errors.hasOwnProperty('name') && (
                        <FormFeedback>Preset Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Controller
          control={control}
          name="datas"
          render={({ field: { onChange, value } }) => <Fields onChange={onChange} value={value} />}
        />

        <Card>
          <CardBody>
            <Button type="submit" color="primary" disabled={isLoadingSave} className="rounded-pill">
              {isLoadingSave && <span>Loading...</span>}
              {!isLoadingSave && <span>Save</span>}
            </Button>
            <Link className="btn btn-secondary rounded-pill" to={PRESET_DATA_FULL_PATH}>
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormPreset;
