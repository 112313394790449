const data = {
  firstName: 'Ary',
  lastName: 'Analog',
  email: 'ary@analogrepublic.com'
};

export const get = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });
};

export const save = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
