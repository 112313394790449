const datas = [
  {
    id: 1,
    name: 'Chicken shop customer survey',
    dateUpdate: '04/25/2022',
    lastResponse: '04/25/2022',
    responses: 105
  },
  {
    id: 2,
    name: 'Staff service survey  2022',
    dateUpdate: '04/25/2022',
    lastResponse: '04/25/2022',
    responses: 88
  },
  {
    id: 3,
    name: 'Favourite side dishes Survey',
    dateUpdate: '04/25/2022',
    lastResponse: '04/25/2022',
    responses: 5
  },
  {
    id: 4,
    name: 'Staff mental health survey',
    dateUpdate: '04/25/2022',
    lastResponse: '04/25/2022',
    responses: 27
  }
];

export const getAll = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas);
    }, 1000);
  });
};

export const getOne = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(datas[0]);
    }, 1000);
  });
};

export const insert = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const update = async (id, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};
