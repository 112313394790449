import { Label, FormGroup, Input, Col, Button, FormFeedback } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import Permission from './Permission';
import { GET_PRESET_DATA_SETS } from 'graphql/queries';
import { useQuery } from '@apollo/client';

const FormTeamMember = ({ onSubmit, isLoadingSave, defaultValues }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues
  });

  const { loading: isLoadingPresetDataSets, data: dataPresetDataSets } = useQuery(
    GET_PRESET_DATA_SETS,
    {
      variables: { first: 100, page: 1 }
    }
  );

  const watchAccess = watch('access');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="row">
        <Label className="form-control-label" htmlFor="first-name" md="3">
          First Name
        </Label>
        <Col md="9">
          <Input
            id="first-name"
            type="text"
            {...replaceRefToInneRef(register('firstName', { required: true }))}
            invalid={errors.hasOwnProperty('firstName')}
          />
          {errors.hasOwnProperty('firstName') && (
            <FormFeedback>First Name is required!</FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" htmlFor="last-name" md="3">
          Last Name
        </Label>
        <Col md="9">
          <Input
            id="last-name"
            type="text"
            {...replaceRefToInneRef(register('lastName', { required: true }))}
            invalid={errors.hasOwnProperty('lastName')}
          />
          {errors.hasOwnProperty('lastName') && <FormFeedback>Last Name is required!</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" htmlFor="email" md="3">
          Email
        </Label>
        <Col md="9">
          <Input
            id="email"
            type="text"
            {...replaceRefToInneRef(register('email', { required: true }))}
            invalid={errors.hasOwnProperty('email')}
            readOnly
          />
          {errors.hasOwnProperty('email') && <FormFeedback>Email is required!</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" htmlFor="access" md="3">
          Access
        </Label>
        <Col md="9">
          <Input
            id="access"
            type="select"
            {...replaceRefToInneRef(register('access', { required: true }))}
            invalid={errors.hasOwnProperty('access')}>
            <option value="">Select Access</option>
            <option value="Admin">Admin</option>
            <option value="Member">Member</option>
          </Input>
          {errors.hasOwnProperty('access') && <FormFeedback>Access is required!</FormFeedback>}
        </Col>
      </FormGroup>
      {watchAccess === 'Member' && (
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="permissions" md="3">
            Permissions
          </Label>
          <Col md="9">
            {isLoadingPresetDataSets && <p className="col-form-label">Loading...</p>}
            {!isLoadingPresetDataSets && (
              <>
                <p className="col-form-label">
                  Set the permission levels for access to reporting for Preset Data
                </p>
                {dataPresetDataSets?.getPresetDataSets.data.map((setData) => {
                  return (
                    <Controller
                      key={`permission-${setData.id}`}
                      control={control}
                      name={`permissions[${setData.id}]`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Permission
                            id={setData.id}
                            title={setData.name}
                            datas={setData.presetDataSetDatas}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                  );
                })}
              </>
            )}
          </Col>
        </FormGroup>
      )}
      <Button type="submit" color="primary" disabled={isLoadingSave} className="rounded-pill">
        {isLoadingSave && <span>Loading...</span>}
        {!isLoadingSave && <span>Update</span>}
      </Button>
    </form>
  );
};

export default FormTeamMember;
