import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { GET_TEAM_INVITES } from 'graphql/queries';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { TEAM_SEND_INVITATION, TEAM_INVITATION_DELETE } from 'graphql/mutations';
import { graphqlLevelToAccessMap } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import useConfirmation from 'hooks/useConfirmation';

const perPage = 100;

function ListInvites() {
  const { me } = useSelector((state) => state.auth);

  const {
    loading: isLoadingData,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_TEAM_INVITES, {
    variables: { team_id: me?.team?.id, first: perPage, page: 1 },
    notifyOnNetworkStatusChange: true
  });

  const [teamSendInvitation, { loading: isLoadingResend }] = useMutation(TEAM_SEND_INVITATION);

  const [teamInvitationDelete, { loading: isLoadingDestroy }] = useMutation(TEAM_INVITATION_DELETE);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const { showConfirmation, hideConfirmation } = useConfirmation(
    isLoadingResend || isLoadingDestroy
  );

  const onResend = ({ name, email, level }) => {
    showConfirmation({
      title: 'Are you sure want to resend?',
      onConfirm: async () => {
        try {
          await teamSendInvitation({
            variables: {
              input: { name, email, level }
            }
          });

          showNotificationAlert({
            title: 'Success',
            message: 'You have resent the invitation successfully'
          });
        } catch (err) {
          console.log('__error', err);
        } finally {
          hideConfirmation();
        }
      }
    });
  };

  const onDeleteTeamInvitation = async (id) => {
    showConfirmation({
      title: 'Are you sure want to delete?',
      onConfirm: async () => {
        try {
          await teamInvitationDelete({ variables: { invitationId: id } });
          hideConfirmation();
          showNotificationAlert({
            title: 'Success',
            message: 'You have deleted the team member successfully'
          });
          refetch();
        } catch (err) {
          console.log('__err', err.message);
        }
      }
    });
  };

  return (
    <Row>
      <div className="col">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col>
                <h3 className="mb-0 text-brand">List Invited Company Members</h3>
              </Col>
            </Row>
          </CardHeader>

          {(isLoadingData || networkStatus === NetworkStatus.refetch) && (
            <CardBody>
              <p className="text-center">Loading...</p>
            </CardBody>
          )}
          {!isLoadingData && data && data.getTeamInvites?.data.length <= 0 && (
            <CardBody>
              <p className="text-center">There is no data!</p>
            </CardBody>
          )}
          {!isLoadingData && data && data.getTeamInvites?.data.length >= 1 && (
            <Table className="align-items-center table-flush" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Access</th>
                  <th>Date Sent</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.getTeamInvites?.data.map((data) => (
                  <tr key={`team-invite-${data.id}`}>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{graphqlLevelToAccessMap[data.level]}</td>
                    <td>{data.createdAt}</td>
                    <td className="table-actions">
                      <div className="d-flex">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            onResend(data);
                          }}
                          className="table-action"
                          id={`tooltip-resend-${data.id}`}>
                          Resend
                        </button>
                        <UncontrolledTooltip delay={0} target={`tooltip-resend-${data.id}`}>
                          Resend Invitation
                        </UncontrolledTooltip>

                        <button
                          className="table-action table-action-delete"
                          id={`tooltip-delete-${data.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            onDeleteTeamInvitation(data.id);
                          }}>
                          <i className="fas fa-trash" />
                        </button>
                        <UncontrolledTooltip delay={0} target={`tooltip-delete-${data.id}`}>
                          Delete Team
                        </UncontrolledTooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </div>
    </Row>
  );
}

export default ListInvites;
