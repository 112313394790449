import React, { useEffect, useState } from 'react';

const FormCheckboxes = ({ items, value, onChange }) => {
  const [localValue, setLocalValue] = useState();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onItemChange = (e) => {
    const { value, checked } = e.target;

    const selectedItems = localValue ? [...localValue] : [];

    if (checked) {
      selectedItems.push(value);
    } else {
      selectedItems.splice(selectedItems.indexOf(value), 1);
    }

    onChange(selectedItems);
  };

  return (
    <>
      {items.map((item) => {
        const key = `item-${item.value}`;
        return (
          <div key={key} className="custom-control custom-checkbox mb-3">
            <input
              className="custom-control-input"
              id={key}
              type="checkbox"
              value={item.value}
              checked={value?.includes(item.value) || false}
              onChange={onItemChange}
            />
            <label className="custom-control-label" htmlFor={key}>
              {item.label}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default FormCheckboxes;
