import React, { useEffect, useState } from 'react';
import FormSurvey from './FormSurvey';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect, useParams } from 'react-router';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { SURVEY_UPDATE } from 'graphql/mutations';
import { GET_SURVEY } from 'graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { getImage } from 'helpers/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import useConfirmation from 'hooks/useConfirmation';
import { SURVEY_STATUS_UPDATE } from 'graphql/mutations';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { logoAlignmentToGraphqlLogoAlignmentMap } from 'constants/global';
import { graphqlLogoAlignmentToLogoAlignmentMap } from 'constants/global';
import ModalPublishedSurveyRestriction from 'components/modals/ModalPublishedSurveyRestriction';

const EditSurvey = () => {
  const history = useHistory ();
  const { id } = useParams();

  const [isRedirect, setIsRedirect] = useState(false);

  const [currentStatus, setCurrentStatus] = useState('DRAFT');
  const [visibilityControl, setVisibilityControl] = useState(false);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const { loading: isLoadingData, data } = useQuery(GET_SURVEY, {
    variables: { id }
  });

  const [surveyUpdate, { loading: isLoadingSave }] = useMutation(SURVEY_UPDATE);

  const [surveyStatusUpdate, { loading: isLoadingPublish }] = useMutation(SURVEY_STATUS_UPDATE);

  const { showConfirmation, hideConfirmation } = useConfirmation(isLoadingPublish);

  const onSubmit = async (data) => {
    if(!visibilityControl){
      data.visibility = null;
    }else if(visibilityControl && !data.visibility){
      data.visibility = '[]'
    }else{
      data.visibility = JSON.stringify(data.visibility)
    }

    try {
      const {
        name,
        backgroundColor,
        backgroundImage,
        buttonColor,
        buttonTextColor,
        font,
        fontColor,
        restartSurvey,
        visibility,
        logo,
        logoAlignment
      } = data;

      const submitData = {
        id,
        name,
        backgroundColor,
        backgroundImage,
        buttonColor,
        buttonTextColor,
        font,
        fontColor,
        restartSurvey,
        visibility,
        logo,
        logoAlignment: logoAlignmentToGraphqlLogoAlignmentMap[logoAlignment]
      };

      await surveyUpdate({
        variables: {
          input: submitData
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have updated the survey successfully!'
      });

      setIsRedirect(false); // set true if you want to redirect it to the survey lists page
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  const onPublish = async (to) => {
    try {
      const nextStatus = to === 'toPublish' ? 'PUBLISHED' : 'DRAFT';
      await surveyStatusUpdate({
        variables: {
          id,
          status: nextStatus
        }
      });

      setCurrentStatus(nextStatus);

      showNotificationAlert({
        title: 'Success',
        message:
          to === 'toPublish'
            ? 'You have published the survey successfully!'
            : 'Successfully reverted to draft!'
      });

      setIsRedirect(false); // set true if you want to redirect it to the survey lists page
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const [showModalBasicPlanRestriction, setShowModalBasicPlanRestriction] = useState(false);

  useEffect(() => {
    if(!isLoadingData){
      if(!data?.getSurvey){
        history.push('/admin/my-surveys')
        return
      }
      setCurrentStatus(data.getSurvey.status);
      setVisibilityControl(data.getSurvey.visibility ? true : false)
    }
  }, [isLoadingData, data]);

  useEffect(() => {
    setShowModalBasicPlanRestriction(currentStatus === 'PUBLISHED');
  }, [currentStatus]);

  if (isRedirect) {
    return <Redirect to={SURVEY_FULL_PATH} />;
  }

  return (
    <>
      <ModalPublishedSurveyRestriction
        isShow={showModalBasicPlanRestriction}
        onClose={() => setShowModalBasicPlanRestriction(false)}
      />

      <SimpleHeader
        name="Edit Survey"
        description="Use the functions below to make the changes to your surveys that you need - quickly and easily."
        firstColProps={{ xs: 8, sm: 10, lg: 10 }}
        secondColProps={{ xs: 4, sm: 2, lg: 2 }}
        parents={[{ to: SURVEY_FULL_PATH, label: 'My Surveys' }]}
        rightElements={
          <>
            <Link
              to={SURVEY_FULL_PATH}
              className="btn btn-neutral btn-sm rounded-pill"
              color="default"
              size="sm">
              Back
            </Link>

            <Link
              to={`${SURVEY_FULL_PATH}/${id}/result`}
              className="btn btn-black btn-sm rounded-pill"
              color="default"
              size="sm">
              Results
            </Link>
          </>
        }
      />

      {isLoadingData && (
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">Survey Details</h3>
            </CardHeader>
            <CardBody>Loading...</CardBody>
          </Card>
        </Container>
      )}

      {!isLoadingData && data.getSurvey && (
        <FormSurvey
          onSubmit={onSubmit}
          onChangeVisibilityControl={(val) => {
            setVisibilityControl(val)
          }}
          type="edit"
          isLoadingSave={isLoadingSave}
          currentLogo={data.getSurvey.logo ? getImage(data.getSurvey.logo) : ''}
          currentBackgroundImage={
            data.getSurvey.backgroundImage ? getImage(data.getSurvey.backgroundImage) : ''
          }
          defaultValues={{
            name: data.getSurvey.name,
            backgroundColor: data.getSurvey.backgroundColor || '',
            buttonColor: data.getSurvey.buttonColor || '',
            buttonTextColor: data.getSurvey.buttonTextColor || '',
            font: data.getSurvey.font || '',
            fontColor: data.getSurvey.fontColor || '',
            restartSurvey: data.getSurvey.restartSurvey || false,
            visibility: JSON.parse(data.getSurvey.visibility) || '',
            visibilityControl: data.getSurvey.visibility ? true : false,
            logoAlignment: data.getSurvey.logoAlignment
              ? graphqlLogoAlignmentToLogoAlignmentMap[data.getSurvey.logoAlignment]
              : ''
          }}
          showVisibilityInput={visibilityControl}
          status={currentStatus}
          onPublish={(to) => {
            showConfirmation({
              type: 'modal',
              title:
                to === 'toPublish'
                  ? 'Are you sure you want to publish this survey?'
                  : 'Do you really want to revert this survey to Draft?',
              description:
                to === 'toPublish'
                  ? "Once this survey is Published, you won't be able to edit it anymore. Make sure you make all the changes you need before publishing it."
                  : 'If you revert this survey to Draft, you will be able to make changes to it again. HOWEVER, these changes might affect the relevance of the responses and the quality of the reports generated from your survey.',
              confirmTitle: to === 'toPublish' ? 'Publish Survey' : 'Revert to Draft',
              onConfirm: () => onPublish(to)
            });
          }}
          totalQuestion={data?.getSurvey.questions.length || 0}
          disabled={currentStatus === 'PUBLISHED'}
        />
      )}
    </>
  );
};

export default EditSurvey;
