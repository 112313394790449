import React, { forwardRef, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import classnames from 'classnames';

const FormPassword = forwardRef(({ invalid, feedbackElements, ...props }, ref) => {
  const [focused, setFocused] = useState(false);

  const [revealed, setRevealed] = useState();

  return (
    <InputGroup
      className={classnames({
        'input-group-merge': !invalid,
        focused: focused && !invalid
      })}>
      <Input
        innerRef={ref}
        {...props}
        type={revealed ? 'text' : 'password'}
        invalid={invalid}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText
          onClick={() => {
            setRevealed(!revealed);
          }}>
          <i className="fas fa-eye" />
        </InputGroupText>
      </InputGroupAddon>
      {feedbackElements}
    </InputGroup>
  );
});

export default FormPassword;
