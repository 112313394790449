import Dashboard from 'views/pages/Dashboard';
import Login from 'views/pages/auth/Login.js';
import Register from 'views/pages/auth/Register.js';
import Survey from 'views/pages/survey';
import AddSurvey from 'views/pages/survey/AddSurvey';
import EditSurvey from 'views/pages/survey/EditSurvey';
import AddQuestion from 'views/pages/survey/question/AddQuestion';
import EditQuestion from 'views/pages/survey/question/EditQuestion';
import Preset from 'views/pages/preset';
import EditPreset from 'views/pages/preset/EditPreset';
import AddPreset from 'views/pages/preset/AddPreset';
import Account from 'views/pages/Account/Account.js';
import Subscription from 'views/pages/Subscription';
import TeamMember from 'views/pages/teamMember';
import InviteTeamMember from 'views/pages/teamMember/InviteTeamMember';
import EditTeamMember from 'views/pages/teamMember/EditTeamMember';
import ResultSurvey from 'views/pages/survey/ResultSurvey';
import Forgot from 'views/pages/auth/Forgot';
import ResetPassword from 'views/pages/auth/ResetPassword';
import Archived from 'views/pages/survey/Archived';
import {
  ACCOUNT_PATH,
  ADD_PRESET_DATA_PATH,
  ADD_QUESTION_PATH,
  ADD_SURVEY_PATH,
  ADMIN_LAYOUT_PATH,
  ARCHIVED_SURVEY_PATH,
  AUTH_LAYOUT_PATH,
  DASHBOARD_PATH,
  EDIT_PRESET_DATA_PATH,
  EDIT_QUESTION_PATH,
  EDIT_SURVEY_PATH,
  EDIT_TEAM_MEMBER_PATH,
  FORGOT_PASSWORD_PATH,
  INVITE_TEAM_MEMBER_PATH,
  LOGIN_PATH,
  PRESET_DATA_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  RESULT_SURVEY_PATH,
  SUBSCRIPTION_PATH,
  SURVEY_PATH,
  RESPONSE_SURVEY_PATH,
  TEAM_MEMBER_PATH
} from './paths';
import ResponseSurvey from 'views/pages/survey/Response';

const routes = [
  {
    collapse: false,
    name: 'Dashboard',
    icon: 'ni ni-compass-04 text-brand',
    state: 'dashboardCollapse',
    path: DASHBOARD_PATH,
    layout: ADMIN_LAYOUT_PATH,
    component: Dashboard,
    accessLevels: ['ADMIN', 'MEMBER']
    /*
    views: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        miniName: 'D',
        component: Dashboard,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/alternative-dashboard',
        name: 'Alternative',
        miniName: 'A',
        component: Alternative,
        layout: ADMIN_LAYOUT_PATH
      }
    ]
    */
  },
  {
    collapse: false,
    name: 'My Surveys',
    icon: 'ni ni-satisfied text-brand',
    state: 'mySurveys',
    path: SURVEY_PATH,
    component: Survey,
    layout: ADMIN_LAYOUT_PATH,
    accessLevels: ['ADMIN', 'MEMBER']
    /*
    views: [
      {
        path: '/survey',
        name: 'My Surveys',
        miniName: 'D',
        component: Survey,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/survey/add',
        name: 'Add Survey',
        miniName: 'A',
        component: AddSurvey,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/survey/:id/edit',
        name: 'Edit Survey',
        miniName: 'D',
        component: EditSurvey,
        layout: ADMIN_LAYOUT_PATH,
        hideFromSidebar: true
      },
      {
        path: '/survey/:surveyId/question/add',
        name: 'Add Question',
        miniName: 'D',
        component: AddQuestion,
        layout: ADMIN_LAYOUT_PATH,
        hideFromSidebar: true
      },
      {
        path: '/survey/:surveyId/question/:id/edit',
        name: 'Edit Question',
        miniName: 'D',
        component: EditQuestion,
        layout: ADMIN_LAYOUT_PATH,
        hideFromSidebar: true
      }
    ]
    */
  },
  {
    collapse: false,
    name: 'Preset Data',
    icon: 'ni ni-app text-brand',
    state: 'presetData',
    path: PRESET_DATA_PATH,
    layout: ADMIN_LAYOUT_PATH,
    component: Preset,
    accessLevels: ['ADMIN']
  },
  {
    collapse: false,
    name: 'Company',
    icon: 'ni ni-single-02 text-brand',
    state: 'teamMember',
    path: TEAM_MEMBER_PATH,
    component: TeamMember,
    layout: ADMIN_LAYOUT_PATH,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  /*
  {
    collapse: true,
    name: 'Examples',
    icon: 'ni ni-ungroup text-orange',
    state: 'examplesCollapse',
    views: [
      {
        path: '/login',
        name: 'Login',
        miniName: 'L',
        component: Login,
        layout: AUTH_LAYOUT_PATH
      },
      {
        path: '/register',
        name: 'Register',
        miniName: 'R',
        component: Register,
        layout: AUTH_LAYOUT_PATH
      }
    ]
  },
  {
    collapse: true,
    name: 'Components',
    icon: 'ni ni-ui-04 text-info',
    state: 'componentsCollapse',
    views: [
      {
        path: '/buttons',
        name: 'Buttons',
        miniName: 'B',
        component: Buttons,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/grid',
        name: 'Grid',
        miniName: 'G',
        component: Grid,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/notifications',
        name: 'Notifications',
        miniName: 'N',
        component: Notifications,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/icons',
        name: 'Icons',
        miniName: 'I',
        component: Icons,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/typography',
        name: 'Typography',
        miniName: 'T',
        component: Typography,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        collapse: true,
        name: 'Multi Level',
        miniName: 'M',
        state: 'multiCollapse',
        views: [
          {
            path: '#pablo',
            name: 'Third level menu',
            component: () => {},
            layout: '/'
          },
          {
            path: '#pablo',
            name: 'Just another link',
            component: () => {},
            layout: '/'
          },
          {
            path: '#pablo',
            name: 'One last link',
            component: () => {},
            layout: '/'
          }
        ]
      }
    ]
  },
  {
    collapse: true,
    name: 'Forms',
    icon: 'ni ni-single-copy-04 text-pink',
    state: 'formsCollapse',
    views: [
      {
        path: '/elements',
        name: 'Elements',
        miniName: 'E',
        component: Elements,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/components',
        name: 'Components',
        miniName: 'C',
        component: Components,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/validation',
        name: 'Validation',
        miniName: 'V',
        component: Validation,
        layout: ADMIN_LAYOUT_PATH
      }
    ]
  },
  {
    collapse: true,
    name: 'Tables',
    icon: 'ni ni-align-left-2 text-default',
    state: 'tablesCollapse',
    views: [
      {
        path: '/tables',
        name: 'Tables',
        miniName: 'T',
        component: Tables,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/sortable',
        name: 'Sortable',
        miniName: 'S',
        component: Sortable,
        layout: ADMIN_LAYOUT_PATH
      },
      {
        path: '/react-bs-table',
        name: 'React BS Tables',
        miniName: 'RBT',
        component: ReactBSTables,
        layout: ADMIN_LAYOUT_PATH
      }
    ]
  },
 */
  {
    path: ACCOUNT_PATH,
    name: 'My Account',
    icon: 'ni ni-circle-08 text-brand',
    miniName: 'A',
    component: Account,
    layout: ADMIN_LAYOUT_PATH,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: SUBSCRIPTION_PATH,
    name: 'My Subscription',
    icon: 'ni ni-support-16 text-brand',
    miniName: 'A',
    component: Subscription,
    layout: ADMIN_LAYOUT_PATH,
    accessLevels: ['ADMIN'],
    hideOnSubscriptionManagedOffline: true
  },

  {
    path: ARCHIVED_SURVEY_PATH,
    name: 'Archived Survey',
    miniName: 'A',
    component: Archived,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: ADD_SURVEY_PATH,
    name: 'Add Survey',
    miniName: 'A',
    component: AddSurvey,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: EDIT_SURVEY_PATH,
    name: 'Edit Survey',
    miniName: 'D',
    component: EditSurvey,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: RESULT_SURVEY_PATH,
    name: 'Result Survey',
    miniName: 'D',
    component: ResultSurvey,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: RESPONSE_SURVEY_PATH,
    name: 'Survey Response',
    miniName: 'D',
    component: ResponseSurvey,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: ADD_QUESTION_PATH,
    name: 'Add Question',
    miniName: 'D',
    component: AddQuestion,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: EDIT_QUESTION_PATH,
    name: 'Edit Question',
    miniName: 'D',
    component: EditQuestion,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: ADD_PRESET_DATA_PATH,
    name: 'Add Preset Data',
    miniName: 'A',
    component: AddPreset,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN']
  },
  {
    path: EDIT_PRESET_DATA_PATH,
    name: 'Edit Preset Data',
    miniName: 'D',
    component: EditPreset,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN']
  },
  {
    path: INVITE_TEAM_MEMBER_PATH,
    name: 'Invite Company Member',
    miniName: 'A',
    component: InviteTeamMember,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },

  {
    path: EDIT_TEAM_MEMBER_PATH,
    name: 'Edit Company Member Data',
    miniName: 'D',
    component: EditTeamMember,
    layout: ADMIN_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: LOGIN_PATH,
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: AUTH_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: REGISTER_PATH,
    name: 'Register',
    miniName: 'R',
    component: Register,
    layout: AUTH_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: FORGOT_PASSWORD_PATH,
    name: 'Forgot',
    miniName: 'F',
    component: Forgot,
    layout: AUTH_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  },
  {
    path: RESET_PASSWORD_PATH,
    name: 'ResetPassword',
    miniName: 'R',
    component: ResetPassword,
    layout: AUTH_LAYOUT_PATH,
    hideFromSidebar: true,
    accessLevels: ['ADMIN', 'MEMBER']
  }
];

export default routes;
