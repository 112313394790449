import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { graphqlQuestionTypeToQuestionTypeMap } from 'constants/global';
import { questionTypes } from 'constants/questionTypes';
import { textEllipsis } from 'helpers/global';
import { SURVEY_FULL_PATH } from 'constants/paths';
import { removeHtmlTags } from 'helpers/global';

const ItemList = ({
  dragRef,
  previewRef,
  handlerId,
  isDragging,
  question,
  showConfirmation,
  onDuplicate,
  onDelete,
  surveyId,
  className,
  disabled
}) => {
  return (
    <tr
      ref={previewRef}
      data-handler-id={handlerId}
      className={classnames('sortable-row', className)}
      style={{ opacity: isDragging ? 0 : 1 }}>
      {!disabled && (
        <td>
          <div
            ref={dragRef}
            className={classnames('sortable-row__pointer', {
              'sortable-row__pointer--dragging': isDragging
            })}>
            <i className="fas fa-sort"></i>
          </div>
        </td>
      )}
      <td className="table-actions">
        <div className="d-flex">
          <Link
            className="table-action"
            to={`${SURVEY_FULL_PATH}/${surveyId}/question/${question.id}/edit`}
            id={`tooltip-edit-${question.id}`}>
            <i className="fas fa-user-edit" />
          </Link>
          <UncontrolledTooltip delay={0} target={`tooltip-edit-${question.id}`}>
            Edit Question & Answers
          </UncontrolledTooltip>

          {!disabled && (
            <>
              <button
                className="table-action table-action-duplicate"
                id={`tooltip-duplicate-${question.id}`}
                onClick={() => {
                  showConfirmation({
                    title: 'Are you sure want to duplicate this question?',
                    onConfirm: () => onDuplicate(question.id)
                  });
                }}>
                <i className="ni ni-ungroup" />
              </button>
              <UncontrolledTooltip delay={0} target={`tooltip-duplicate-${question.id}`}>
                Duplicate Question
              </UncontrolledTooltip>
            </>
          )}

          {!disabled && (
            <>
              <button
                className="table-action table-action-delete"
                id={`tooltip-delete-${question.id}`}
                onClick={(e) => {
                  showConfirmation({
                    title: 'Are you sure want to delete?',
                    onConfirm: () => onDelete(question.id)
                  });
                }}>
                <i className="fas fa-trash" />
              </button>
              <UncontrolledTooltip delay={0} target={`tooltip-delete-${question.id}`}>
                Delete Question
              </UncontrolledTooltip>
            </>
          )}
        </div>
      </td>
      <td>{question.sortOrder}</td>
      <td dangerouslySetInnerHTML={{__html: textEllipsis(removeHtmlTags(question.question), 100)}}></td>
      <td>{questionTypes[graphqlQuestionTypeToQuestionTypeMap[question.type]].label}</td>
    </tr>
  );
};

export default ItemList;
