import { Button, Modal } from 'reactstrap';

const ModalPresetLimit = ({ isShow, onClose }) => {
  let titleDescription = 'You have reached the 10 item limit!';
  let description =
    'To have unlimited answers in the Preset Data, please contact our customer service to upgrade to an Enterprise plan.';

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isShow}
      toggle={onClose}
      backdrop="static"
      keyboard={false}>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">{titleDescription}</h4>
          <p>{description}</p>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto text-white"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ModalPresetLimit;
