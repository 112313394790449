import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import useQueryParams from 'hooks/useQueryParams';
import { VERIFY_UPDATE_EMAIL } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import AuthFooter from 'components/Footers/AuthFooter';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function VerifyEmailUpdate() {
  const queryParams = useQueryParams();

  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add('bg-default');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove('bg-default');
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const token = queryParams.get('token');

  const [isVerified, setIsVerified] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const [message, setMessage] = useState(false);

  const [verifyUpdateEmail, { loading }] = useMutation(VERIFY_UPDATE_EMAIL);

  const verify = useCallback(async () => {
    try {
      await verifyUpdateEmail({
        variables: {
          token
        }
      });

      setMessage('Success!');
      setIsSuccess(true);
    } catch (err) {
      if (err.message === 'invalid-token') {
        setMessage('Invalid Token!');
      } else {
        setMessage(err.message);
      }
    }
  }, [token, verifyUpdateEmail]);

  useEffect(() => {
    if (verify && !isVerified) {
      verify();
      setIsVerified(true);
    }
  }, [verify, isVerified]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <AuthHeader title="Logo" lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  {!token && <p className="text-center mb-0">Invalid Token!</p>}
                  {token && (
                    <>
                      {loading && <p className="text-center mb-0">Loading....</p>}
                      {!loading && message && <p className="text-center mb-0">{message}</p>}
                    </>
                  )}

                  {isSuccess && (
                    <div className="text-center mt-4">
                      <Link to="/" className="text-white ml-auto btn btn-primary btn-sm">
                        Go to Dashboard
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}

export default VerifyEmailUpdate;
