import { Line, Bar } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, Container, Row, Col, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QuoteBox from 'components/QuoteBox';
import { textEllipsis } from 'helpers/global';
import { SURVEY_FULL_PATH, TEAM_MEMBER_FULL_PATH, ACCOUNT_FULL_PATH } from 'constants/paths';
import { useQuery } from '@apollo/client';
import { GET_SURVEYS_COMPLETED_COUNT } from 'graphql/queries';
import { GET_SURVEYS_COMPLETED_TOTAL } from 'graphql/queries';
import { GET_MOST_POPULAR_SURVEY_NAME } from 'graphql/queries';
import { GET_RESPONSES_COUNT_PER_SURVEY } from 'graphql/queries';
import { useMemo } from 'react';
import { GET_RESPONSES_COUNT_MONTH } from 'graphql/queries';
import { stringObjMonths } from 'constants/global';

function Dashboard() {
  const { me } = useSelector((state) => state.auth);

  const { loading: isLoadingResponsesCountMonth, data: dataResponsesCountMonth } =
    useQuery(GET_RESPONSES_COUNT_MONTH);

  const { loading: isLoadingDataCompleted7Days, data: dataCompleted7Days } = useQuery(
    GET_SURVEYS_COMPLETED_COUNT,
    {
      variables: { days: 7 }
    }
  );

  const { loading: isLoadingDataCompleted30Days, data: dataCompleted30Days } = useQuery(
    GET_SURVEYS_COMPLETED_COUNT,
    {
      variables: { days: 30 }
    }
  );

  const { loading: isLoadingDataCompletedTotal, data: dataCompletedTotal } = useQuery(
    GET_SURVEYS_COMPLETED_TOTAL
  );

  const { loading: isLoadingDataMostPopular7Days, data: dataMostPopular7Days } = useQuery(
    GET_MOST_POPULAR_SURVEY_NAME,
    {
      variables: { days: 7 }
    }
  );

  const { loading: isLoadingDataCountPerSurvey30Days, data: dataCountPerSurvey30Days } = useQuery(
    GET_RESPONSES_COUNT_PER_SURVEY,
    {
      variables: { days: 30 }
    }
  );

  const chartCountPerMonth = useMemo(() => {
    return {
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#e9ecef',
                zeroLineColor: '#e9ecef'
              },
              ticks: {}
            }
          ]
        }
      },
      data: {
        labels: dataResponsesCountMonth?.getResponsesCountPerMonth.map(
          (elm) => stringObjMonths[elm.month]
        ),
        datasets: [
          {
            label: 'Total Response',
            data: dataResponsesCountMonth?.getResponsesCountPerMonth.map((elm) => elm.total)
          }
        ]
      }
    };
  }, [dataResponsesCountMonth]);

  const chartCountPerSurvey = useMemo(() => {
    return {
      data: {
        labels: dataCountPerSurvey30Days?.getResponsesCountPerSurvey.map((elm) => elm.name),
        datasets: [
          {
            label: 'Total Response',
            backgroundColor: '#fe5900',
            data: dataCountPerSurvey30Days?.getResponsesCountPerSurvey.map((elm) => elm.count),
            maxBarThickness: 10
          }
        ]
      },
      options: {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      }
    };
  }, [dataCountPerSurvey30Days]);

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: '500px',
          backgroundImage: 'url("' + require('assets/img/theme/bg-home.jpg').default + '")',
          backgroundSize: 'cover',
          backgroundPosition: 'center top'
        }}>
        <span className="mask bg-gradient-brand opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col md="10" lg="10" xl="8">
              <h1>
                <QuoteBox color="white">{textEllipsis(`Hello ${me?.firstName}`, 16)}</QuoteBox>
              </h1>
              <h3 className="text-white">{me?.team.name}</h3>
              <p className="text-white mt-0 mb-5">
                Welcome to Gather - taking care of all your feedback and survey needs. Gain insights
                from customers, members and staff simply and quickly. You can see all your recent
                statistics and trends, as well as your surveys, team members and your own profile
                below.
              </p>
              <Link to={ACCOUNT_FULL_PATH} className="btn btn-default btn-neutral rounded-pill">
                Edit profile
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row className="row--stats-home">
          <Col md="6" xl="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      7 days
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {isLoadingDataCompleted7Days
                        ? 'Loading...'
                        : dataCompleted7Days?.getSurveysCompletedCount}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-active-40" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-card-summary">Surveys Completed in The Past 7 Days</p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      30 days
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {isLoadingDataCompleted30Days
                        ? 'Loading...'
                        : dataCompleted30Days?.getSurveysCompletedCount}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                      <i className="ni ni-chart-pie-35" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-card-summary">Surveys Completed in The Past 30 Days</p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      Total
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {isLoadingDataCompletedTotal
                        ? 'Loading...'
                        : dataCompletedTotal?.getSurveysCompletedTotal}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-card-summary">Total Amount of Surveys Completed</p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      Most Popular
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0 text-card-summary-survey-name">
                      {isLoadingDataMostPopular7Days
                        ? 'Loading...'
                        : dataMostPopular7Days?.getMostPopularSurveyName}
                    </span>
                  </div>
                </Row>
                <p className="mt-3 mb-0 text-card-summary">
                  Most Popular Survey in The Past 7 Days
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h6 className="surtitle">Overview</h6>
                <h5 className="h3 mb-0">Total Responses per Month</h5>
              </CardHeader>
              <CardBody>
                {isLoadingResponsesCountMonth ? (
                  <p>Loading...</p>
                ) : (
                  <Line
                    data={chartCountPerMonth.data}
                    options={chartCountPerMonth.options}
                    id="chart-sales"
                    className="chart-canvas"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h6 className="surtitle">Overview</h6>
                <h5 className="h3 mb-0">Total Responses per Survey</h5>
              </CardHeader>
              <CardBody>
                {isLoadingDataCountPerSurvey30Days ? (
                  <p>Loading...</p>
                ) : (
                  <Bar
                    data={chartCountPerSurvey.data}
                    options={chartCountPerSurvey.options}
                    className="chart-canvas"
                    id="chart-bar-stacked"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <CardTitle className="mb-3" tag="h3">
              My Surveys
            </CardTitle>
            <CardText className="mb-4">
              Lets get going, create, edit, preview, publish and look at the results of your
              surveys.
            </CardText>
            <Link className="btn btn-primary rounded-pill" to={SURVEY_FULL_PATH}>
              Go to My Surveys
            </Link>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-3" tag="h3">
              My Company
            </CardTitle>
            <CardText className="mb-4">
              See, invite and edit the authority of your team member.
            </CardText>
            <Link className="btn btn-primary rounded-pill" to={TEAM_MEMBER_FULL_PATH}>
              Go to My Company
            </Link>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Dashboard;
