import { Label, FormGroup, Input, Col, Button, FormFeedback } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';

const TeamForm = ({ onSubmit, defaultValues, isLoadingSave, currentLogo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="row">
        <Label className="form-control-label" htmlFor="last-name" md="3">
          Company Name
        </Label>
        <Col md="9">
          <Input
            id="team-name"
            type="text"
            {...replaceRefToInneRef(register('name', { required: true }))}
            invalid={errors.hasOwnProperty('name')}
          />
          {errors.hasOwnProperty('name') && <FormFeedback>Team Name is required!</FormFeedback>}
        </Col>
      </FormGroup>

      <Button color="primary" disabled={isLoadingSave} className="rounded-pill">
        {isLoadingSave && <span>Loading...</span>}
        {!isLoadingSave && <span>Save</span>}
      </Button>
    </form>
  );
};

export default TeamForm;
