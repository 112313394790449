import React, { useState } from 'react';
import { Button, FormGroup, FormFeedback } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormPassword from 'components/forms/FormPassword';
import { passwordPattern } from 'constants/global';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'graphql/mutations';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { Redirect } from 'react-router';
import { LOGIN_FULL_PATH } from 'constants/paths';

const ResetPasswordForm = ({ email, token }) => {
  const [redirect, setRedirect] = useState(false);

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'You have changed your password successfully!',
    place: 'bc'
  });

  const onSubmit = async (data) => {
    try {
      const { password } = data;

      const res = await resetPassword({
        variables: {
          input: {
            email,
            password,
            token
          }
        }
      });

      if (!res.data.resetPassword.success) {
        showNotificationAlert({
          type: 'danger',
          title: 'Failed',
          message: res.data.resetPassword.message
        });
      } else {
        reset();
        showNotificationAlert();
        setRedirect(true);
      }
    } catch (err) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  if (redirect) {
    return <Redirect to={LOGIN_FULL_PATH} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormPassword
          {...register('password', {
            required: true,
            minLength: 8,
            pattern: passwordPattern
          })}
          placeholder="Password"
          invalid={errors.hasOwnProperty('password')}
          feedbackElements={
            <>
              {errors?.password?.type === 'required' && (
                <FormFeedback>Password is required!</FormFeedback>
              )}
              {errors?.password?.type === 'minLength' && (
                <FormFeedback>The minimum password length is 8!</FormFeedback>
              )}
              {errors?.password?.type === 'pattern' && (
                <FormFeedback>
                  The password should contain at least 1 lowercase, 1 uppercase, 1 special
                  character, and 1 number.
                </FormFeedback>
              )}
            </>
          }
        />
      </FormGroup>

      <FormGroup>
        <FormPassword
          {...register('confirmPassword', {
            required: true,
            minLength: 8,
            pattern: passwordPattern,
            validate: (val) => {
              if (watch('password') !== val) {
                return 'The password fields do not match';
              }
            }
          })}
          placeholder="Confirm Password"
          invalid={errors.hasOwnProperty('confirmPassword')}
          feedbackElements={
            <>
              {errors?.confirmPassword?.type === 'required' && (
                <FormFeedback>Confirm Password is required!</FormFeedback>
              )}
              {errors?.confirmPassword?.type === 'minLength' && (
                <FormFeedback>The minimum password length is 8!</FormFeedback>
              )}
              {errors?.confirmPassword?.type === 'pattern' && (
                <FormFeedback>
                  The password should contain at least 1 lowercase, 1 uppercase, 1 special
                  character, and 1 number.
                </FormFeedback>
              )}
              {errors?.confirmPassword?.type === 'validate' && (
                <FormFeedback>{errors.confirmPassword.message}</FormFeedback>
              )}
            </>
          }
        />
      </FormGroup>

      <div className="text-center">
        <Button className="my-4" color="info" type="submit" disabled={loading}>
          {loading && <span>Loading...</span>}
          {!loading && <span>Confirm</span>}
        </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
