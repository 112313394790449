import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMe } from 'redux/reducers/auth';
import { Route, Switch } from 'react-router';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';

const AuthenticatedOrNot = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { isLoadingMe } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingMe) {
      setIsLoading(false);
    }
  }, [isLoadingMe]);

  if (isLoading || isLoadingMe) {
    return <p>Loading...</p>;
  }

  return (
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
    </Switch>
  );
};

export default AuthenticatedOrNot;
