import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import UppyUploader from 'components/UppyUploader';
import { questionTypes } from 'constants/questionTypes';

const emoticonQuestionType = questionTypes.emoticon;

const maxOptions = 5;

const FieldEmoticon = ({
  onChange,
  value,
  questionRoutes,
  questionRoutesLoading,
  error,
  numberOfOptions = maxOptions,
  disabled
}) => {
  const defaultAnswers = useMemo(() => {
    if (numberOfOptions === 4) {
      return emoticonQuestionType.default4OptionsAnswers;
    }

    return emoticonQuestionType.defaultAnswers;
  }, [numberOfOptions]);

  const [answers, setAnswers] = useState(emoticonQuestionType.defaultAnswers);

  useEffect(() => {
    setAnswers(() => {
      const newAnswers = [];

      for (let i = 0; i < Math.max(defaultAnswers.length, value?.length || 0); i++) {
        let val = {};
        if (defaultAnswers[i]) {
          val = {
            ...defaultAnswers[i]
          };
        }

        if (value && value[i]) {
          val = {
            ...val,
            ...value[i],
            icon: value[i].icon
          };
        }

        newAnswers.push(val);
      }

      return newAnswers;
    });
  }, [value, numberOfOptions, defaultAnswers]);

  const onChangeAnswer = useCallback(
    (index, field, value) => {
      const newAnswers = [...answers];
      newAnswers[index][field] = value;

      setAnswers(newAnswers);
      onChange(newAnswers);
    },
    [answers, setAnswers, onChange]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-brand">
                Question Type Details
                {error ? (
                  <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                    - {error}
                  </span>
                ) : (
                  ''
                )}
              </h3>
            </CardHeader>
            <CardBody>
              {answers
                .filter((_, index) => index < defaultAnswers.length)
                .map((answer, index) => (
                  <Row
                    key={`answer-${answer.id}`}
                    className={'row--answers row--answers--emoticon'}>
                    <Col>{index + 1}</Col>
                    <Col>
                      <img alt="Face icon" style={{ width: '36px' }} src={answer.defaultIcon} />
                    </Col>
                    {!disabled && (
                      <Col>
                        <UppyUploader
                          title="Replace Icon"
                          currentImage={answer.currentIcon}
                          onChange={(path) => {
                            onChangeAnswer(index, 'icon', path);
                          }}
                        />
                      </Col>
                    )}
                    <Col>
                      <Input
                        invalid={error && !answer.answer}
                        placeholder="Answer Text"
                        value={answer.answer}
                        disabled={disabled}
                        onChange={(e) => {
                          onChangeAnswer(index, 'answer', e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        placeholder="Alert Email"
                        value={answer.email}
                        disabled={disabled}
                        onChange={(e) => {
                          onChangeAnswer(index, 'email', e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      {questionRoutesLoading && (
                        <div>
                          <small>Loading...</small>
                        </div>
                      )}
                      {!questionRoutesLoading && (
                        <Input
                          type="select"
                          disabled={disabled}
                          value={answer.route}
                          onChange={(e) => {
                            onChangeAnswer(index, 'route', e.target.value);
                          }}>
                          <option value="">Next</option>
                          {questionRoutes.map((question) => (
                            <option value={question.id} key={question.id}>
                              {question.sortOrder} - {question.question}
                            </option>
                          ))}
                        </Input>
                      )}
                    </Col>
                  </Row>
                ))}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default FieldEmoticon;
