import React, { useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Redirect } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { replaceRefToInneRef } from 'helpers/global';
import { Link } from 'react-router-dom';
import { TEAM_SEND_INVITATION } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { accessToGraphqlLevelMap } from 'constants/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import useConfirmation from 'hooks/useConfirmation';
import TeamMemberPageWrapper from './TeamMemberPageWrapper';
import { TEAM_MEMBER_FULL_PATH } from 'constants/paths';

const InviteTeamMember = () => {
  const { show: showNotificationAlert } = useNotificationAlert({
    place: 'br'
  });

  const [isRedirect, setIsRedirect] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [teamSendInvitation, { loading: isLoadingSave }] = useMutation(TEAM_SEND_INVITATION);

  const { showConfirmation, hideConfirmation } = useConfirmation(isLoadingSave);

  const onSubmit = async (data) => {
    const { name, email, access } = data;
    try {
      await teamSendInvitation({
        variables: {
          input: { name, email, level: accessToGraphqlLevelMap[access] }
        }
      });

      showNotificationAlert({
        title: 'Success',
        message: 'You have invited a team member successfully'
      });

      setIsRedirect(true);
    } catch (err) {
      switch (err.message) {
        case 'email_registered':
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: 'Email Already Registered'
          });
          break;
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  if (isRedirect) {
    return <Redirect to={TEAM_MEMBER_FULL_PATH} />;
  }

  const onInvite = (data) => {
    showConfirmation({
      title: 'Are you sure want to continue?',
      onConfirm: () => onSubmit(data)
    });
  };

  return (
    <TeamMemberPageWrapper>
      <SimpleHeader
        name="Invite Company Member"
        firstColProps={{ xs: 8, sm: 10, lg: 10 }}
        secondColProps={{ xs: 4, sm: 2, lg: 2 }}
        parents={[{ to: TEAM_MEMBER_FULL_PATH, label: 'Company' }]}
        rightElements={
          <Link to={TEAM_MEMBER_FULL_PATH} className="btn btn-neutral btn-sm rounded-pill">
            Back
          </Link>
        }
      />
      <Container className="mt--6" fluid>
        <form onSubmit={handleSubmit(onInvite)}>
          <Row>
            <Col>
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-brand">Company Member Details</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="name" md="3">
                        First Name
                      </Label>
                      <Col md="9">
                        <Input
                          id="name"
                          type="name"
                          {...replaceRefToInneRef(
                            register('name', { required: true, maxLength: 30 })
                          )}
                          invalid={errors.hasOwnProperty('name')}
                        />
                        {errors.hasOwnProperty('name') && (
                          <>
                            {errors.name.type === 'required' && (
                              <FormFeedback>Name is required!</FormFeedback>
                            )}
                            {errors.name.type === 'maxLength' && (
                              <FormFeedback>The maximum characters length is 30</FormFeedback>
                            )}
                          </>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="email" md="3">
                        Email
                      </Label>
                      <Col md="9">
                        <Input
                          id="email"
                          type="email"
                          {...replaceRefToInneRef(
                            register('email', { required: true, maxLength: 50 })
                          )}
                          invalid={errors.hasOwnProperty('email')}
                        />
                        {errors.hasOwnProperty('email') && (
                          <>
                            {errors.email.type === 'required' && (
                              <FormFeedback>Email is required!</FormFeedback>
                            )}
                            {errors.email.type === 'maxLength' && (
                              <FormFeedback>The maximum characters length is 50</FormFeedback>
                            )}
                          </>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="form-control-label" htmlFor="access" md="3">
                        Access
                      </Label>
                      <Col md="9">
                        <Input
                          id="access"
                          type="select"
                          {...replaceRefToInneRef(register('access', { required: true }))}
                          invalid={errors.hasOwnProperty('access')}>
                          <option value="">Select Access</option>
                          <option value="Admin">Admin</option>
                          <option value="Member">Member</option>
                        </Input>
                        {errors.hasOwnProperty('access') && (
                          <FormFeedback>Access is required!</FormFeedback>
                        )}
                      </Col>
                    </FormGroup>

                    <Button type="submit" color="primary" className="rounded-pill">
                      Invite
                    </Button>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </TeamMemberPageWrapper>
  );
};

export default InviteTeamMember;
