import useClickOutside from 'hooks/useClickOutside';
import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

const FormColorPicker = ({ value, onChange }) => {
  const pickerRef = useRef(null);

  useClickOutside(pickerRef, () => {
    setShowPicker(false);
  });

  const [showPicker, setShowPicker] = useState(false);
  return (
    <div className="form-color-picker" ref={pickerRef}>
      <div
        className="form-color-picker__selector"
        style={{ backgroundColor: value }}
        onClick={() => {
          setShowPicker(!showPicker);
        }}
      />
      {showPicker && (
        <div className="form-color-picker__picker-container">
          <SketchPicker
            color={value}
            onChange={(v) => {
              onChange(v.hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FormColorPicker;
