export const questionTypes = {
  emoticon: {
    label: 'Emoticon',
    fields: [
      'mandatory',
      'skipOption',
      'questionIcon',
      'nextOnSelect',
      'emoticon4Options',
      'buttonText',
      'question'
    ],
    hasAnswers: true,
    keyAnswer: 'emoticons',
    default4OptionsAnswers: [
      {
        id: 1,
        defaultIcon: '/icons/face-grin.svg',
        icon: '',
        answer: 'Outstanding',
        email: '',
        route: ''
      },
      {
        id: 2,
        defaultIcon: '/icons/face-smile.svg',
        icon: '',
        answer: 'Good',
        email: '',
        route: ''
      },
      {
        id: 3,
        defaultIcon: '/icons/face-meh.svg',
        icon: '',
        answer: 'Requires Improvement',
        email: '',
        route: ''
      },
      {
        id: 4,
        defaultIcon: '/icons/face-frown.svg',
        icon: '',
        answer: 'Inadequate',
        email: '',
        route: ''
      },
    ],
    defaultAnswers: [
      {
        id: 1,
        defaultIcon: '/icons/face-grin.svg',
        icon: '',
        answer: 'Very Happy',
        email: '',
        route: ''
      },
      {
        id: 2,
        defaultIcon: '/icons/face-smile.svg',
        icon: '',
        answer: 'Happy',
        email: '',
        route: ''
      },
      {
        id: 3,
        defaultIcon: '/icons/face-meh.svg',
        icon: '',
        answer: 'OK',
        email: '',
        route: ''
      },
      {
        id: 4,
        defaultIcon: '/icons/face-frown.svg',
        icon: '',
        answer: 'Unhappy',
        email: '',
        route: ''
      },
      {
        id: 5,
        defaultIcon: '/icons/face-angry.svg',
        icon: '',
        answer: 'Very Unhappy',
        email: '',
        route: ''
      }
    ],
    returnAnswers: (answers) => {
      const count = 5;
      return answers.filter((_, index) => index < count);
    }
  },
  multiSelect: {
    label: 'Multi Select',
    fields: [
      'mandatory',
      'skipOption',
      'minimumSelect',
      'questionIcon',
      'buttonText',
      'question',
      'route'
    ],
    hasAnswers: true,
    keyAnswer: 'options'
  },
  singleSelect: {
    label: 'Single Select',
    fields: ['mandatory', 'skipOption', 'questionIcon', 'nextOnSelect', 'buttonText', 'question'],
    hasAnswers: true,
    keyAnswer: 'options'
  },
  commentBox: {
    label: 'Comment Box',
    fields: [
      'mandatory',
      'skipOption',
      'questionIcon',
      'buttonText',
      'question',
      'route',
      'alertEmail'
    ]
  },
  input: {
    label: 'Input',
    fields: [
      'mandatory',
      'skipOption',
      'questionIcon',
      'buttonText',
      'question',
      'route',
      'alertEmail',
      'fieldName'
    ]
  },
  datetimePicker: {
    label: 'Calendar',
    fields: [
      'mandatory',
      'skipOption',
      'questionIcon',
      'buttonText',
      'question',
      'route',
      'alertEmail'
    ]
  },
  statement: {
    label: 'Statement',
    fields: [
      'mandatory',
      'questionIcon',
      'buttonText',
      'statement',
      'theEnd',
      'route',
      'externalLink'
    ]
  },
  presetData: {
    label: 'Preset Data',
    fields: [
      'mandatory',
      'skipOption',
      'questionIcon',
      'nextOnSelect',
      'buttonText',
      'question',
      'route',
      'presetDataSet'
    ]
  }
};
