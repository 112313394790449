import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  invite as apiInvite,
  update as apiUpdate,
  resend as apiResend,
  destroy as apiDestroy
} from 'services/fake/teamMemberApi';

export const getAll = createAsyncThunk('teamMember/getAll', async () => {
  const data = await apiGetAll();
  return data;
});

export const getOne = createAsyncThunk('teamMember/getOne', async (id) => {
  const data = await apiGetOne(id);
  return data;
});

export const invite = createAsyncThunk('teamMember/invite', async (data) => {
  await apiInvite(data);
  return true;
});

export const update = createAsyncThunk('teamMember/update', async (id, data) => {
  await apiUpdate(id, data);
  return true;
});

export const resend = createAsyncThunk('teamMember/resend', async (id) => {
  await apiResend(id);
  return true;
});

export const destroy = createAsyncThunk('teamMember/destroy', async (id) => {
  await apiDestroy(id);
  return true;
});

export const teamMemberSlice = createSlice({
  name: 'teamMember',
  initialState: {
    isLoadingDatas: false,
    datas: [],
    isLoadingData: false,
    data: undefined,
    isLoadingSave: false,
    isLoadingResend: false,
    isLoadingDestroy: false
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(invite.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(invite.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(resend.pending, (state) => {
      state.isLoadingResend = true;
    });

    builder.addCase(resend.fulfilled, (state) => {
      state.isLoadingResend = false;
    });

    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  }
});

export default teamMemberSlice.reducer;
