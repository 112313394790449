import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { useForm } from 'react-hook-form';
import { USER_REGISTRATION } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { replaceRefToInneRef } from 'helpers/global';
import useNotificationAlert from 'hooks/useNotificationAlert';
import { RESEND_VERIFICATION_EMAIL } from 'graphql/mutations';
import { Link } from 'react-router-dom';
import { LOGIN_FULL_PATH } from 'constants/paths';

function UserRegister() {
  const [focusedFirstName, setfocusedFirstName] = useState(false);
  const [focusedLastName, setfocusedLastName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const [userRegistration, { loading }] = useMutation(USER_REGISTRATION);

  const [resendVerificationEmail, { loading: isLoadingResed }] =
    useMutation(RESEND_VERIFICATION_EMAIL);

  const { show: showNotificationAlert } = useNotificationAlert({
    title: 'Success',
    message: 'Please check your email to continue!',
    place: 'bc'
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const resendEmail = async (email) => {
    await resendVerificationEmail({ variables: { email } });
    setIsSuccess(true);
    reset();
  };

  const onSubmit = async (data) => {
    const { firstName, lastName, email } = data;

    try {
      await userRegistration({
        variables: { input: { firstName, lastName, email } }
      });
      setIsSuccess(true);
      reset();
    } catch (err) {
      switch (err.message) {
        case 'unverified':
          resendEmail(email);
          break;
        case 'email_registered':
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: 'Email is already registered!'
          });
          break;
        default:
          showNotificationAlert({
            type: 'danger',
            title: 'Failed',
            message: err.message
          });
          break;
      }
    }
  };

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-white border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                {isSuccess && (
                  <>
                    <h3 className="text-center text-brand">Success</h3>
                    <p className="text-center text-black">Please check your email to continue!</p>
                    <p className="text-center mb-0">
                      <Link to={LOGIN_FULL_PATH} className="text-brand text-underline">
                        <small className="font-weight-bold">Back to login page</small>
                      </Link>
                    </p>
                  </>
                )}
                {!isSuccess && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup
                      className={classnames({
                        focused: focusedFirstName
                      })}>
                      <InputGroup
                        className={classnames('input-group-merge input-group-alternative', {
                          'mb-3': !errors.hasOwnProperty('firstName')
                        })}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="First Name"
                          type="text"
                          onFocus={() => setfocusedFirstName(true)}
                          onBlur={() => setfocusedFirstName(false)}
                          {...replaceRefToInneRef(
                            register('firstName', { required: true, maxLength: 50 })
                          )}
                        />
                      </InputGroup>
                      {errors?.firstName?.type === 'required' && (
                        <FormFeedback className="d-block">First Name is required!</FormFeedback>
                      )}
                      {errors?.firstName?.type === 'maxLength' && (
                        <FormFeedback className="d-block">
                          The maximum characters is 50!
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedLastName
                      })}>
                      <InputGroup
                        className={classnames('input-group-merge input-group-alternative', {
                          'mb-3': !errors.hasOwnProperty('lastName')
                        })}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          onFocus={() => setfocusedLastName(true)}
                          onBlur={() => setfocusedLastName(false)}
                          {...replaceRefToInneRef(
                            register('lastName', { required: true, maxLength: 50 })
                          )}
                        />
                      </InputGroup>
                      {errors?.lastName?.type === 'required' && (
                        <FormFeedback className="d-block">Last Name is required!</FormFeedback>
                      )}
                      {errors?.lastName?.type === 'maxLength' && (
                        <FormFeedback className="d-block">
                          The maximum characters is 50!
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedEmail
                      })}>
                      <InputGroup
                        className={classnames('input-group-merge input-group-alternative', {
                          'mb-3': !errors.hasOwnProperty('email')
                        })}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                          {...replaceRefToInneRef(register('email', { required: true }))}
                        />
                      </InputGroup>
                      {errors?.email?.type === 'required' && (
                        <FormFeedback className="d-block">Email is required!</FormFeedback>
                      )}
                    </FormGroup>
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox mb-2">
                          <input
                            className={classnames('custom-control-input', {
                              'is-invalid': errors.hasOwnProperty('agree')
                            })}
                            id="customCheckRegister"
                            type="checkbox"
                            {...register('agree', { required: true })}
                          />
                          <label className="custom-control-label" htmlFor="customCheckRegister">
                            <span className="text-black">
                              I agree with the{' '}
                              <a
                                href="https://umbrella-insights-assets.s3.eu-west-2.amazonaws.com/pdf/privacy_policy.pdf"
                                target="_blank"
                                rel="noreferrer"
                                className="text-black text-underline">
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                          <FormFeedback>You must agree our Privacy Policy!</FormFeedback>
                        </div>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className={classnames('custom-control-input', {
                              'is-invalid': errors.hasOwnProperty('agreeTermsOfUse')
                            })}
                            id="customCheckAgreeTermsOfUse"
                            type="checkbox"
                            {...register('agreeTermsOfUse', { required: true })}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckAgreeTermsOfUse">
                            <span className="text-black">
                              I agree with the{' '}
                              <a
                                href="https://umbrella-insights-assets.s3.eu-west-2.amazonaws.com/pdf/TERMS_OF_USE_Gather.pdf"
                                target="_blank"
                                rel="noreferrer"
                                className="text-black text-underline">
                                Terms of Use
                              </a>
                            </span>
                          </label>
                          <FormFeedback>You must agree our Terms of Use!</FormFeedback>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="mt-4 rounded-pill w-100"
                        color="primary"
                        type="submit"
                        disabled={loading || isLoadingResed}>
                        {(loading || isLoadingResed) && <span>Loading...</span>}
                        {!loading && !isLoadingResed && <span>Create account</span>}
                      </Button>
                    </div>
                  </form>
                )}
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col>
                <small>
                  Already have an account?{' '}
                  <Link className="text-brand text-underline mt-3" to={LOGIN_FULL_PATH}>
                    <span className="font-weight-bold">Sign In</span>
                  </Link>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserRegister;
